import { FaWhatsapp } from "react-icons/fa";
import { ReactComponent as ViewerIcon } from "../../assets/images/viwerCount.svg";

export const WhatsAppButton = ({ count, css }) => {
  const handleWhatsAppClick = () => {
    const phoneNumber = "919030908181"; // Replace with your WhatsApp number (include country code, e.g., 911234567890)
    const message = "Greetings From eventsonlife.com"; // Replace with your default message
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;
    window.open(url, "_blank"); // Opens WhatsApp in a new tab
  };

  return (
    <div
      style={{
        position: "fixed",
        bottom: "20px",
        right: "20px",
        display: "flex",
        alignItems: "center",
        gap: "10px",
      }}
    >
      {count > 0 && (
        <div
          style={{
            color: css?.textColor ? css?.textColor : "black",
            fontFamily: "Arial, sans-serif",
            fontSize: "18px",
            backgroundColor: "#f3f4f6",
            border: "1px solid #ccc",
            borderRadius: "8px",
            padding: "6px 5px",
            display: "flex",
            alignItems: "center",
            gap: "5px",
            boxShadow: "2px 2px 6px rgba(0, 0, 0, 0.1)",
          }}
        >
          <ViewerIcon
            style={{ borderRadius: "50%", width: "30px", height: "32px" }}
          />
          : <strong style={{ color: "#ff5722" }}>{count}</strong>
        </div>
      )}

      <button
        onClick={handleWhatsAppClick}
        style={{
          backgroundColor: "#25D366",
          color: "#fff",
          border: "none",
          borderRadius: "50%",
          width: "60px",
          height: "60px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          cursor: "pointer",
          fontSize: "24px",
        }}
      >
        <FaWhatsapp style={{ fontSize: "24px" }} />
      </button>
    </div>
  );
};
