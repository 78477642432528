import { Button } from "@mui/material";
import { motion, AnimatePresence } from "framer-motion";
import { useAppDataContext } from "hooks/useappdatacontext";

const LoadTemplateSettings = ({
  content,
  eventData,
  setEventData,
  selectedMainSettingsOption,
  setSelectedMainSettingsOption,
  setContent,
  handleTemplateSettings,
  setSelectedSettingsOption,
  selectedSettingsOption,
}) => {
  console.log("content: ", content?.template_css?.template_type);

  return (
    <AnimatePresence>
      <motion.div
        initial={{
          opacity: 0,
          scale: 0.5,
          borderRadius: "50%",
          minWidth: "0px",
        }}
        animate={{
          opacity: 1,
          scale: 1,
          borderRadius: "5px",
          minWidth: "300px",
        }}
        transition={{
          duration: 0.8,
          delay: 0.5,
          ease: [0, 0.71, 0.2, 1.01],
        }}
        style={{
          position: "absolute",
          right: "0px",
          border: "1px solid, brown",
          borderRadius: "5px",
          padding: "5px 10px",
          zIndex: "1",
          minWidth: "300px",
          maxWidth: "300px",
          textAlign: "left",
          background: "white",
          top: "35px",
        }}
      >
        <select
          style={{
            width: "100%",
            padding: "3px 6px",
            margin: "10px 0px",
          }}
          value={content?.template_css?.template_type || "default"}
          onChange={(e) => {
            setContent({
              ...content,
              template_css: {
                ...content?.template_css,
                template_type: e.target.value || "default", // Ensure no undefined value
              },
            });
            console.log("e.target.value: ", e.target.value);
          }}
        >
          <option value="dafault">Default</option>
          <option value="t2">Template 2</option>
          <option value="t3">Template 3</option>
          <option value="t4">Template 4</option>
          <option value="custom">Custom</option>
        </select>

        {content?.template_css?.template_type === "custom" && (
          <>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "4px",
                justifyContent: "center",
                marginBottom: "8px",
              }}
            >
              <button
                onClick={() => {
                  setSelectedMainSettingsOption("bg");
                }}
                className={`selected-not-selected ${
                  selectedMainSettingsOption === "bg"
                    ? "selected"
                    : "not-selected"
                }`}
              >
                Background
              </button>
              <button
                onClick={() => {
                  setSelectedMainSettingsOption("title");
                }}
                className={`selected-not-selected ${
                  selectedMainSettingsOption === "title"
                    ? "selected"
                    : "not-selected"
                }`}
              >
                Title
              </button>
              <button
                onClick={() => {
                  setSelectedMainSettingsOption("text");
                }}
                className={`selected-not-selected ${
                  selectedMainSettingsOption === "text"
                    ? "selected"
                    : "not-selected"
                }`}
              >
                Text
              </button>
            </div>
            {selectedMainSettingsOption === "bg" && (
              <div>
                <div
                  style={{
                    margin: "8px 0px",
                    justifyContent: "center",
                  }}
                  className="settings-semi-container"
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "4px",
                    }}
                  >
                    <button
                      onClick={() => {
                        setSelectedSettingsOption("color");
                        setContent({
                          ...content,
                          template_css: {
                            ...content?.template_css,
                            bgGradient: "",
                            bgImage: "",
                          },
                        });
                        setEventData({
                          ...eventData,
                          template_css: {
                            ...eventData?.template_css,
                            bgGradient: "",
                            bgImage: "",
                          },
                        });
                      }}
                      className={`selected-not-selected ${
                        selectedSettingsOption === "color"
                          ? "selected"
                          : "not-selected"
                      }`}
                    >
                      Color
                    </button>
                    <button
                      onClick={() => {
                        setSelectedSettingsOption("image");
                        setContent({
                          ...content,
                          template_css: {
                            ...content?.template_css,
                            bgGradient: "",
                            bgColor: "",
                          },
                        });
                        setEventData({
                          ...eventData,
                          template_css: {
                            ...eventData?.template_css,
                            bgGradient: "",
                            bgColor: "",
                          },
                        });
                      }}
                      className={`selected-not-selected ${
                        selectedSettingsOption === "image"
                          ? "selected"
                          : "not-selected"
                      }`}
                    >
                      Image
                    </button>
                    <button
                      onClick={() => {
                        setSelectedSettingsOption("gradient");
                        setContent({
                          ...content,
                          template_css: {
                            ...content?.template_css,
                            bgImage: "",
                            bgColor: "",
                          },
                        });
                        setEventData({
                          ...eventData,
                          template_css: {
                            ...eventData?.template_css,
                            bgImage: "",
                            bgColor: "",
                          },
                        });
                      }}
                      className={`selected-not-selected ${
                        selectedSettingsOption === "gradient"
                          ? "selected"
                          : "not-selected"
                      }`}
                    >
                      Gradients
                    </button>
                  </div>
                </div>

                {selectedSettingsOption === "color" && (
                  <div className="settings-semi-container subcontainers">
                    <label className="w-50">Color: </label>
                    <input
                      name="bgColor"
                      onChange={handleTemplateSettings}
                      value={content?.template_css?.bgColor}
                      className="w-50 input-option"
                      type="color"
                    />
                  </div>
                )}

                {selectedSettingsOption === "image" && (
                  <div className="subcontainers">
                    <div className="settings-semi-container">
                      <label className="w-50">Image Link: </label>
                      <input
                        name="bgImage"
                        onChange={handleTemplateSettings}
                        value={content?.template_css?.bgImage}
                        className="w-50 input-option"
                        style={{ padding: "3px 0px" }}
                        type="text"
                      />
                    </div>
                    <div
                      style={{ marginBottom: "0px" }}
                      className="settings-semi-container"
                    >
                      <label className="w-50">Opacity: </label>{" "}
                      <input
                        name="bgOpacity"
                        value={content?.template_css?.bgOpacity * 100}
                        onChange={(e) => {
                          handleTemplateSettings(e);
                        }}
                        type="range"
                        className="w-50"
                        step="1"
                        min="0"
                        max="100"
                      />
                    </div>
                  </div>
                )}
                {selectedSettingsOption === "gradient" && (
                  <div
                    style={{ margin: "8px 0px" }}
                    className="settings-semi-container subcontainers"
                  >
                    <label className="w-50">Gradient: </label>
                    <select
                      name="bgGradient"
                      value={content?.template_css?.bgGradient}
                      style={{
                        fontWeight: content?.template_css?.bgGradient,
                      }}
                      onChange={(e) => {
                        handleTemplateSettings(e);
                      }}
                      className="w-50 input-option"
                    >
                      <option selected key="Linear Gradient Right" value="">
                        None
                      </option>
                      <option
                        key="Linear Gradient Right"
                        value="radial-gradient(circle, #F2C94C, #F2994A)"
                      >
                        Default
                      </option>
                      <option
                        key="Linear Gradient Right"
                        value="radial-gradient(circle, #FC00FF 0%, #00DBDE 100%)"
                      >
                        Gradient 1
                      </option>
                      <option
                        key="Linear Gradient Left"
                        value="linear-gradient(50deg, #FAACA8 0%, #DDD6F3 80%)"
                      >
                        Gradient 2
                      </option>
                      <option
                        key="Linear Gradient Top"
                        value="radial-gradient(circle, #B2FEFA, #0ED2F7)"
                      >
                        Gradient 3
                      </option>
                      <option
                        key="Linear Gradient Bottom"
                        value="radial-gradient(circle, #E29587, #D66D75)"
                      >
                        Gradient 4
                      </option>

                      <option
                        key="Radial Gradient Circle"
                        value="radial-gradient(circle, #A7BFE8, #6190E8)"
                      >
                        Gradient 5
                      </option>
                      <option
                        key="Radial Gradient Ellipse"
                        value="radial-gradient(circle, #ff00cc, #333399)"
                      >
                        Gradient 6
                      </option>
                      <option
                        key="Radial Gradient Centered"
                        value="radial-gradient(circle, #4568DC, #B06AB3)"
                      >
                        Gradient 7
                      </option>
                      <option
                        key="Radial Gradient Centered"
                        value="radial-gradient(circle, #9CECFB, #65C7F7, #0052D4)"
                      >
                        Gradient 8
                      </option>
                      <option
                        key="Radial Gradient Centered"
                        value="radial-gradient(circle, #f4c4f3, #fc67fa)"
                      >
                        Gradient 9
                      </option>
                      <option
                        key="Radial Gradient Centered"
                        value="radial-gradient(circle, #feb47b, #ff7e5f)"
                      >
                        Gradient 10
                      </option>
                    </select>
                  </div>
                )}
              </div>
            )}
            {selectedMainSettingsOption === "title" && (
              <div className="subcontainers">
                <div
                  style={{ margin: "8px 0px" }}
                  className="settings-semi-container"
                >
                  <label className="w-50">Font Size: </label>
                  <select
                    name="titleFontSize"
                    value={content?.template_css?.titleFontSize}
                    onChange={(e) => {
                      handleTemplateSettings(e);
                    }}
                    className="w-50"
                  >
                    {Array.from({ length: 13 }, (_, i) => 16 + i * 2).map(
                      (size) => (
                        <option
                          className="input-option"
                          key={size}
                          value={`${size}px`}
                        >
                          {size}px
                        </option>
                      )
                    )}
                  </select>
                </div>
                <div
                  style={{ margin: "8px 0px" }}
                  className="settings-semi-container"
                >
                  <label className="w-50">Font Weight: </label>
                  <select
                    name="titleFontWeight"
                    className="w-50 input-option"
                    value={content?.template_css?.titleFontWeight}
                    style={{
                      fontWeight: content?.template_css?.titleFontWeight,
                    }}
                    onChange={(e) => {
                      handleTemplateSettings(e);
                    }}
                  >
                    <option
                      className="input-option"
                      key="lighter"
                      value="lighter"
                    >
                      Normal
                    </option>
                    <option
                      className="input-option"
                      style={{ fontWeight: "bold" }}
                      key="bold"
                      value="bold"
                    >
                      Bold
                    </option>
                  </select>
                </div>
              </div>
            )}
            {selectedMainSettingsOption === "text" && (
              <>
                <div className="settings-semi-container subcontainers">
                  <label className="w-50">Text Color: </label>
                  <input
                    className="w-50 input-option"
                    name="textColor"
                    value={content?.template_css?.textColor}
                    onChange={(e) => {
                      handleTemplateSettings(e);
                    }}
                    type="color"
                  />
                </div>
                <div className="settings-semi-container subcontainers">
                  <label className="w-50">Font Family: </label>
                  <select
                    name="textFontFamily"
                    value={content?.template_css?.textFontFamily}
                    onChange={(e) => {
                      handleTemplateSettings(e);
                    }}
                    className="w-50 input-option"
                  >
                    <option
                      className="input-option"
                      style={{ fontFamily: "Roboto" }}
                      value="Roboto"
                    >
                      Roboto
                    </option>
                    <option
                      className="input-option"
                      style={{ fontFamily: "Cavete" }}
                      value="Cavete"
                    >
                      Cavete
                    </option>
                    <option
                      className="input-option"
                      style={{ fontFamily: "Arial" }}
                      value="Arial"
                    >
                      Arial
                    </option>
                    <option
                      className="input-option"
                      style={{ fontFamily: "Helvetica" }}
                      value="Helvetica"
                    >
                      Helvetica
                    </option>
                    <option
                      className="input-option"
                      style={{ fontFamily: "Georgia" }}
                      value="Georgia"
                    >
                      Georgia
                    </option>
                    <option
                      className="input-option"
                      style={{ fontFamily: "Times New Roman" }}
                      value="Times New Roman"
                    >
                      Times New Roman
                    </option>
                    <option
                      className="input-option"
                      style={{ fontFamily: "Courier New" }}
                      value="Courier New"
                    >
                      Courier New
                    </option>
                    <option
                      className="input-option"
                      style={{ fontFamily: "Verdana" }}
                      value="Verdana"
                    >
                      Verdana
                    </option>
                    <option
                      className="input-option"
                      style={{ fontFamily: "Tahoma" }}
                      value="Tahoma"
                    >
                      Tahoma
                    </option>
                    <option
                      className="input-option"
                      style={{ fontFamily: "Trebuchet MS" }}
                      value="Trebuchet MS"
                    >
                      Trebuchet MS
                    </option>
                    <option
                      className="input-option"
                      style={{ fontFamily: "Open Sans" }}
                      value="Open Sans"
                    >
                      Open Sans
                    </option>
                    <option
                      className="input-option"
                      style={{ fontFamily: "Montserrat" }}
                      value="Montserrat"
                    >
                      Montserrat
                    </option>
                    <option
                      className="input-option"
                      style={{ fontFamily: "Ubuntu" }}
                      value="Ubuntu"
                    >
                      Ubuntu
                    </option>
                  </select>
                </div>
                <div className="settings-semi-container subcontainers">
                  <label className="w-50">Font Size: </label>
                  <select
                    name="headingFontSize"
                    value={content?.template_css?.headingFontSize}
                    onChange={(e) => {
                      handleTemplateSettings(e);
                    }}
                    className="w-50 input-option"
                  >
                    {Array.from({ length: 13 }, (_, i) => 16 + i * 2).map(
                      (size) => (
                        <option
                          className="input-option"
                          key={size}
                          value={`${size}px`}
                        >
                          {size}px
                        </option>
                      )
                    )}
                  </select>
                </div>
              </>
            )}
            <Button
              variant="contained"
              style={{
                backgroundColor: "#d32f2f",
                color: "#fff",
                textTransform: "none",
                fontWeight: "bold",
                width: "100%",
              }}
              onClick={() => {
                setContent({
                  ...content,
                  template_css: {
                    ...content?.template_css,
                    bgColor: "",
                    bgImage: "",
                    bgOpacity: null,
                    textColor: "",
                    textFontFamily: "",
                    titleFontSize: "",
                    titleFontWeight: "",
                    headingFontSize: "",
                    bgGradient:
                      "linear-gradient(50deg, #FAACA8 0%, #DDD6F3 80%)",
                  },
                });
              }}
            >
              Clear Settings
            </Button>
          </>
        )}
      </motion.div>
    </AnimatePresence>
  );
};

export default LoadTemplateSettings;
