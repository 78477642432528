export const RenderFooterSection = ({ idOfflinePortrait, css,  }) => {
  const currentYear = new Date().getFullYear();
  return (
    <>
      <div style={{ margin: idOfflinePortrait ? "100px 0px 0px 0" : "50px" }}>
        {/* <p className={stylesTwo.footerHeading}>Forever our love, Thank you</p> */}
        <p
          style={{
            color: css?.textColor ? css?.textColor : "black",
            fontFamily: css?.textFontFamily
              ? css?.textFontFamily
              : "Courier New",
            fontSize: "0px",
          }}
        >
          <p>
            &copy; {currentYear}, Made with ❤️ by{" "}
            <strong>Infinitum Tech</strong>
          </p>
        </p>
      </div>
    </>
  );
};
