import { Outlet } from "react-router-dom";
import Header from "components/header/header";

const DashBoard = () => {
  console.log("version v5");
  return (
    <main>
      <Header />
      <Outlet />
    </main>
  );
};

export default DashBoard;
