import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { blogData } from "./content";
import "./blogss.css"; // Importing CSS file
import HomeHeader from "../../components/homeHeader/homeHeader";
import { BaseFoooter } from "./Footer";

// Normalize the slug to match the format in blogData
const normalizeSlug = (slug) => {
  return slug.toLowerCase().replace(/ /g, '-');
};

const BlogPost = () => {
  const { slug } = useParams();
  useEffect(() => {
      document.title = "Event on Life";
    }, []);

  // Normalize the slug from the URL to match blogData keys
  const normalizedSlug = normalizeSlug(slug);

  // Fetch the blog data based on the normalized slug
  const post = blogData[normalizedSlug];

  if (!post) {
    return <p className="error-message">Blog post not found: {slug}</p>; // Show a message if the post is not found
  }

  return (
    <>
<HomeHeader />
    <div className="blog-container">
      <div
        className="blog-content"
        dangerouslySetInnerHTML={{
          __html: post.content,
        }}
      />
    </div>
    <BaseFoooter/>
    </>
  );
};

export default BlogPost;
