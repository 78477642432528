import React, { useEffect, useState } from "react";
import axios from "axios";
import { TextField, Typography, CircularProgress } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import { useToastContext } from "hooks/usetoastcontext";
import styles from "./styles.module.css";

const ResetPassword = () => {
  const { uid, token } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    document.title = "Event on Life - Reset Password";
  }, []);
  // contexts
  const { showToast } = useToastContext();
  // states
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleResetPassword = async () => {
    const baseUrl = `${process.env.REACT_APP_MEDIA_URL}/api/users/reset_password_confirm/`;
    setIsLoading(true);

    try {
      const response = await axios.post(
        baseUrl,
        {
          uid: uid,
          token: token,
          new_password: password,
          re_new_password: confirmPassword,
        },
        { headers: { "Content-Type": "application/json" } }
      );

      if (response.status === 204) {
        showToast.success("Password reset successfully!");
        // setMessage("Password reset successfully!");
        setIsLoading(false);
        navigate("/");
      }
    } catch (error) {
      setIsLoading(false);
      setMessage("Error resetting password. Please try again.");
      console.error("Password Reset Error: ", error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    if (password.length < 8) {
      setMessage("Password must be at least 8 characters long.");
      return;
    }

    if (password !== confirmPassword) {
      setMessage("Passwords do not match.");
      return;
    }

    handleResetPassword();
  };

  return (
    <div className={styles.resetFormContainer}>
      <div className={styles.resetFormContent}>
        <Typography variant="h4">Reset Password</Typography>
        <form onSubmit={handleSubmit}>
          <div className={styles.inputFieldWrapper}>
            <TextField
              label="New Password"
              variant="outlined"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
              className={styles.inputField}
            />
            <TextField
              label="Confirm Password"
              variant="outlined"
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
              className={styles.inputField}
            />
            <button
              type="submit"
              className={styles.resetFormBtn}
              disabled={isLoading}
            >
              {isLoading ? (
                <>
                  Resetting...
                  <CircularProgress size={24} className={styles.loader} />
                </>
              ) : (
                "Reset Password"
              )}
            </button>
          </div>
        </form>
        {message && (
          <Typography variant="body1" className={styles.message}>
            {message}
          </Typography>
        )}
      </div>
    </div>
  );
};

export default ResetPassword;
