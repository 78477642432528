import { useAppDataContext } from "hooks/useappdatacontext";
import stylesTwo from "./weddingTemplate.module.css";

export const RenderScrollingView = ({ idOfflinePortrait }) => {
  const { singleEventData } = useAppDataContext();
  return (
    <>
      {singleEventData?.scrolling_mess?.length > 0 && (
        <div
          className={`${stylesTwo.advertisementContainer} ${
            idOfflinePortrait
              ? stylesTwo.advertismentL
              : stylesTwo.advertismentP
          }`}
          style={{ width: "90% !important" }}
        >
          <div className={stylesTwo.advertisement}>
            <div>{singleEventData?.scrolling_mess}</div>
          </div>
        </div>
      )}
    </>
  );
};
