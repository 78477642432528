export const extractYouTubeVideoId = (data) => {
  const regExp =
    /^.*((youtu.be\/)|(v\/)|(\/u\/w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = data?.match(regExp);
  return match && match[7].length === 11 ? match[7] : null;
};

export const extractYouTubeLiveId = (data) => {
  const regExp = /youtube\.com\/live\/([^?&]+)/;
  const match = data?.match(regExp);
  return match ? match[1] : null;
};

export   const extractHlsUrl = (data) => {
  const regExp = /https?:\/\/[^\s]*\.m3u8/;
  const match = data?.match(regExp);
  return match ? match[0] : null;
};

export const capitalizeFirstLetter = (str) => {
  return str
    ?.split(" ") // Split the string into words by space
    ?.map(
      (word) => word?.charAt(0).toUpperCase() + word?.slice(1).toLowerCase()
    ) // Capitalize first letter of each word
    ?.join(" "); // Join the words back together with space
};