import img1 from "../../assets/images/sunflower-removebg-preview.png";
import img2 from "../../assets/images/lily-removebg-preview.png";
import img3 from "../../assets/images/rose-removebg-preview.png";
import img4 from "../../assets/images/white_flower-removebg-preview.png";
import img5 from "../../assets/images/leaf-1-removebg-preview.png";
import Slider from "react-slick";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { motion } from "framer-motion";
import styles from "../../components/fancyBoxGallery/fancyBoxGallery.module.css";
import { useState } from "react";
import stylesT2 from "../Custom/T4/styles.module.css";
import HLSPlayer from "../../components/hlsPlayer";
import stylesTwo from "./weddingTemplate.module.css";
import stylesDefault from "../Custom/T2/styles.module.css";

import {
  capitalizeFirstLetter,
  extractHlsUrl,
  extractYouTubeLiveId,
  extractYouTubeVideoId,
} from "../weddingTemplate/comonFunctions";
import { useAppDataContext } from "hooks/useappdatacontext";
import ScrollingMess from "../EditEvent/ScrollingMess";
import { WhatsAppButton } from "./whatsAppSection";

const T2Template = ({
  streamStatus,
  setStreamStatus,
  selectedTemplateOption,
  idOfflinePortrait,
  videoRef,
  handleOfflineVideoMetadata,
}) => {
  const { singleEventData } = useAppDataContext();

  const [overlayImage, setOverlayImage] = useState(null);

  const images = singleEventData?.images?.length > 0 && singleEventData.images;

  const handleImageClick = (image) => {
    setOverlayImage(image);
  };

  const closeOverlay = () => {
    setOverlayImage(null);
  };
  const NextArrow = ({ onClick }) => (
    <MdKeyboardArrowRight className={styles.nextArrow} onClick={onClick} />
  );

  const PrevArrow = ({ onClick }) => (
    <MdKeyboardArrowLeft className={styles.prevArrow} onClick={onClick} />
  );

  const settings = {
    dots: true,
    infinite: images.length > 1,
    speed: 500,
    slidesToShow: images.length === 2 ? 2 : 2, // Ensure correct handling for 2 images
    slidesToScroll: 1,
    autoplay: images.length > 2,
    arrows: images.length > 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        },
      },
    ],
  };

  function formatTime(timeString) {
    if (!timeString) return "Invalid time"; // Handle undefined or empty strings

    const [hour, minute] = timeString.split(":");
    const hourInt = parseInt(hour, 10);
    const isPM = hourInt >= 12;

    const formattedHour = hourInt % 12 === 0 ? 12 : hourInt % 12; // Handle 12-hour clock
    const period = isPM ? "PM" : "AM";

    return `${formattedHour.toString().padStart(2, "0")}:${minute} ${period}`;
  }

  const renderFooterSection = () => {
    const currentYear = new Date().getFullYear();
    return (
      <footer style={{ padding: "20px 0px", textAlign: "center" }}>
        <p>
          &copy; {currentYear}, Made with ❤️ by <strong>Infinitum Tech</strong>
        </p>
      </footer>
    );
  };

  const renderLiveVideoSection = () => {
    const videoId = extractYouTubeVideoId(singleEventData?.live_vidlink);
    const liveId = extractYouTubeLiveId(singleEventData?.live_vidlink);
    const hlsUrl = extractHlsUrl(singleEventData?.live_vidlink);

    if (videoId) {
      return (
        <>
          {singleEventData?.scrolling_mess && (
            <ScrollingMess mess={singleEventData?.scrolling_mess} />
          )}
          <div
            style={{ marginTop: "0px" }}
            className={stylesT2.videoContainerT4}
          >
            <iframe
              className={stylesT2.videoContainerT4Iframe}
              style={{ width: "inherit" }}
              src={`https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="YouTube Video"
            ></iframe>
          </div>
        </>
      );
    } else if (liveId) {
      return (
        <>
          {singleEventData?.scrolling_mess && (
            <ScrollingMess mess={singleEventData?.scrolling_mess} />
          )}
          <div
            style={{ marginTop: "0px" }}
            className={stylesT2.videoContainerT4}
          >
            <iframe
              className={stylesT2.videoContainerT4Iframe}
              style={{ width: "inherit" }}
              title="Live"
              src={`https://www.youtube.com/embed/${liveId}?autoplay=1&mute=1`}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </>
      );
    } else if (hlsUrl) {
      return (
        <>
          {singleEventData?.scrolling_mess && (
            <ScrollingMess mess={singleEventData?.scrolling_mess} />
          )}
          <div
            style={{ marginTop: "0px" }}
            className={stylesT2.videoContainerT4}
          >
            <HLSPlayer
              selectedTemplateOption={selectedTemplateOption}
              weddingPage={true}
              streamStatus={streamStatus}
              setStreamStatus={setStreamStatus}
            />
          </div>
        </>
      );
    } else if (
      singleEventData?.offline_video !== null &&
      singleEventData?.offline_video !== ""
    ) {
      return (
        <>
          {singleEventData?.scrolling_mess && (
            <ScrollingMess mess={singleEventData?.scrolling_mess} />
          )}
          <div
            style={{ marginTop: "0px" }}
            className={`${
              idOfflinePortrait
                ? stylesTwo.videoContainerPortrait
                : stylesT2.videoContainerT4
            }`}
          >
            <video
              ref={videoRef}
              style={{ width: "inherit" }}
              src={singleEventData?.offline_video}
              type="video/mp4"
              controls
              onLoadedMetadata={handleOfflineVideoMetadata}
            />
          </div>
        </>
      );
    }
  };

  const renderInvitationSection = () => {
    return (
      <>
        {singleEventData && singleEventData?.invitation_card ? (
          <div id="invitation">
            <h1 className={stylesT2.cedarvilleCursiveRegularT4}>
              Invitation Card
            </h1>
            <img
              src={singleEventData?.invitation_card}
              style={{
                width: "80%",
                maxHeight: "500px",
                border: "20px white solid",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                borderRadius: "10px",
              }}
              alt="Invitation card"
            />
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              textAlign: "center",
              gap: "30px",
              margin: "85px 0px",
            }}
          >
            {singleEventData?.title && (
              <div className={stylesT2.wrapperT4}>
                <div className={`${stylesT2.containerT4}`}>
                  <h1 className={stylesT2.navHeading}>
                    {singleEventData?.title}
                  </h1>
                </div>
              </div>
            )}
            {singleEventData?.template === "Marriage" && (
              <p
                style={{ fontSize: "1.5rem" }}
                className={stylesT2.marckScriptRegularT4}
              >
                We are getting Married
              </p>
            )}
            {singleEventData?.template === "Birthday" && (
              <p
                style={{ fontSize: "1.5rem" }}
                className={stylesT2.marckScriptRegularT4}
              >
                We are celebrating Birthday
              </p>
            )}
            {singleEventData?.template !== "Birthday" &&
              singleEventData?.template !== "Marriage" && (
                <p
                  style={{ fontSize: "1.5rem" }}
                  className={stylesT2.marckScriptRegularT4}
                >
                  We are Having Party
                </p>
              )}

            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "flex-start",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <p
                style={{
                  fontSize: "1.2rem",
                  padding: "6px",
                  fontFamily: "sans-serif",
                }}
              >
                <strong>When:</strong>
              </p>
              <div>
                <p className={stylesT2.cedarvilleCursiveRegularT4}>
                  {singleEventData?.venue_date
                    ? new Date(singleEventData?.venue_date).toLocaleDateString(
                        "en-GB",
                        {
                          day: "2-digit",
                          month: "short",
                          year: "numeric",
                        }
                      )
                    : "Please Select Date"}
                </p>
                <p>
                  At{" "}
                  <span className={stylesT2.cedarvilleCursiveRegularT4}>
                    {singleEventData?.venue_time
                      ? formatTime(singleEventData?.venue_time)
                      : "Please Select Time"}
                  </span>
                </p>
              </div>
            </div>
            <div
              style={{
                display: "flex",
                gap: "10px",
                alignItems: "center",
                justifyContent: "center",
                textAlign: "center",
              }}
            >
              <p
                style={{
                  fontSize: "1.2rem",
                  padding: "6px",
                  fontFamily: "sans-serif",
                }}
              >
                <strong>Where:</strong>
              </p>
              <p className={stylesT2.marckScriptRegularT4}>
                {singleEventData?.venue
                  ? capitalizeFirstLetter(singleEventData?.venue)
                  : "Please Enter Event Location"}
              </p>
            </div>
            <div className={stylesT2.cedarvilleCursiveRegularT4}>
              {singleEventData?.template_desc ? (
                <p>{capitalizeFirstLetter(singleEventData?.template_desc)}</p>
              ) : (
                <p>Please Enter Event Caption</p>
              )}
            </div>
          </div>
        )}
      </>
    );
  };
  const renderCouplePic = () => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "30px",
          textAlign: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyItems: "center",
          }}
        >
          {singleEventData?.banner && (
            <div
              style={{
                visibility: "visible",
                animationDuration: "1000ms",
                animationName: "zoomIn",
              }}
            >
              <div
                style={{}}
                // className={stylesT2.middleCouplePicT4}
                className={stylesT2.middleCouplePicForEditT4}
              >
                <div className={stylesT2.middleCouplePicInnerForEditT4}>
                  <img
                    src={singleEventData?.banner}
                    alt="event-pic"
                    className=""
                  />
                </div>
                <div
                  // style={{ height: "160px", left: "33px", top: "-7px" }}
                  className={stylesT2.shapeForEditT4}
                >
                  <img
                    src="https://wpocean.com/html/tf/sukun/assets/images/slider/left-shape-3.png"
                    alt=""
                  />
                </div>
                <div className={stylesT2.shape2ForEditT4}>
                  <img
                    src="https://wpocean.com/html/tf/sukun/assets/images/slider/left-shape-4.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>

      // {/* <div style={{ display: "flex", gap: "50px" }}>
      //   <div class="outer-circle">
      //     <div class="clock-face">
      //       <div class="hour-hand"></div>
      //       <div class="minute-hand"></div>
      //       <div class="second-hand"></div>
      //     </div>
      //   </div>
      // </div>  <div className={stylesT2.videoContainerT4}>
      //   <HLSPlayer
      //     selectedTemplateOption={selectedTemplateOption}
      //     weddingPage={true}
      //     streamStatus={streamStatus}
      //     setStreamStatus={setStreamStatus}
      //   />
      // </div>*/}
    );
  };

  const flowersFalling = () => {
    return (
      <div class={stylesT2.flowerContainerT4}>
        <div class={stylesT2.flowerT4}>
          <img style={{ width: "100px" }} src={img1} />
        </div>
        <div class={stylesT2.flowerT4}>
          <img style={{ width: "100px" }} src={img2} />
        </div>
        <div class={stylesT2.flowerT4}>
          <img style={{ width: "100px" }} src={img3} />
        </div>
        <div class={stylesT2.flowerT4}>
          <img style={{ width: "100px" }} src={img4} />
        </div>
        <div class={stylesT2.flowerT4}>
          <img style={{ width: "50px" }} src={img5} />
        </div>
        <div class={stylesT2.flowerT4}>
          <img style={{ width: "100px" }} src={img2} />
        </div>
      </div>
    );
  };
  const renderGallerySection = () => {
    return (
      <div className={stylesT2.videoContainerT4}>
        <div style={{ width: "100%" }} className={styles.galleryContainer}>
          {images.length > 0 ? (
            images.length === 1 ? (
              <motion.div
                className={styles.imageContainer}
                style={{ width: "inherit" }}
                whileHover={{ scale: 1.05, transition: { duration: 0.3 } }}
              >
                <img
                  style={{ width: "inherit" }}
                  src={
                    singleEventData?.images?.length
                      ? images[0]?.image
                      : images[0]
                  } // Ensure correct src for event or fallback images
                  alt="Event"
                  className={styles.thumbnail}
                  onClick={() => handleImageClick(images[0])}
                />
              </motion.div>
            ) : (
              // When there are multiple images, show the slider
              <Slider {...settings}>
                {images.map((image, index) => (
                  <motion.div
                    key={index}
                    className={styles.imageContainer}
                    whileHover={{
                      scale: 1.05,
                      transition: { duration: 0.3 },
                    }}
                  >
                    <img
                      src={
                        singleEventData?.images?.length ? image?.image : image
                      } // Ensure correct src for event or fallback images
                      alt={`Event ${index + 1}`}
                      className={styles.thumbnail}
                      onClick={() => handleImageClick(image)}
                    />
                  </motion.div>
                ))}
              </Slider>
            )
          ) : (
            <h1>No images present...</h1>
          )}
          {overlayImage && (
            <div className={styles.overlay} onClick={closeOverlay}>
              <img
                src={overlayImage?.image || overlayImage} // handle event images or fallback images
                alt="Full Size"
                className={styles.fullImage}
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderNavBar = () => {
    return (
      <div>
        {/* <div
          style={{ backgroundColor: "black" }}
          className={stylesDefault.navbarT2}
        >
          <div className={stylesDefault.navLogoContainerT2}>
            <a href="/">
              <img
                src="https://d1gmn9lhl3xm2s.cloudfront.net/static/logo/neolLogo.jpg"
                className={stylesDefault.navLogoT2}
                alt="nav-logo"
              />
            </a>
          </div>
          <h1
            style={{
              background:
                "linear-gradient(45deg, rgb(217 164 8), rgb(255 173 0)) text",
              WebkitBackgroundClip: "text",
              color: "transparent",

              fontWeight: "bold",
              textAlign: "center",
              fontFamily: "cursive",
            }}
            className={stylesDefault.fontSizeT2}
          >
            {capitalizeFirstLetter(singleEventData?.title)}
          </h1>
        </div>
        // */}
        <div
          style={{
            backgroundColor: "black",
            display:
              singleEventData?.title?.length >= 40 && window.innerWidth <= 768
                ? "none"
                : "block",
          }}
          className={stylesDefault.navbarT2}
        >
          <div className={stylesDefault.navLogoContainerT2}>
            <a href="/">
              <img
                src="https://d1gmn9lhl3xm2s.cloudfront.net/static/logo/neolLogo.jpg"
                className={stylesDefault.navLogoT2}
                alt="nav-logo"
              />
            </a>
          </div>
          <h1
            style={{
              background:
                "linear-gradient(45deg, rgb(217 164 8), rgb(255 173 0)) text",
              WebkitBackgroundClip: "text",
              color: "transparent",

              fontWeight: "bold",
              textAlign: "center",
              fontFamily: "cursive",
            }}
            className={stylesDefault.fontSizeT2}
          >
            {capitalizeFirstLetter(singleEventData?.title)}{" "}
          </h1>
        </div>
        <div
          style={{
            backgroundColor: "black",

            display:
              singleEventData?.title?.length >= 40 && window.innerWidth < 768
                ? "block"
                : "none",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
          className={stylesDefault.navbarMobileT2}
        >
          <div>
            <a href="/">
              <img
                src="https://d1gmn9lhl3xm2s.cloudfront.net/static/logo/neolLogo.jpg"
                className={stylesDefault.navLogoT2}
                alt="nav-logo"
              />
            </a>
          </div>
          <h1
            style={{
              background:
                "linear-gradient(45deg, rgb(217 164 8), rgb(255 173 0)) text",
              WebkitBackgroundClip: "text",
              color: "transparent",

              fontWeight: "bold",
              textAlign: "center",
              fontFamily: "cursive",
            }}
            className={stylesDefault.fontSizeT2}
          >
            {capitalizeFirstLetter(singleEventData?.title)}
          </h1>
        </div>
      </div>
    );
  };
  return (
    <div
      style={{
        backgroundColor: "white",
        height: "100vh",
        width: "100vw",
        overflowX: "hidden",
      }}
    >
      {flowersFalling()}
      <div>
        {renderNavBar()}
        <div style={{ padding: "30px 60px" }}>
          {renderCouplePic()}
          {renderInvitationSection()}
          {renderLiveVideoSection()}
          {renderGallerySection()}
          {renderFooterSection()}
          <WhatsAppButton />
        </div>
      </div>
    </div>
  );
};
export default T2Template;
