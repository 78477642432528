import { useCallback, useEffect, useRef, useState } from "react";
import { useAppDataContext } from "hooks/useappdatacontext";
// import styles from "../../pages/weddingTemplate/weddingTemplate.module.css";
import styles from "../Custom/T30/t3.module.css";
import FancyBoxGallery from "../../components/fancyBoxGallery/fancyBoxGallery";
import HLSPlayer from "../../components/hlsPlayer";
import { FaLocationDot } from "react-icons/fa6";
import VideoPlayerForEdit from "./VideoPlayerForEdit";
import DefaultGallery from "../../components/fancyBoxGallery/DefaultGallery";
import { X, CalendarHeart, Clock, MapPinned } from "lucide-react";
import T3FancyBoxGallery from "../../components/fancyBoxGallery/T3fancyBoxGallery";

const T3RenderTemplate = ({
  hlsLiveUrl,
  streamStatus,
  setStreamStatus,
  selectedOfflineVideo,
  optionData,
  videoSrc,
  selectedInvitationCard,
  selectedInvitationVideo,
  selectedBanner,
}) => {
  const { eventData, singleChannelData, singleEventData, hlsVideoUrl } =
    useAppDataContext();

  const extractYouTubeVideoId = (url) => {
    const regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url?.match(regExp);
    return match && match[7]?.length === 11 ? match[7] : null;
  };

  const extractYouTubeLiveId = (url) => {
    const regExp = /youtube\.com\/live\/([^?&]+)/;
    const match = url?.match(regExp);
    return match ? match[1] : null;
  };

  const previewUrl = (file) => URL.createObjectURL(file);
  const calculateTimeLeft = () => {
    const eventDateTime = new Date(`${eventData?.venue_date}T${eventData.venue_time}`).getTime();
    const now = new Date().getTime();
    const difference = eventDateTime - now;

    if (difference <= 0) return { days: 0, hours: 0, minutes: 0, seconds: 0 };

    return {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [eventData?.venue_date, eventData?.venue_time]);
  const renderFooterSection = () => {
    const currentYear = new Date().getFullYear();

    return (
      <footer className={styles.L302F}>
        <p>
          &copy; {currentYear}, Made with ❤️ by <strong>Infinitum Tech</strong>
        </p>
      </footer>
    );
  };

  const renderScrollingMess = (hider) => {
    return (
      <>
        {eventData?.scrolling_mess.length > 0 && (
          <div style={{ width: "80%" }} className={styles.advertisementContainer}>
            <div style={{ width: "100%" }} className="scrollingmess">
              <div style={{ width: "100%" }} className={styles.advertisement}>
                {eventData?.scrolling_mess}
              </div>
            </div>
          </div>
        )}
      </>
    );
  };
  const renderGallerySection = () => {
    return (
      <div id="gallery" className={styles.galleryBlock}>
        {eventData &&
          Array.isArray(eventData.images) &&
          eventData.images.length > 0 && (
            <>
              <div className={styles.invi1headings}>
                <h1>Our Wedding Gallery</h1>
                <img src="https://preview.colorlib.com/theme/wed/assets/img/gallery/tittle_img.png.webp" alt="" />
              </div>
              <div style={{ width: "100%" }}>
                <T3FancyBoxGallery preview={true} />
              </div>
            </>
          )}
      </div>
    );
  };
  //component to render live video based on available link
  const renderLiveVideoComponent = (hider, preview) => {
    let videoOptions;
    if (hlsVideoUrl) {
      videoOptions = {
        autoplay: false,
        controls: true,
        muted: true,
        sources: [{ src: hlsVideoUrl, type: "application/x-mpegURL" }],
      };
    }

    // Extract YouTube video and live IDs
    const videoId = extractYouTubeVideoId(eventData?.live_vidlink);
    const liveId = extractYouTubeLiveId(eventData?.live_vidlink);

    // Determine the appropriate video URL based on the type
    const videoUrl = videoId
      ? `https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1`
      : "";
    const liveUrl = liveId
      ? `https://www.youtube.com/embed/${liveId}?autoplay=1&mute=1`
      : "";
    return (
      <>
        {optionData?.option === "youtubeChannelId" &&
          optionData?.youtubeLiveUrl && (
            <div style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}>
              <div className={styles.invi1headings}>
                <h1>Live video</h1>
                <img src="https://preview.colorlib.com/theme/wed/assets/img/gallery/tittle_img.png.webp" alt="" />
              </div>
              {renderScrollingMess(hider)}
              <iframe
                frameBorder="0"
                style={{
                  width: "80%",
                  margin: "auto",
                  height: "250px",
                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                  borderRadius: "10px",
                }}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="YouTube Video"
                src={`https://www.youtube.com/embed/${extractYouTubeLiveId(
                  optionData?.youtubeLiveUrl
                )}?autoplay=1&mute=1`}
              ></iframe>
            </div>
          )}
        {optionData?.option === "youtubeStreamedUrl" &&
          optionData?.youtubeStreamedUrl && (
            <div style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}>
              <div className={styles.invi1headings}>
                <h1>Live video</h1>
                <img src="https://preview.colorlib.com/theme/wed/assets/img/gallery/tittle_img.png.webp" alt="" />
              </div>
              {renderScrollingMess(hider)}
              <iframe
                frameBorder="0"
                style={{
                  width: "80%",
                  margin: "auto",
                  height: "250px",
                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                  borderRadius: "10px",
                }}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="YouTube Video"
                src={`https://www.youtube.com/embed/${extractYouTubeVideoId(
                  optionData?.youtubeStreamedUrl
                )}?autoplay=1&mute=1`}
              ></iframe>
            </div>
          )}
        {!optionData?.youtubeStreamedUrl &&
          !eventData?.live_vidlink &&
          hlsVideoUrl && (
            <div style={{ width: "100%", margin: "20px 0px" }}>
              <div className={styles.invi1headings}>
                <h1>Live video</h1>
                <img src="https://preview.colorlib.com/theme/wed/assets/img/gallery/tittle_img.png.webp" alt="" />
              </div>
              {renderScrollingMess(hider)}
              <div
                style={{
                  width: "80%",
                  margin: "auto",
                  border: "20px solid white",
                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                  borderRadius: "10px",
                }}
              >
                <VideoPlayerForEdit options={videoOptions} />
              </div>
            </div>
          )}
        <>
          {(videoId !== null || liveId !== null) && hlsVideoUrl === "" && (
            <div
              className={`${preview ? "box-border-styling-80" : "box-border-styling-100"
                }`}
              style={{ margin: "20px 0px" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  // backgroundImage: `${hider && `url('${image1}')`}`,
                }}
                className="video-container"
              >
                {videoUrl && !liveUrl && (
                  <div style={{ width: "100%", margin: "auto" }}>
                    <div className={styles.invi1headings}>
                      <h1>Live video</h1>
                      <img src="https://preview.colorlib.com/theme/wed/assets/img/gallery/tittle_img.png.webp" alt="" />
                    </div>
                    {renderScrollingMess()}
                    <div class={styles.frameContainer}>
                      <iframe
                        style={{
                          width: hider ? "80%" : "100%",
                          margin: "auto",
                          height: "250px",
                          borderRadius: "8px",
                        }}
                        src={videoUrl}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="YouTube Video"
                      ></iframe>
                    </div>
                  </div>
                )}

                {liveUrl && !videoUrl && (
                  <div style={{ width: "100%", margin: "auto" }}>
                    <div className={styles.invi1headings}>
                      <h1>Live video</h1>
                      <img src="https://preview.colorlib.com/theme/wed/assets/img/gallery/tittle_img.png.webp" alt="" />
                    </div>
                    {renderScrollingMess(hider)}
                    <div class={styles.frameContainer}>
                      <iframe
                        style={{
                          borderRadius: "8px",
                          width: hider ? "80%" : "100%",
                          margin: "auto",
                          height: "250px",
                        }}
                        src={liveUrl}
                        frameBorder="3"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="YouTube Live"
                      ></iframe>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </>
        {hlsLiveUrl && !videoUrl && !liveUrl && (
          <div
            className={`${preview ? "box-border-styling-80" : "box-border-styling-100"
              }`}
            style={{ margin: "20px 0px" }}
          >
            <div className={styles.invi1headings}>
              <h1>Live video</h1>
              <img src="https://preview.colorlib.com/theme/wed/assets/img/gallery/tittle_img.png.webp" alt="" />
            </div>
            {renderScrollingMess(hider)}

            <div
              className={`video-container-hlsplayer ${styles.videocontainer}`}
            >
              <HLSPlayer
                selectedTemplateOption="t3"
                singleChannelData={singleChannelData}
                editPage={true}
                preview={true}
                hider={true}
                streamStatus={streamStatus}
                setStreamStatus={setStreamStatus}
              />
            </div>
          </div>
        )}
      </>
    );
  };
  const renderOfflineVideoModificationAndViewingection = (hider) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        {hider && (
          <div className={styles.invi1headings}>
            <h1>Offline Video</h1>
            <img src="https://preview.colorlib.com/theme/wed/assets/img/gallery/tittle_img.png.webp" alt="Divider" />
          </div>
        )}

        {optionData?.option === "file" && (
          <div style={{ marginTop: "10px" }}>
            <div className={styles.invi1headings}>
              <h1>Offline Video</h1>
              <img src="https://preview.colorlib.com/theme/wed/assets/img/gallery/tittle_img.png.webp" alt="Divider" />
            </div>
            {renderScrollingMess(hider)}

            <div className={styles.videocontainer}>
              <video controls>
                <source src={previewUrl(optionData?.file)} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>

        )}

        {selectedOfflineVideo && selectedOfflineVideo !== null ? (
          <div className={styles.invivideo}>
            <div className={styles.invi1headings}>
              <h1>Offline Video</h1>
              <img src="https://preview.colorlib.com/theme/wed/assets/img/gallery/tittle_img.png.webp" alt="Divider" />
            </div>
            {renderScrollingMess(hider)}
            <div className={styles.videocontainer}>
              <video controls>
                <source src={videoSrc} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        ) : (
          eventData?.offline_video &&
          eventData?.offline_video != null && (
            <div className={styles.invivideo}>
              <div className={styles.invi1headings}>
                <h1>Offline Video</h1>
                <img src="https://preview.colorlib.com/theme/wed/assets/img/gallery/tittle_img.png.webp" alt="Divider" />
              </div>
              {renderScrollingMess(hider)}
              <div className={styles.videocontainer}>
                <video controls>
                  <source src={eventData?.offline_video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          )
        )}
      </div>
    );
  };

  const renderInvitationSection = () => {
    function formatTime(timeString) {
      if (!timeString) return "Invalid time"; // Handle undefined or empty strings

      const [hour, minute] = timeString.split(":");
      const hourInt = parseInt(hour, 10);
      const isPM = hourInt >= 12;

      const formattedHour = hourInt % 12 === 0 ? 12 : hourInt % 12; // Handle 12-hour clock
      const period = isPM ? "PM" : "AM";

      return `${formattedHour.toString().padStart(2, "0")}:${minute} ${period}`;
    }
    return (
      <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        {selectedInvitationCard ? (
          <div id="invitation" className={styles.invi1bg}>
            <div className={styles.invi1headings}>
              <h1>Wedding Info</h1>
              <img src="https://preview.colorlib.com/theme/wed/assets/img/gallery/tittle_img.png.webp" alt="" />
            </div>
            {selectedInvitationCard && (
              <img
                src={previewUrl(selectedInvitationCard)}
                className={styles.t3invitaitonImage}
                alt="Invitation card"
              />
            )}
          </div>
        ) : (
          <>
            {
              eventData && eventData.invitation_card ? (
                <div id="invitation" className={styles.invi1bg}>
                  <div className={styles.invi1bg}>
                    <div className={styles.invi1headings}>
                      <h1>Wedding Info</h1>
                      <img src="https://preview.colorlib.com/theme/wed/assets/img/gallery/tittle_img.png.webp" alt="" />
                    </div>
                    <img
                      className={styles.t3invitaitonImage}
                      src={singleEventData?.invitation_card}
                      alt="Invitation card"
                    />
                  </div>
                </div>
              ) : (
                <div className={styles.invi1bg}>
                  <div className={styles.invi1headings}>
                    <h1>Wedding Info</h1>
                    <img src="https://preview.colorlib.com/theme/wed/assets/img/gallery/tittle_img.png.webp" alt="" />
                  </div>
                  <div className={styles.invi1content}>
                    <div className={styles.detailscontainer}>
                      <div className={styles.detailbox}>
                        <CalendarHeart className={styles.detailboxicon} />
                        <h2>Date</h2>
                        <p>{singleEventData?.venue_date}</p>
                      </div>
                      <div className={styles.detailbox}>
                        <Clock className={styles.detailboxicon} />

                        <h2>Time</h2>
                        <p>{singleEventData?.venue_time}</p>
                      </div>
                      <div className={styles.detailbox}>
                        <MapPinned className={styles.detailboxicon} />

                        <h2>Venue</h2>
                        <p>{singleEventData?.venue}</p>
                      </div>
                    </div>
                    <p>{singleEventData?.template_desc || "All are Invited"}</p>
                    <div className={styles.countdowncontainer}>
                      <div className={styles.countdownbox}>
                        <p>{timeLeft.days}</p>
                        <span>Days</span>
                      </div>
                      <div className={styles.countdownbox}>
                        <p>{timeLeft.hours}</p>
                        <span>Hours</span>
                      </div>
                      <div className={styles.countdownbox}>
                        <p>{timeLeft.minutes}</p>
                        <span>Mins</span>
                      </div>
                      <div className={styles.countdownbox}>
                        <p>{timeLeft.seconds}</p>
                        <span>Secs</span>
                      </div>
                    </div>
                  </div>
                </div>
              )
            }
          </>
        )}
        {selectedInvitationVideo && (
          <>
            {/* {isPortrait ? ( */}
            <div className={styles.invivideo}>
              <div className={styles.invi1headings}>
                <h1>Invitation Video</h1>
                <img src="https://preview.colorlib.com/theme/wed/assets/img/gallery/tittle_img.png.webp" alt="Divider" />
              </div>
              <div className={styles.videocontainer}>
                <video controls>
                  <source src={previewUrl(selectedInvitationVideo)} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </>
        )}

        {eventData?.invitation_video && selectedInvitationVideo === null && (
          <>
            <div className={styles.invivideo}>
              <div className={styles.invi1headings}>
                <h1>Invitation Video</h1>
                <img src="https://preview.colorlib.com/theme/wed/assets/img/gallery/tittle_img.png.webp" alt="Divider" />
              </div>
              <div className={styles.videocontainer}>
                <video controls>
                  <source src={eventData?.invitation_video} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </div>
            </div>
          </>
        )}
      </div>
    );
  };
  const capitalizeFirstLetter = (str) => {
    return str
      ?.split(" ") // Split the string into words by space
      ?.map(
        (word) => word?.charAt(0).toUpperCase() + word?.slice(1).toLowerCase()
      ) // Capitalize first letter of each word
      ?.join(" "); // Join the words back together with space
  };
  const renderBannerImage = (preview) => {
    const fontSize =
      singleEventData.title && singleEventData.title.length > 0 && singleEventData.title.length < 10
        ? "40px"
        : singleEventData.title && singleEventData.title.length > 20 && singleEventData.title.length < 40
          ? "20px"
          : singleEventData.title && singleEventData.title.length >= 40
            ? "16px"
            : "30px";
    return (
      <>
        {
          selectedBanner ? (
            <div>
              <section
                id="banner"

              >
                <div className={styles.bgbanner}
                  style={{
                    backgroundImage: eventData?.banner ? `url(${eventData.banner})` : "none",
                    minHeight: "300px",
                    maxHeight: "500px",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}>

                  <h1 style={{ fontSize }}>{capitalizeFirstLetter(singleEventData?.title)}</h1>
                </div>
              </section>
            </div>
          ) : (
            eventData?.banner !== null ? (
              <section id="banner">
                <div
                  className={styles.bgbanner}
                  style={{
                    backgroundImage: eventData?.banner ? `url(${eventData.banner})` : "none",
                    minHeight: "300px",
                    maxHeight: "500px",
                    backgroundSize: "cover",
                    backgroundPosition: "center",
                    backgroundRepeat: "no-repeat",
                  }}
                >
                  <h1 style={{ fontSize, margin: 0, padding: "20px", color: "#fff" }}>
                    {capitalizeFirstLetter(singleEventData?.title)}
                  </h1>
                </div>
              </section>

            )
              : (
                <div style={{ width: "100%", textAlign: "center" }}>
                  <h1 style={{ fontSize }}>{capitalizeFirstLetter(singleEventData?.title)}</h1>
                </div>
              )
          )
        }
      </>
    );
  };

  const renderNavigation = () => {
    function capitalizeFirstLetter(str) {
      return str
        ?.split(" ") // Split the string into words by space
        ?.map(
          (word) =>
            word?.charAt(0)?.toUpperCase() + word?.slice(1).toLowerCase()
        ) // Capitalize first letter of each word
        ?.join(" "); // Join the words back together with space
    }

    return (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#333",
          }}
        >
          <h1
            style={{
              background:
                "linear-gradient(45deg, rgb(0, 0, 0), rgb(205, 205, 205), rgb(217, 164, 8), rgb(255, 173, 0)) text",
              color: "transparent",
              fontWeight: "bold",
              textAlign: "center",
              fontFamily: "cursive",
            }}
          >
            {capitalizeFirstLetter(eventData?.title)}
          </h1>
          {eventData?.scrolling_mess &&
            !optionData?.option &&
            !hlsVideoUrl &&
            eventData?.live_vidlink === "" &&
            eventData?.offline_video === "" && (
              <div style={{ width: "100%" }}>
                <div style={{ width: "100%" }} className="scrollingmess">
                  <div
                    style={{ width: "100%" }}
                    className={styles.advertisement}
                  >
                    {eventData?.scrolling_mess}
                  </div>
                </div>
              </div>
            )}
        </div>
      </>
    );
  };
  return (
    <div style={{ width: "100%" }} className={styles.container}>
      {renderBannerImage()}
      {renderInvitationSection()}
      {(singleEventData?.offline_video && eventData?.offline_video) ||
        selectedOfflineVideo ||
        optionData?.option === "file"
        ? renderOfflineVideoModificationAndViewingection()
        : renderLiveVideoComponent()}
      {renderGallerySection()}
      {renderFooterSection()}
    </div>
  );
};

export default T3RenderTemplate;
