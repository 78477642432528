import { useEffect, useState } from "react";
import SearchBar from "components/searchBar/searchBar";
import MuiCard from "components/muiCard/muiCard";
import CrudTable from "components/crudTable/page";
import { useAppDataContext } from "hooks/useappdatacontext";
import styles from "./styles.module.css";
import { useAuthContext } from "../../hooks/useauthcontext";
import { BiCloudUpload, BiLogoYoutube } from "react-icons/bi";

const Dashboard = () => {
  const { allEventsData, userCredits } = useAppDataContext();
  const { userData } = useAuthContext();

  const [searchQuery, setSearchQuery] = useState("");

  // state and effect to show loading view
  const [LoadingViewFor5, setLoadingViewFor5] = useState(true);

  useEffect(() => {
    let timer = setTimeout(() => {
      setLoadingViewFor5(false);
    }, 4500);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
  };

  const renderTopSection = () => {
    return (
      <SearchBar
        searchQuery={searchQuery}
        handleSearchChange={handleSearchChange}
      />
    );
  };

  const renderMiddleSection = () => {
    return (
      <div style={{ textAlign: "center" }}>
        <h4>Credits</h4>

        <div className={styles.cardContainer}>
          <div className={styles.card} style={{ backgroundColor: "#FF7519" }}>
            <h3>Event </h3>
            <p className={styles.cardPara}>{userCredits.credits}</p>
          </div>

          <div className={styles.card} style={{ backgroundColor: "#ab8ece" }}>
            <h3>Youtube </h3>
            <p className={styles.cardPara}>{userCredits.yt_credits}</p>
          </div>
        </div>
      </div>
    );
  };

  const renderBottomSection = () => {
    return <CrudTable searchQuery={searchQuery} />;
  };

  return (
    <>
      <div
        style={{
          display: `${LoadingViewFor5 === false ? "inline" : "none"}`,
        }}
        className={styles.mainContainerForPage}
      >
        <div className={styles.maincomp}>
          {renderTopSection()}

          {userData?.is_admin === false && renderMiddleSection()}
          {renderBottomSection()}
        </div>
      </div>
      <div
        style={{
          display: `${LoadingViewFor5 ? "flex" : "none"}`,
          opacity: "1",
          transition: "opacity 1s ease-in-out",
        }}
        className={styles.loaderContainer}
      >
        <div className={styles.loader}></div>
      </div>
    </>
  );
};

export default Dashboard;
