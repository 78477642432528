import img1 from "../../assets/images/sunflower-removebg-preview.png";
import img2 from "../../assets/images/lily-removebg-preview.png";
import img3 from "../../assets/images/rose-removebg-preview.png";
import img4 from "../../assets/images/white_flower-removebg-preview.png";
import img5 from "../../assets/images/leaf-1-removebg-preview.png";
import Slider from "react-slick";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { motion } from "framer-motion";
import styles from "../../components/fancyBoxGallery/fancyBoxGallery.module.css";
import { useState } from "react";
import stylesT2 from "../Custom/T4/styles.module.css";
import HLSPlayer from "../../components/hlsPlayer";
import { useAppDataContext } from "hooks/useappdatacontext";
import {
  capitalizeFirstLetter,
  extractYouTubeLiveId,
  extractYouTubeVideoId,
} from "../weddingTemplate/comonFunctions";
import ScrollingMess from "./ScrollingMess";
import VideoPlayerForEdit from "./VideoPlayerForEdit";
import styles2 from "../Custom/T2/styles.module.css";

export default function T2({
  edit,
  hlsLiveUrl,
  streamStatus,
  setStreamStatus,
  selectedOfflineVideo,
  optionData,
  videoSrc,
  selectedInvitationCard,
  selectedBanner,
  selectedTemplateOption,
}) {
  const { eventData, singleChannelData, singleEventData, hlsVideoUrl } =
    useAppDataContext();
  const [overlayImage, setOverlayImage] = useState(null);

  const images = singleEventData?.images?.length > 0 && singleEventData.images;

  const previewUrl = (file) => URL.createObjectURL(file);

  function formatTime(timeString) {
    if (!timeString) return "Invalid time"; // Handle undefined or empty strings

    const [hour, minute] = timeString.split(":");
    const hourInt = parseInt(hour, 10);
    const isPM = hourInt >= 12;

    const formattedHour = hourInt % 12 === 0 ? 12 : hourInt % 12; // Handle 12-hour clock
    const period = isPM ? "PM" : "AM";

    return `${formattedHour.toString().padStart(2, "0")}:${minute} ${period}`;
  }

  const handleImageClick = (image) => {
    setOverlayImage(image);
  };

  const closeOverlay = () => {
    setOverlayImage(null);
  };
  const NextArrow = ({ onClick }) => (
    <MdKeyboardArrowRight className={styles.nextArrow} onClick={onClick} />
  );

  const PrevArrow = ({ onClick }) => (
    <MdKeyboardArrowLeft className={styles.prevArrow} onClick={onClick} />
  );

  const settings = {
    dots: true,
    infinite: images.length > 1,
    speed: 500,
    slidesToShow: images.length === 2 ? 2 : 2, // Ensure correct handling for 2 images
    slidesToScroll: 1,
    autoplay: images.length > 2,
    arrows: images.length > 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
    responsive: [
      {
        breakpoint: 850,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        },
      },
    ],
  };

  const renderFooterSection = () => {
    const currentYear = new Date().getFullYear();
    return (
      <footer style={{ padding: "20px 0px", textAlign: "center" }}>
        <p>
          &copy; {currentYear}, Made with ❤️ by <strong>Infinitum Tech</strong>
        </p>
      </footer>
    );
  };

  //component to render live video based on available link
  const renderLiveVideoComponent = (hider, preview) => {
    let videoOptions;
    if (hlsVideoUrl) {
      videoOptions = {
        autoplay: false,
        controls: true,
        muted: true,
        sources: [{ src: hlsVideoUrl, type: "application/x-mpegURL" }],
      };
    }

    // Extract YouTube video and live IDs
    const videoId = extractYouTubeVideoId(eventData?.live_vidlink);
    const liveId = extractYouTubeLiveId(eventData?.live_vidlink);

    // Determine the appropriate video URL based on the type
    const videoUrl = videoId
      ? `https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1`
      : "";
    const liveUrl = liveId
      ? `https://www.youtube.com/embed/${liveId}?autoplay=1&mute=1`
      : "";
    return (
      <>
        {optionData?.option === "youtubeChannelId" &&
          optionData?.youtubeLiveUrl && (
            <div style={{ width: "100%", margin: "20px 0px" }}>
              {eventData?.scrolling_mess && (
                <ScrollingMess
                  edit={true}
                  templtae="t2"
                  mess={eventData?.scrolling_mess}
                />
              )}
              <iframe
                frameBorder="0"
                style={{
                  width: "80%",
                  margin: "auto",
                  height: "250px",
                  border: "20px solid white",
                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                  borderRadius: "10px",
                }}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="YouTube Video"
                src={`https://www.youtube.com/embed/${extractYouTubeLiveId(
                  optionData?.youtubeLiveUrl
                )}?autoplay=1&mute=1`}
              ></iframe>
            </div>
          )}
        {optionData?.option === "youtubeStreamedUrl" &&
          optionData?.youtubeStreamedUrl && (
            <div style={{ width: "100%", margin: "20px 0px" }}>
              {eventData?.scrolling_mess && (
                <ScrollingMess
                  edit={true}
                  templtae="t2"
                  mess={eventData?.scrolling_mess}
                />
              )}
              <iframe
                frameBorder="0"
                style={{
                  width: "80%",
                  margin: "auto",
                  height: "250px",
                  border: "20px solid white",
                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                  borderRadius: "10px",
                }}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="YouTube Video"
                src={`https://www.youtube.com/embed/${extractYouTubeVideoId(
                  optionData?.youtubeStreamedUrl
                )}?autoplay=1&mute=1`}
              ></iframe>
            </div>
          )}
        {!optionData?.youtubeStreamedUrl &&
          !eventData?.live_vidlink &&
          hlsVideoUrl && (
            <div style={{ width: "100%", margin: "20px 0px" }}>
              {eventData?.scrolling_mess && (
                <ScrollingMess
                  edit={true}
                  templtae="t2"
                  mess={eventData?.scrolling_mess}
                />
              )}
              <div
                style={{
                  width: "80%",
                  margin: "auto",
                  border: "20px solid white",
                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                  borderRadius: "10px",
                }}
              >
                <VideoPlayerForEdit options={videoOptions} />
              </div>
            </div>
          )}
        <>
          {(videoId !== null || liveId !== null) && hlsVideoUrl === "" && (
            <div
              className={`${
                preview ? "box-border-styling-80" : "box-border-styling-100"
              }`}
              style={{ margin: "20px 0px" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                className="video-container"
              >
                {videoUrl && !liveUrl && (
                  <div style={{ width: "100%", margin: "auto" }}>
                    {eventData?.scrolling_mess && (
                      <ScrollingMess
                        edit={true}
                        templtae="t2"
                        mess={eventData?.scrolling_mess}
                      />
                    )}
                    <div className={styles2.frameContainer}>
                      <iframe
                        style={{
                          width: hider ? "80%" : "100%",
                          margin: "auto",
                          height: "250px",
                          borderRadius: "8px",
                        }}
                        src={videoUrl}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="YouTube Video"
                      ></iframe>
                    </div>
                  </div>
                )}

                {liveUrl && !videoUrl && (
                  <div style={{ width: "100%", margin: "auto" }}>
                    {eventData?.scrolling_mess && (
                      <ScrollingMess
                        edit={true}
                        templtae="t2"
                        mess={eventData?.scrolling_mess}
                      />
                    )}
                    <div className={styles2.frameContainer}>
                      <iframe
                        style={{
                          borderRadius: "8px",
                          width: hider ? "80%" : "100%",
                          margin: "auto",
                          height: "250px",
                        }}
                        src={liveUrl}
                        frameBorder="3"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="YouTube Live"
                      ></iframe>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </>
        {hlsLiveUrl && !videoUrl && !liveUrl && (
          <div style={{ width: "100%" }}>
            {eventData?.scrolling_mess && (
              <ScrollingMess
                edit={true}
                templtae="t2"
                mess={eventData?.scrolling_mess}
              />
            )}
            <div
              style={{ margin: "0px" }}
              className={stylesT2.videoContainerT4}
            >
              <HLSPlayer
                selectedTemplateOption={selectedTemplateOption}
                singleChannelData={singleChannelData}
                editPage={true}
                preview={true}
                hider={true}
                streamStatus={streamStatus}
                setStreamStatus={setStreamStatus}
              />
            </div>
          </div>
        )}
      </>
    );
  };

  const renderOfflineVideoModificationAndViewingection = (hider) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        {optionData?.option === "file" && (
          <div style={{ marginTop: "10px" }}>
            {eventData?.scrolling_mess && (
              <ScrollingMess
                edit={true}
                templtae="t2"
                mess={eventData?.scrolling_mess}
              />
            )}
            <div style={{ width: "100%" }}>
              <video
                style={{
                  width: "80%",
                  margin: "auto",
                  border: "20px solid white",
                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                  borderRadius: "10px",
                }}
                controls
                src={previewUrl(optionData?.file)}
              />
            </div>
          </div>
        )}

        {selectedOfflineVideo && selectedOfflineVideo !== null ? (
          <div style={{ marginTop: "10px" }} className="video-container">
            {eventData?.scrolling_mess && (
              <ScrollingMess
                edit={true}
                templtae="t2"
                mess={eventData?.scrolling_mess}
              />
            )}
            <video
              src={videoSrc}
              controls
              style={{
                width: "80%",
                margin: "auto",
                border: "20px solid white",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                borderRadius: "10px",
              }}
            />
          </div>
        ) : (
          eventData?.offline_video &&
          eventData?.offline_video != null && (
            <div
              className="video-container"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              {eventData?.scrolling_mess && (
                <ScrollingMess
                  edit={true}
                  templtae="t2"
                  mess={eventData?.scrolling_mess}
                />
              )}
              <video
                controls
                src={eventData?.offline_video}
                style={{
                  width: "80%",
                  margin: "auto",
                  border: "20px solid white",
                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                  borderRadius: "10px",
                }}
              />
            </div>
          )
        )}
      </div>
    );
  };

  const renderNavigation = () => {
    function capitalizeFirstLetter(str) {
      return str
        ?.split(" ") // Split the string into words by space
        ?.map(
          (word) =>
            word?.charAt(0)?.toUpperCase() + word?.slice(1).toLowerCase()
        ) // Capitalize first letter of each word
        ?.join(" "); // Join the words back together with space
    }

    return (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "black",
          }}
        >
          <h1
            style={{
              background:
                "linear-gradient(45deg, rgb(217, 164, 8), rgb(255, 173, 0)) text",
              color: "transparent",
              fontWeight: "bold",
              textAlign: "center",
              fontFamily: "cursive",
              padding: "30px 0px",
            }}
            className={stylesT2.fontSizeT4}
          >
            {capitalizeFirstLetter(eventData?.title)}
          </h1>
          {eventData?.scrolling_mess &&
            !optionData?.option &&
            !hlsVideoUrl &&
            eventData?.live_vidlink === "" &&
            eventData?.offline_video === "" && (
              <div style={{ width: "100%" }}>
                <div
                  style={{ width: "100%" }}
                  className={stylesT2.scrollingMessT4}
                >
                  <div
                    style={{ width: "100%" }}
                    className={stylesT2.advertisementT4}
                  >
                    {eventData?.scrolling_mess}
                  </div>
                </div>
              </div>
            )}
        </div>
      </>
    );
  };

  return (
    <div>
      <div className={stylesT2.flowerContainerT4}>
        <div className={stylesT2.flowerT4}>
          <img style={{ width: "100px" }} src={img1} />
        </div>
        <div className={stylesT2.flowerT4}>
          <img style={{ width: "100px" }} src={img2} />
        </div>
        <div className={stylesT2.flowerT4}>
          <img style={{ width: "100px" }} src={img3} />
        </div>
        <div className={stylesT2.flowerT4}>
          <img style={{ width: "100px" }} src={img4} />
        </div>
        <div className={stylesT2.flowerT4}>
          <img style={{ width: "50px" }} src={img5} />
        </div>
        <div className={stylesT2.flowerT4}>
          <img style={{ width: "100px" }} src={img1} />
        </div>
        <div className={stylesT2.flowerT4}>
          <img style={{ width: "100px" }} src={img3} />
        </div>
      </div>
      {renderNavigation()}
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          padding: "50px 30px",
          gap: "30px",
          textAlign: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyItems: "center",
          }}
        >
          {(selectedBanner || eventData?.banner) && (
            <div
              style={{
                visibility: "visible",
                animationDuration: "1000ms",
                animationName: "zoomIn",
              }}
            >
              <div
                style={{}}
                // className={stylesT2.middleCouplePicT4}
                className={stylesT2.middleCouplePicForEditT4}
              >
                <div className={stylesT2.middleCouplePicInnerForEditT4}>
                  <img
                    src={
                      selectedBanner
                        ? previewUrl(selectedBanner)
                        : eventData?.banner
                    }
                    alt="event-pic"
                    className=""
                  />
                </div>
                <div
                  // style={{ height: "160px", left: "33px", top: "-7px" }}
                  className={stylesT2.shapeForEditT4}
                >
                  <img
                    src="https://wpocean.com/html/tf/sukun/assets/images/slider/left-shape-3.png"
                    alt=""
                  />
                </div>
                <div className={stylesT2.shape2ForEditT4}>
                  <img
                    src="https://wpocean.com/html/tf/sukun/assets/images/slider/left-shape-4.png"
                    alt=""
                  />
                </div>
              </div>
            </div>
          )}
        </div>

        {selectedInvitationCard ? (
          <div id="invitation">
            <h1 className={stylesT2.cedarvilleCursiveRegularT4}>
              Invitation Card
            </h1>
            <img
              src={previewUrl(selectedInvitationCard)}
              style={{
                width: "80%",
                maxHeight: "500px",
                border: "20px white solid",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                borderRadius: "10px",
              }}
              alt="Invitation card"
            />
          </div>
        ) : (
          <>
            {eventData && eventData.invitation_card ? (
              <div id="invitation">
                <h1 className={stylesT2.cedarvilleCursiveRegularT4}>
                  Invitation Card
                </h1>
                <img
                  src={eventData.invitation_card}
                  style={{
                    width: "80%",
                    maxHeight: "500px",
                    border: "20px white solid",
                    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                    borderRadius: "10px",
                  }}
                  alt="Invitation card"
                />
              </div>
            ) : (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  textAlign: "center",
                  gap: "30px",
                }}
              >
                {eventData?.title && (
                  <div className={stylesT2.wrapperT4}>
                    <div className={stylesT2.containerT4}>
                      <h1>{eventData?.title}</h1>
                    </div>
                  </div>
                )}
                {eventData?.template === "Marriage" && (
                  <p
                    style={{ fontSize: "1.5rem" }}
                    className={stylesT2.marckScriptRegularT4}
                  >
                    We are getting Married
                  </p>
                )}
                {eventData?.template === "Birthday" && (
                  <p
                    style={{ fontSize: "1.5rem" }}
                    className={stylesT2.marckScriptRegularT4}
                  >
                    We are celebrating Birthday
                  </p>
                )}
                {eventData?.template !== "Birthday" &&
                  eventData?.template !== "Marriage" && (
                    <p
                      style={{ fontSize: "1.5rem" }}
                      className={stylesT2.marckScriptRegularT4}
                    >
                      We are Having Party
                    </p>
                  )}

                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "flex-start",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <p
                    style={{
                      fontSize: "1.2rem",
                      padding: "6px",
                      fontFamily: "sans-serif",
                    }}
                  >
                    <strong>When:</strong>
                  </p>
                  <div>
                    <p className={stylesT2.cedarvilleCursiveRegularT4}>
                      {eventData?.venue_date
                        ? new Date(eventData?.venue_date).toLocaleDateString(
                            "en-GB",
                            {
                              day: "2-digit",
                              month: "short",
                              year: "numeric",
                            }
                          )
                        : "Please Select Date"}
                    </p>
                    <p>
                      At{" "}
                      <span className={stylesT2.cedarvilleCursiveRegularT4}>
                        {eventData?.venue_time
                          ? formatTime(eventData?.venue_time)
                          : "Please Select Time"}
                      </span>
                    </p>
                  </div>
                </div>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    alignItems: "center",
                    justifyContent: "center",
                    textAlign: "center",
                  }}
                >
                  <p
                    style={{
                      fontSize: "1.2rem",
                      padding: "6px",
                      fontFamily: "sans-serif",
                    }}
                  >
                    <strong>Where:</strong>
                  </p>
                  <p className={stylesT2.marckScriptRegularT4}>
                    {eventData?.venue
                      ? capitalizeFirstLetter(eventData?.venue)
                      : "Please Enter Event Location"}
                  </p>
                </div>
                <div className={stylesT2.cedarvilleCursiveRegularT4}>
                  {eventData?.template_desc ? (
                    <p>{capitalizeFirstLetter(eventData?.template_desc)}</p>
                  ) : (
                    <p>Please Enter Event Caption</p>
                  )}
                </div>
              </div>
            )}
          </>
        )}

        {/* <div style={{ display: "flex", gap: "50px" }}>
            <div className="outer-circle">
              <div className="clock-face">
                <div className="hour-hand"></div>
                <div className="minute-hand"></div>
                <div className="second-hand"></div>
              </div>
            </div>
          </div> */}

        {(singleEventData?.offline_video && eventData?.offline_video) ||
        selectedOfflineVideo ||
        optionData?.option === "file"
          ? renderOfflineVideoModificationAndViewingection()
          : renderLiveVideoComponent()}

        <div className={stylesT2.videoContainerT4}>
          <div style={{ width: "100%" }} className={styles.galleryContainer}>
            {images.length > 0 ? (
              images.length === 1 ? (
                <motion.div
                  className={styles.imageContainer}
                  style={{ width: "inherit" }}
                  whileHover={{ scale: 1.05, transition: { duration: 0.3 } }}
                >
                  <img
                    style={{ width: "inherit" }}
                    src={
                      singleEventData?.images?.length
                        ? images[0]?.image
                        : images[0]
                    } // Ensure correct src for event or fallback images
                    alt="Event"
                    className={styles.thumbnail}
                    onClick={() => handleImageClick(images[0])}
                  />
                </motion.div>
              ) : (
                // When there are multiple images, show the slider
                <Slider {...settings}>
                  {images.map((image, index) => (
                    <motion.div
                      key={index}
                      className={styles.imageContainer}
                      whileHover={{
                        scale: 1.05,
                        transition: { duration: 0.3 },
                      }}
                    >
                      <img
                        src={
                          singleEventData?.images?.length ? image?.image : image
                        } // Ensure correct src for event or fallback images
                        alt={`Event ${index + 1}`}
                        className={styles.thumbnail}
                        onClick={() => handleImageClick(image)}
                      />
                    </motion.div>
                  ))}
                </Slider>
              )
            ) : (
              <h1>No images present...</h1>
            )}
            {overlayImage && (
              <div className={styles.overlay} onClick={closeOverlay}>
                <img
                  src={overlayImage?.image || overlayImage} // handle event images or fallback images
                  alt="Full Size"
                  className={styles.fullImage}
                />
              </div>
            )}
          </div>
        </div>

        {renderFooterSection()}
      </div>
    </div>
  );
}
