import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import HomeHeader from "../../components/homeHeader/homeHeader";
import { blogData } from "./content";
import { BaseFoooter } from "./Footer";

const BlogPageMain = () => {
  useEffect(() => {
    document.title = "Event on Life - Blogs";
  }, []);
  const styles = {
    container: {
      width: "100%",
      minHeight: "100vh", // Ensure the container takes at least the full viewport height
      display: "flex",
      flexDirection: "column",
      justifyContent: "center",
      alignItems: "center",
      backgroundColor: "#f8f9fa", // Light gray background for a clean look
      padding: "20px", // Add padding to avoid content touching the edges
    },
    card: {
      width: "90%", // Slightly wider for better readability
      maxWidth: "800px", // Optimal width for reading
      padding: "40px",
      backgroundColor: "#fff",
      borderRadius: "12px", // Softer rounded corners
      boxShadow: "0 6px 12px rgba(0, 0, 0, 0.1)", // Subtle shadow for depth
      textAlign: "center",
      boxSizing: "border-box",
      marginBottom: "40px", // More space at the bottom
    },
    header: {
      marginBottom: "30px",
      fontSize: "2.5rem", // Larger font size for the header
      color: "#333", // Darker color for better contrast
      fontWeight: "600", // Semi-bold for emphasis
    },
    blogsList: {
      listStyle: "none",
      padding: "0",
      textAlign: "left",
      marginTop: "20px",
    },
    blogItem: {
      marginBottom: "20px", // More space between blog items
      padding: "15px", // Padding for each blog item
      backgroundColor: "#f8f9fa", // Light background for each item
      borderRadius: "8px", // Rounded corners for each item
      transition: "transform 0.2s, box-shadow 0.2s", // Smooth hover effect
    },
    blogLink: {
      textDecoration: "none",
      color: "#007bff",
      fontWeight: "500", // Medium weight for links
      fontSize: "1.1rem", // Slightly larger font size for links
      display: "block", // Make the entire area clickable
    },
    blogItemHover: {
      transform: "translateY(-5px)", // Lift effect on hover
      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)", // Shadow on hover
    },
    footer: {
      width: "100%",
      textAlign: "center",
      padding: "20px",
      backgroundColor: "#333", // Dark background for the footer
      color: "#fff", // White text for contrast
    },
  };

  return (
    <>
      <HomeHeader />
      <div style={styles.container}>
        <div style={styles.card}>
          <section>
            <h2 style={styles.header}>Latest Blogs</h2>
            <ul style={styles.blogsList}>
              {Object.keys(blogData).map((slug) => (
                <li
                  style={styles.blogItem}
                  key={slug}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.transform =
                      styles.blogItemHover.transform;
                    e.currentTarget.style.boxShadow =
                      styles.blogItemHover.boxShadow;
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.transform = "none";
                    e.currentTarget.style.boxShadow = "none";
                  }}
                >
                  <Link
                    to={`/blogs/${slug.toLowerCase().replace(/ /g, "-")}`}
                    style={styles.blogLink}
                  >
                    {blogData[slug].title}
                  </Link>
                </li>
              ))}
            </ul>
          </section>
        </div>
      </div>
      <BaseFoooter />
    </>
  );
};

export default BlogPageMain;
