import { useAppDataContext } from "hooks/useappdatacontext";
import stylesT2 from "../../pages/Custom/T4/styles.module.css";
import "./index.css";

const ScrollingMess = ({ edit, mess, templtae }) => {
  return (
    <div style={{ width: "100%" }} className={stylesT2.navbarForAd}>
      <div
        style={{
          width: edit ? (templtae === "t2" ? "100%" : "80%") : "100%",
          maxWidth: "800px",
        }}
        className={stylesT2.scrollingMessT4}
      >
        <div
          style={{ width: "100%", maxWidth: "800px" }}
          className={stylesT2.advertisementT4}
        >
          {mess}
        </div>
      </div>
    </div>
  );
};

export default ScrollingMess;
