import { useAppDataContext } from "hooks/useappdatacontext";
import styles from "../../pages/weddingTemplate/weddingTemplate.module.css";
import styles2 from "../Custom/T2/styles.module.css";
import FancyBoxGallery from "../../components/fancyBoxGallery/fancyBoxGallery";
import HLSPlayer from "../../components/hlsPlayer";
import { FaLocationDot } from "react-icons/fa6";
import VideoPlayerForEdit from "./VideoPlayerForEdit";
import DefaultGallery from "../../components/fancyBoxGallery/DefaultGallery";
import ScrollingMess from "./ScrollingMess";

const RenderDefaultTemplate = ({
  hlsLiveUrl,
  streamStatus,
  setStreamStatus,
  selectedOfflineVideo,
  optionData,
  videoSrc,
  selectedInvitationCard,
  selectedInvitationVideo,
  selectedBanner,
}) => {
  const { eventData, singleChannelData, singleEventData, hlsVideoUrl } =
    useAppDataContext();

  const extractYouTubeVideoId = (url) => {
    const regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url?.match(regExp);
    return match && match[7]?.length === 11 ? match[7] : null;
  };

  const extractYouTubeLiveId = (url) => {
    const regExp = /youtube\.com\/live\/([^?&]+)/;
    const match = url?.match(regExp);
    return match ? match[1] : null;
  };

  const previewUrl = (file) => URL.createObjectURL(file);

  const renderFooterSection = () => {
    const currentYear = new Date().getFullYear();
    return (
      <footer style={{ padding: "20px 0px", textAlign: "center" }}>
        <p>
          &copy; {currentYear}, Made with ❤️ by <strong>Infinitum Tech</strong>
        </p>
      </footer>
    );
  };

  const renderScrollingMess = (hider) => {
    return (
      <>
        {hider && eventData?.scrolling_mess && (
          <div style={{ width: "100%" }} className={styles.navbarForAd}>
            <div style={{ width: "100%" }} className="scrollingmess">
              <div style={{ width: "100%" }} className={styles.advertisement}>
                {eventData?.scrolling_mess}
              </div>
            </div>
          </div>
        )}
      </>
    );
  };
  const renderGallerySection = () => {
    return (
      <div id="gallery" className={styles.galleryBlock}>
        {eventData &&
          Array.isArray(eventData.images) &&
          eventData.images.length > 0 && (
            <>
              <h1 className={styles2.bannerHeadingT2}>Our Wedding Gallery</h1>
              <div style={{ width: "100%" }}>
                <DefaultGallery preview={true} />
              </div>
            </>
          )}
      </div>
    );
  };
  //component to render live video based on available link
  const renderLiveVideoComponent = (hider, preview) => {
    let videoOptions;
    if (hlsVideoUrl) {
      videoOptions = {
        autoplay: false,
        controls: true,
        muted: true,
        sources: [{ src: hlsVideoUrl, type: "application/x-mpegURL" }],
      };
    }

    // Extract YouTube video and live IDs
    const videoId = extractYouTubeVideoId(eventData?.live_vidlink);
    const liveId = extractYouTubeLiveId(eventData?.live_vidlink);

    // Determine the appropriate video URL based on the type
    const videoUrl = videoId
      ? `https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1`
      : "";
    const liveUrl = liveId
      ? `https://www.youtube.com/embed/${liveId}?autoplay=1&mute=1`
      : "";
    return (
      <>
        {optionData?.option === "youtubeChannelId" &&
          optionData?.youtubeLiveUrl && (
            <div style={{ width: "100%", margin: "20px 0px" }}>
              <h1 className={styles2.bannerHeadingT2}>Live Video</h1>
              {eventData?.scrolling_mess && (
                <ScrollingMess mess={eventData?.scrolling_mess} />
              )}
              <iframe
                frameBorder="0"
                style={{
                  width: "80%",
                  margin: "auto",
                  height: "250px",
                  border: "20px solid white",
                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                  borderRadius: "10px",
                }}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="YouTube Video"
                src={`https://www.youtube.com/embed/${extractYouTubeLiveId(
                  optionData?.youtubeLiveUrl
                )}?autoplay=1&mute=1`}
              ></iframe>
            </div>
          )}
        {optionData?.option === "youtubeStreamedUrl" &&
          optionData?.youtubeStreamedUrl && (
            <div style={{ width: "100%", margin: "20px 0px" }}>
              <h1 className={styles2.bannerHeadingT2}>Live Video</h1>
              {eventData?.scrolling_mess && (
                <ScrollingMess mess={eventData?.scrolling_mess} />
              )}
              <iframe
                frameBorder="0"
                style={{
                  width: "80%",
                  margin: "auto",
                  height: "250px",
                  border: "20px solid white",
                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                  borderRadius: "10px",
                }}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="YouTube Video"
                src={`https://www.youtube.com/embed/${extractYouTubeVideoId(
                  optionData?.youtubeStreamedUrl
                )}?autoplay=1&mute=1`}
              ></iframe>
            </div>
          )}
        {!optionData?.youtubeStreamedUrl &&
          !eventData?.live_vidlink &&
          hlsVideoUrl && (
            <div style={{ width: "100%", margin: "20px 0px" }}>
              <h1 className={styles2.bannerHeadingT2}>Live Video</h1>
              {eventData?.scrolling_mess && (
                <ScrollingMess mess={eventData?.scrolling_mess} />
              )}
              <div
                style={{
                  width: "80%",
                  margin: "auto",
                  border: "20px solid white",
                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                  borderRadius: "10px",
                }}
              >
                <VideoPlayerForEdit options={videoOptions} />
              </div>
            </div>
          )}
        <>
          {(videoId !== null || liveId !== null) && hlsVideoUrl === "" && (
            <div
              className={`${
                preview ? "box-border-styling-80" : "box-border-styling-100"
              }`}
              style={{ margin: "20px 0px" }}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
                className="video-container"
              >
                {videoUrl && !liveUrl && (
                  <div style={{ width: "100%", margin: "auto" }}>
                    <h1 className={styles2.bannerHeadingT2}>Live Video</h1>
                    {renderScrollingMess()}
                    <div className={styles2.frameContainer}>
                      <iframe
                        style={{
                          width: hider ? "80%" : "100%",
                          margin: "auto",
                          height: "250px",
                          borderRadius: "8px",
                        }}
                        src={videoUrl}
                        frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="YouTube Video"
                      ></iframe>
                    </div>
                  </div>
                )}

                {liveUrl && !videoUrl && (
                  <div style={{ width: "100%", margin: "auto" }}>
                    <h1 className={styles2.bannerHeadingT2}>Live Video</h1>
                    {eventData?.scrolling_mess && (
                      <ScrollingMess mess={eventData?.scrolling_mess} />
                    )}
                    <div className={styles2.frameContainer}>
                      <iframe
                        style={{
                          borderRadius: "8px",
                          width: hider ? "80%" : "100%",
                          margin: "auto",
                          height: "250px",
                        }}
                        src={liveUrl}
                        frameBorder="3"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="YouTube Live"
                      ></iframe>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
        </>
        {hlsLiveUrl && !videoUrl && !liveUrl && (
          <div
            className={`${
              preview ? "box-border-styling-80" : "box-border-styling-100"
            }`}
            style={{ margin: "20px 0px" }}
          >
            <div className="videoContainer">
              <h1 className={styles2.bannerHeadingT2}>Live Video</h1>
              {eventData?.scrolling_mess && (
                <ScrollingMess mess={eventData?.scrolling_mess} />
              )}

              <HLSPlayer
                singleChannelData={singleChannelData}
                editPage={true}
                preview={true}
                hider={true}
                streamStatus={streamStatus}
                setStreamStatus={setStreamStatus}
              />
            </div>
          </div>
        )}
      </>
    );
  };

  const renderOfflineVideoModificationAndViewingection = (hider) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        {hider && <h1 className={styles2.bannerHeadingT2}>Offline Video</h1>}

        {optionData?.option === "file" && (
          <div style={{ marginTop: "10px" }}>
            <h4 className={styles2.bannerHeadingT2}>Offline Video</h4>

            {eventData?.scrolling_mess && (
              <ScrollingMess mess={eventData?.scrolling_mess} />
            )}
            <div style={{ width: "100%" }}>
              <video
                style={{
                  width: "80%",
                  margin: "auto",
                  border: "20px solid white",
                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                  borderRadius: "10px",
                }}
                controls
                src={previewUrl(optionData?.file)}
              />
            </div>
          </div>
        )}

        {selectedOfflineVideo && selectedOfflineVideo !== null ? (
          <div style={{ marginTop: "10px" }} className="video-container">
            <h4 className={styles2.bannerHeadingT2}>Offline Video</h4>
            {eventData?.scrolling_mess && (
              <ScrollingMess mess={eventData?.scrolling_mess} />
            )}
            <video
              src={videoSrc}
              controls
              style={{
                width: "80%",
                margin: "auto",
                border: "20px solid white",
                boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                borderRadius: "10px",
              }}
            />
          </div>
        ) : (
          eventData?.offline_video &&
          eventData?.offline_video != null && (
            <div
              className="video-container"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                marginTop: "10px",
              }}
            >
              <h4 className={styles2.bannerHeadingT2}>Offline Video</h4>
              {eventData?.scrolling_mess && (
                <ScrollingMess mess={eventData?.scrolling_mess} />
              )}
              <video
                controls
                src={eventData?.offline_video}
                style={{
                  width: "80%",
                  margin: "auto",
                  border: "20px solid white",
                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                  borderRadius: "10px",
                }}
              />
            </div>
          )
        )}
      </div>
    );
  };

  const renderInvitationSection = () => {
    function formatTime(timeString) {
      if (!timeString) return "Invalid time"; // Handle undefined or empty strings

      const [hour, minute] = timeString.split(":");
      const hourInt = parseInt(hour, 10);
      const isPM = hourInt >= 12;

      const formattedHour = hourInt % 12 === 0 ? 12 : hourInt % 12; // Handle 12-hour clock
      const period = isPM ? "PM" : "AM";

      return `${formattedHour.toString().padStart(2, "0")}:${minute} ${period}`;
    }

    // const getTitle = (title) => {
    //   if (title.toLowerCase().includes("weds")) {
    //     return title
    //       .split(" ")
    //       .map((word, index) => <div key={index}>{word}</div>);
    //   } else {
    //     return title;
    //   }
    // };
    return (
      <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        {selectedInvitationCard ? (
          <div id="invitation">
            <h1 className={styles2.bannerHeadingT2}>Invitation Card</h1>
            {selectedInvitationCard && (
              <img
                src={previewUrl(selectedInvitationCard)}
                style={{
                  width: "80%",
                  maxHeight: "500px",
                  border: "20px white solid",
                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                  borderRadius: "10px",
                }}
                alt="Invitation card"
              />
            )}
          </div>
        ) : (
          <>
            {
              eventData && eventData.invitation_card ? (
                <div id="invitation">
                  <h1
                    // style={{
                    //   color: content?.template_css?.textColor
                    //     ? content?.template_css?.textColor
                    //     : "black",
                    //   fontFamily: content?.template_css?.textFontFamily
                    //     ? content?.template_css?.textFontFamily
                    //     : "Courier New",
                    //   fontSize: content?.template_css?.headingFontSize
                    //     ? content?.template_css?.headingFontSize
                    //     : "30px",
                    // }}
                    // className="editingInvitationHeading"
                    className={styles2.bannerHeadingT2}
                  >
                    Invitation Card
                  </h1>
                  <img
                    src={eventData.invitation_card}
                    style={{
                      width: "80%",
                      maxHeight: "500px",
                      border: "20px white solid",
                      boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                      borderRadius: "10px",
                    }}
                    alt="Invitation card"
                  />
                </div>
              ) : (
                <div
                  className={`${styles2.staticHeroS2T2} ${styles2.eachSectionT2}`}
                  style={{ border: "none" }}
                >
                  <h1 className={styles2.bannerHeadingT2}>Invitation Card</h1>
                  <div className={styles2.staticMainBoxT2}>
                    <div className={styles2.containerFluidT2}>
                      <div className={styles2.staticInnerBoxT2}>
                        <div className={styles2.rowT2}>
                          <div className={styles2.invitationCardT2}>
                            <div className={styles2.wpoStaticHeroTextWrapT2}>
                              <div className={styles2.wpoStaticHeroTextBoxT2}>
                                <div className={styles2.slideTitleT2}>
                                  <div
                                    className={`${styles2.poortTextT2} ${styles2.poortInUpT2}`}
                                    style={{ perspective: "600px" }}
                                  >
                                    <h2
                                      className={styles2.poortLineT2}
                                      style={{
                                        display: "block",
                                        textAlign: "center",
                                        position: "relative",
                                        fontFamily: "'Dancing Script', cursive",
                                      }}
                                    >
                                      {eventData?.title}
                                    </h2>
                                  </div>
                                </div>

                                <div
                                  className={styles2.slideTextT2}
                                  data-wow-duration="1600ms"
                                  style={{
                                    visibility: "visible",
                                    animationDuration: "1600ms",
                                    animationName: "fadeInUp",
                                  }}
                                >
                                  <p>We Are Getting Married In</p>
                                </div>

                                <p>On</p>

                                <div
                                  className={styles2.slideDateT2}
                                  data-wow-duration="1700ms"
                                  style={{
                                    visibility: "visible",
                                    animationDuration: "1700ms",
                                    animationName: "fadeInUp",
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "14px",
                                  }}
                                >
                                  <span
                                    style={{
                                      color: "rgb(113 108 83)",
                                      fontWeight: "700",
                                      fontSize: "1rem",
                                    }}
                                  >
                                    Date:
                                  </span>
                                  {/* <p>04 . 03 . 2024</p> */}
                                  <p>
                                    {eventData?.venue_date
                                      ? new Date(
                                          eventData?.venue_date
                                        ).toLocaleDateString("en-GB", {
                                          day: "2-digit",
                                          month: "short",
                                          year: "numeric",
                                        })
                                      : "Please Select Date"}
                                  </p>
                                </div>

                                <div
                                  className={styles2.slideTimeT2}
                                  data-wow-duration="1700ms"
                                  style={{
                                    visibility: "visible",
                                    animationDuration: "1700ms",
                                    animationName: "fadeInUp",
                                    display: "flex",
                                    gap: "14px",
                                    alignItems: "center",
                                  }}
                                >
                                  <span
                                    style={{
                                      color: "rgb(113 108 83)",
                                      fontWeight: "700",
                                      fontSize: "1rem",
                                    }}
                                  >
                                    Time:
                                  </span>
                                  {/* <p>04 : 00 : 00 PM</p> */}
                                  <p>
                                    {eventData?.venue_time
                                      ? formatTime(eventData?.venue_time)
                                      : "Please Select Time"}
                                  </p>
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    alignItems: "center",
                                    gap: "4px",
                                  }}
                                >
                                  <p
                                    style={{
                                      maxWidth: "250px",
                                      overflowX: "auto",
                                    }}
                                    className={
                                      styles2.cedarvilleCursiveRegularT2
                                    }
                                  >
                                    <FaLocationDot
                                      style={{ marginTop: "2px" }}
                                    />
                                    {eventData?.venue
                                      ? eventData?.venue
                                      : "N/A"}
                                  </p>
                                </div>

                                <div className={styles2.clearFixT2}></div>
                              </div>

                              {/* <div
                      className="shape wow fadeInLeftSlow"
                      data-wow-duration="2500ms"
                      style={{
                        visibility: "visible",
                        animationDuration: "2500ms",
                        animationName: "fadeInLeftSlow",
                      }}
                    >
                      <img
                        src="https://wpocean.com/html/tf/sukun/assets/images/slider/left-shape-1.png"
                        alt=""
                      />
                    </div>

                    <div
                      className="shape2 wow fadeInRightSlow"
                      data-wow-duration="2500ms"
                      style={{
                        visibility: "visible",
                        animationDuration: "2500ms",
                        animationName: "fadeInRightSlow",
                      }}
                    >
                      <img
                        src="https://wpocean.com/html/tf/sukun/assets/images/slider/left-shape-2.png"
                        alt=""
                      />
                    </div> */}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )
              // (
              //   <div className="invitation-frame">
              //     <div>
              //       <div
              //         className={styles2.marginBottom}
              //         style={{
              //           display: "flex",
              //           alignItems: "center",
              //           justifyContent: "center",
              //         }}
              //       >
              //         <FaLocationDot className="location-icon" />

              //         {(eventData?.venue)}
              //       </div>

              //       <div
              //         className="time-date"
              //         style={{
              //           display: "flex",
              //           justifyContent: "space-between",
              //           alignItems: "center",
              //           gap: "4px",
              //         }}
              //       >
              //         <div style={{ display: "flex", alignItems: "center" }}>
              //           <BsCalendar2DateFill className="location-icon" />
              //           <div style={{ fontSize: "12px" }}>
              //             {eventData?.venue_date}
              //           </div>
              //         </div>

              //         <p style={{ display: "flex", alignItems: "center" }}>
              //           <MdAccessTimeFilled className="location-icon" />{" "}
              //           <span style={{ fontSize: "12px" }}>
              //             {new Date(
              //               `1970-01-01T${eventData?.venue_time}`
              //             ).toLocaleTimeString([], {
              //               hour: "numeric",
              //               minute: "2-digit",
              //               hour12: true,
              //             })}
              //           </span>
              //         </p>
              //       </div>
              //       {/* <p
              //         style={{
              //           display: "flex",
              //           justifyContent: "center",
              //           alignItems: "center",
              //           margin: "8px 0px 0px 0px",
              //         }}
              //       >
              //         <MdPhoneCallback className="location-icon" />
              //         {eventData?.contact}
              //       </p> */}
              //       <div style={{ margin: "8px 0px 0px 0px" }}>
              //         {(eventData?.template_desc)}
              //       </div>
              //     </div>
              //   </div>
              // )
            }
          </>
        )}
        {selectedInvitationVideo && (
          <>
            {/* {isPortrait ? ( */}
            <div style={{ width: "100%" }} className="video-container2">
              <h1
                // style={{
                //   color: content?.template_css?.textColor
                //     ? content?.template_css?.textColor
                //     : "black",
                //   fontFamily: content?.template_css?.textFontFamily
                //     ? content?.template_css?.textFontFamily
                //     : "Courier New",
                //   fontSize: content?.template_css?.headingFontSize
                //     ? content?.template_css?.headingFontSize
                //     : "20px",
                // }}
                // className="editingInvitationHeading"
                className={styles2.bannerHeadingT2}
              >
                Invitaion Video
              </h1>
              <video
                style={{
                  width: "80%",
                  border: "20px white solid",
                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                  borderRadius: "10px",
                }}
                controls
                src={previewUrl(selectedInvitationVideo)}
                type="video/mp4"
              />
            </div>
          </>
        )}

        {eventData?.invitation_video && selectedInvitationVideo === null && (
          <>
            <div style={{ width: "100%" }} className="video-container2">
              <h1
                // style={{
                //   color: content?.template_css?.textColor
                //     ? content?.template_css?.textColor
                //     : "black",
                //   fontFamily: content?.template_css?.textFontFamily
                //     ? content?.template_css?.textFontFamily
                //     : "Courier New",
                //   fontSize: content?.template_css?.headingFontSize
                //     ? content?.template_css?.headingFontSize
                //     : "20px",
                // }}
                // className="editingInvitationHeading"
                className={styles2.bannerHeadingT2}
              >
                Invitaion Video
              </h1>

              <video
                style={{
                  width: "80%",
                  border: "20px white solid",
                  boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                  borderRadius: "10px",
                }}
                controls
                src={eventData?.invitation_video}
                type="video/mp4"
              />
            </div>
          </>
        )}
      </div>
    );
  };

  const renderBannerImage = (preview) => {
    return (
      <>
        {selectedBanner ? (
          <div>
            <h1 className={styles2.bannerHeadingT2}>Banner</h1>
            <div
              id="home"
              style={{
                width: "100%",
                margin: "auto",
                backgroundSize: "cover",
              }}
              className={styles2.bannerSectionT2}
            >
              <img
                src={previewUrl(selectedBanner)}
                alt="Banner Preview"
                style={{
                  borderRadius: "8px",
                  width: "80%",
                  margin: "auto",
                  height: "inherit",
                  border: "20px white solid",
                }}
              />
            </div>
          </div>
        ) : (
          eventData?.banner !== null && (
            <div>
              <h1 className={styles2.bannerHeadingT2}>Banner</h1>
              <div
                style={{
                  width: "100%",
                  margin: "auto",
                  backgroundSize: "cover",
                }}
                className={styles2.bannerSectionT2}
              >
                <img
                  style={{
                    borderRadius: "8px",
                    width: "80%",
                    height: "inherit",
                    border: "20px white solid",
                  }}
                  src={eventData?.banner}
                  alt="Frame"
                />
              </div>
            </div>
          )
        )}
      </>
    );
  };

  const renderNavigation = () => {
    function capitalizeFirstLetter(str) {
      return str
        ?.split(" ") // Split the string into words by space
        ?.map(
          (word) =>
            word?.charAt(0)?.toUpperCase() + word?.slice(1).toLowerCase()
        ) // Capitalize first letter of each word
        ?.join(" "); // Join the words back together with space
    }

    return (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#333",
          }}
        >
          <h1
            style={{
              background:
                "linear-gradient(45deg, rgb(217, 164, 8), rgb(255, 173, 0)) text",
              color: "transparent",
              fontWeight: "bold",
              textAlign: "center",
              fontFamily: "cursive",
              padding: "30px 0px",
            }}
            className={styles2.navHeading}
          >
            {capitalizeFirstLetter(eventData?.title)}
          </h1>
          {eventData?.scrolling_mess &&
            !optionData?.option &&
            !hlsVideoUrl &&
            eventData?.live_vidlink === "" &&
            eventData?.offline_video === "" && (
              <div style={{ width: "100%" }}>
                <div style={{ width: "100%" }} className="scrollingmess">
                  <div
                    style={{ width: "100%" }}
                    className={styles.advertisement}
                  >
                    {eventData?.scrolling_mess}
                  </div>
                </div>
              </div>
            )}
        </div>
      </>
    );
  };
  return (
    <div style={{ width: "100%" }}>
      {eventData?.title && renderNavigation()}
      {renderBannerImage()}
      {renderInvitationSection()}
      {(singleEventData?.offline_video && eventData?.offline_video) ||
      selectedOfflineVideo ||
      optionData?.option === "file"
        ? renderOfflineVideoModificationAndViewingection()
        : renderLiveVideoComponent()}
      {renderGallerySection()}
      {renderFooterSection()}
    </div>
  );
};

export default RenderDefaultTemplate;
