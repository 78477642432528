
export const blogData = {
  "detailed-overview-of-events": {
    title: "1A. Detailed Overview of Events on Life Website",
    content: ` <div style="padding: 20px; max-width: 1200px; margin: 0 auto;">
  <h1 style="font-size: 36px; font-weight: bold; margin-bottom: 20px;">Detailed Overview of Events on Life Website</h1>
  <p style="font-size: 18px; line-height: 1.6; margin-bottom: 30px;">
    Events on Life is an all-in-one platform offering exceptional solutions for event and wedding live streaming. With customizable options and high-quality technology, it provides the perfect opportunity to create, manage, and share memorable events seamlessly.
  </p>

  <div style="margin-bottom: 40px;">
    <h2 style="font-size: 24px; font-weight: bold; margin-bottom: 20px;">Our Services</h2>
    <ul style="list-style-type: none; padding-left: 0;">
      <li style="margin-bottom: 20px; margin-left:20px;">
        <h3 style="font-size: 18px; font-weight: bold;">Birthday Parties</h3>
        <ul style="padding-left: 20px;">
          <li>Unforgettable celebrations with live streaming and photography.</li>
          <li>Capture and preserve memories that last a lifetime.</li>
        </ul>
      </li>
     <li style="margin-bottom: 20px; margin-left:20px;">
        <h3 style="font-size: 18px; font-weight: bold;">Weddings Coverage</h3>
        <ul style="padding-left: 20px;">
          <li>Comprehensive wedding solutions, including decor, live streaming, and photography.</li>
          <li>Cherish every magical moment with precision and elegance.</li>
        </ul>
      </li>
     <li style="margin-bottom: 20px; margin-left:20px;">
        <h3 style="font-size: 18px; font-weight: bold;">Cultural Events</h3>
        <ul style="padding-left: 20px;">
          <li>Celebrate culture with traditional setups, vibrant event photography, and live streaming.</li>
          <li>Honor cultural heritage while sharing the experience with a broader audience.</li>
        </ul>
      </li>
     <li style="margin-bottom: 20px; margin-left:20px;">
        <h3 style="font-size: 18px; font-weight: bold;">Holiday Parties</h3>
        <ul style="padding-left: 20px;">
          <li>Festive gatherings enhanced with themed decor, entertainment, and memorable experiences.</li>
          <li>Make every holiday celebration unique and engaging.</li>
        </ul>
      </li>
     <li style="margin-bottom: 20px; margin-left:20px;">
        <h3 style="font-size: 18px; font-weight: bold;">Family Reunions</h3>
        <ul style="padding-left: 20px;">
          <li>Bring families closer with live streaming, photography, and personalized experiences.</li>
          <li>Preserve the joy and connection of family reunions.</li>
        </ul>
      </li>
     <li style="margin-bottom: 20px; margin-left:20px;">
        <h3 style="font-size: 18px; font-weight: bold;">Concerts and Music Festivals</h3>
        <ul style="padding-left: 20px;">
          <li>Dynamic festivals brought to life with advanced streaming and photography.</li>
          <li>Capture every exciting moment and share it with the world.</li>
        </ul>
      </li>
    </ul>
  </div>

  <div style="margin-bottom: 40px;">
    <h2 style="font-size: 24px; font-weight: bold; margin-bottom: 20px;">Powerful Features</h2>
    <p style="font-size: 18px; line-height: 1.6; margin-bottom: 20px;">Everything You Need to Create, Manage, and Share Memorable Events</p>
    <ul style="list-style-type: none; padding-left: 0;">
     <li style="margin-bottom: 20px; margin-left:20px;">
        <h3 style="font-size: 18px; font-weight: bold;">Live Streaming</h3>
        <ul style="padding-left: 20px;">
          <li>Broadcast your events in crystal-clear quality with advanced streaming technology.</li>
          <li>Deliver a seamless viewing experience to remote attendees.</li>
        </ul>
      </li>
     <li style="margin-bottom: 20px; margin-left:20px;">
        <h3 style="font-size: 18px; font-weight: bold;">Wedding Gallery</h3>
        <ul style="padding-left: 20px;">
          <li>Create stunning galleries to showcase your event memories.</li>
          <li>Organize and present photos and videos elegantly.</li>
        </ul>
      </li>
     <li style="margin-bottom: 20px; margin-left:20px;">
        <h3 style="font-size: 18px; font-weight: bold;">Event Sharing</h3>
        <ul style="padding-left: 20px;">
          <li>Share moments instantly with attendees and social media platforms.</li>
          <li>Amplify your event’s reach and engagement.</li>
        </ul>
      </li>
     <li style="margin-bottom: 20px; margin-left:20px;">
        <h3 style="font-size: 18px; font-weight: bold;">Secure Access</h3>
        <ul style="padding-left: 20px;">
          <li>Control who can view and interact with your content.</li>
          <li>Ensure privacy and security for all your event materials.</li>
        </ul>
      </li>
    </ul>
  </div>

  <div style="margin-bottom: 40px;">
    <h2 style="font-size: 24px; font-weight: bold; margin-bottom: 20px;">White Label Solution</h2>
    <p style="font-size: 18px; line-height: 1.6; margin-bottom: 20px;">Experience the Best Cloud-Based Streaming Solution for Your Events and Weddings</p>
    <ul style="list-style-type: none; padding-left: 0;">
    <li style="margin-bottom: 20px; margin-left:20px;">
    <h3 style="font-size: 24px; font-weight: bold; margin-bottom: 10px;">What Do We Propose?</h3>
    <ul style="padding-left: 20px;">
      <li>Go Live Instantly: Start streaming your events immediately with ease.</li>
      <li>No Hidden Costs: Enjoy transparent pricing without surprises.</li>
      <li>All-Inclusive Platform: Access all tools and features in one place.</li>
      </ul>
      </li>
      
      <li style="margin-bottom: 20px; margin-left:20px;">
    <h3 style="font-size: 24px; font-weight: bold; margin-bottom: 10px;">Why Choose Events on Life?</h3>
    <ul style="padding-left: 20px;">
      <li>Customizable: Tailor the platform to reflect your brand identity.</li>
      <li>Cost-Effective: A perfect balance of quality and affordability.</li>
      <li>Experienced Leadership: Backed by a team with extensive expertise in event streaming solutions.</li>
      </ul>
      </li>
      </ul>
  </div>

  <p style="font-size: 18px; line-height: 1.6;">
    With its wide range of services, powerful features, and customizable options, Events on Life empowers individuals and organizations to create extraordinary events while maintaining complete control over their content. Whether it’s a birthday party, wedding, or concert, this platform ensures a memorable and engaging experience for everyone involved.
  </p>
</div>

    `,
  },
  "guide-to-creating-an-event": {
    title: "2A. Guide to Creating an Event",
    content: ` <div style="padding: 20px; max-width: 1200px; margin: 0 auto;">
    <h1 style="font-size: 36px; font-weight: bold; margin-bottom: 20px;">Guide to Creating an Event</h1>
  <p style="font-size: 18px; line-height: 1.6; margin-bottom: 30px;">
    The Create Event process allows you to set up a fully customized and professional event page. Follow the steps below to create your event and understand the details required for each field.
    </p>
    
    <img style="width:90%;" src="https://r2.streamteches.com/Screenshot%202025-01-28%20103134.png" alt="Create event"/>
  <h2 style="font-size: 24px; font-weight: bold; margin-bottom: 20px;">1. User Information</h2>
  <ul style="list-style-type: none; padding-left: 0;">
    <li style="margin-bottom: 20px; margin-left: 20px;">
      <strong style="font-size: 18px;">User Name:</strong> Enter your name to identify yourself as the event organizer.
    </li>
    <li style="margin-bottom: 20px; margin-left: 20px;">
      <strong style="font-size: 18px;">Contact Number:</strong> Provide your phone number, including the country code (e.g., +91 for India).
    </li>
  </ul>

  <h2 style="font-size: 24px; font-weight: bold; margin-bottom: 20px;">2. Event Details</h2>
  <ul style="list-style-type: none; padding-left: 0;">
    <li style="margin-bottom: 20px; margin-left: 20px;">
      <strong style="font-size: 18px;">Event Title:</strong> Provide the title of your event. This is the main name that will represent your event.
    </li>
    <li style="margin-bottom: 20px; margin-left: 20px;">
      <strong style="font-size: 18px;">Event Type:</strong> Select the type of event from the available options (e.g., wedding, party, conference).
    </li>
    <li style="margin-bottom: 20px; margin-left: 20px;">
      <strong style="font-size: 18px;">Custom URL:</strong> Create a unique URL for your event page (e.g., "ram123"). This URL will be used for sharing the event online.
    </li>
  </ul>

  <h2 style="font-size: 24px; font-weight: bold; margin-bottom: 20px;">3. Venue Details</h2>
  <ul style="list-style-type: none; padding-left: 0;">
    <li style="margin-bottom: 20px; margin-left: 20px;">
      <strong style="font-size: 18px;">Venue:</strong> Enter the location of your event. This could be an address or venue name.
    </li>
    <li style="margin-bottom: 20px; margin-left: 20px;">
      <strong style="font-size: 18px;">Event Date:</strong> Specify the date of the event in the format MM/DD/YYYY (e.g., 01/30/2025).
    </li>
    <li style="margin-bottom: 20px; margin-left: 20px;">
      <strong style="font-size: 18px;">Venue Timing:</strong> Add the start time of the event in the format hh:mm AM/PM (e.g., 10:00 AM).
    </li>
    <li style="margin-bottom: 20px; margin-left: 20px;">
      <strong style="font-size: 18px;">Live Timing:</strong> Specify the time when live streaming of the event will begin (e.g., 12:30 PM).
    </li>
    <li style="margin-bottom: 20px; margin-left: 20px;">
      <strong style="font-size: 18px;">Scrolling Message:</strong> Add a custom message to be displayed on the event page, such as "Welcome to our special day!" or "Live now!"
    </li>
  </ul>
  <img style="width:100%;" src="https://r2.streamteches.com/Screenshot%202025-01-28%20103151.png" alt="Create event"/>

  <h2 style="font-size: 24px; font-weight: bold; margin-bottom: 20px;">4. File Uploads</h2>
  <ul style="list-style-type: none; padding-left: 0;">
    <li style="margin-bottom: 20px; margin-left: 20px;">
      <strong style="font-size: 18px;">Banner Image:</strong> Upload a banner image that will visually represent your event. Supported formats: .jpg, .jpeg, .png, .tiff.
    </li>
    <li style="margin-bottom: 20px; margin-left: 20px;">
      <strong style="font-size: 18px;">Invitation Card:</strong> Upload a digital invitation card for your event. Supported formats: .jpg, .jpeg, .png, .tiff.
    </li>
    <li style="margin-bottom: 20px; margin-left: 20px;">
      <strong style="font-size: 18px;">Invitation Video:</strong> Upload a video invitation to make your event announcement more engaging. Supported formats: .mp4, .mov, .avi.
    </li>
    <li style="margin-bottom: 20px; margin-left: 20px;">
      <strong style="font-size: 18px;">Additional Images:</strong> Upload multiple images related to your event, such as highlights, decor, or previews. Supported formats: .jpg, .jpeg, .png, .tiff.
    </li>
  </ul>

  <h2 style="font-size: 24px; font-weight: bold; margin-bottom: 20px;">5. Live Video Streaming Settings</h2>
  <ul style="list-style-type: none; padding-left: 0;">
    <li style="margin-bottom: 20px; margin-left: 20px;">
      <strong style="font-size: 18px;">Streaming Options:</strong>
      <ul style="padding-left: 20px;">
        <li><strong style="font-size: 18px;">Cloud Server:</strong> Use a secure, cloud-based streaming solution for professional-quality live streaming.</li>
        <li><strong style="font-size: 18px;">YouTube:</strong> Stream your event live on YouTube. <em style="font-size: 18px;">Note:</em> If you choose this option, Google Ads will not be displayed on the event page.</li>
        <li><strong style="font-size: 18px;">YouTube with Ads:</strong> Select this option to stream on YouTube with advertisements enabled.</li>
      </ul>
    </li>
    <li style="margin-bottom: 20px; margin-left: 20px;">
      <strong style="font-size: 18px;">Password Protection:</strong> Secure your event page by setting a password. Only guests with the password will be able to view the event.
    </li>
    <li style="margin-bottom: 20px; margin-left: 20px;">
      <strong style="font-size: 18px;">Terms and Conditions:</strong> Accept the terms and conditions for creating and managing your event. A link to view the terms is provided.
    </li>
  </ul>

  <h2 style="font-size: 24px; font-weight: bold; margin-bottom: 20px;">6. Submit Your Event</h2>
  <ol style="font-size: 18px; line-height: 1.6;">
    <li>Review the information you’ve entered.</li>
    <li>Confirm all the details are accurate.</li>
    <li>Submit your event for creation.</li>
  </ol>

  <p style="font-size: 18px; line-height: 1.6;">
    Upon successful creation, you will receive a confirmation message with the event details and a link to your event page.
  </p>
  <p style="font-size: 18px; line-height: 1.6;">
    Enjoy hosting your event! 🎉
  </p>
</div>
`,
  },
  "credit-transfer-functionality": {
    title: "3A Credit Transfer Functionality",
    content: ` <div style="padding: 20px; max-width: 1200px; margin: 0 auto;">
  <h1 style="font-size: 36px; font-weight: bold; margin-bottom: 20px;">Credit Transfer Functionality</h1>
  <p style="font-size: 18px; line-height: 1.6; margin-bottom: 30px;">
    This feature allows you to transfer credits to another user via their email. Here’s how the process works from a user's perspective:
  </p>
  <img style="width:90%;" src="https://r2.streamteches.com/Screenshot%202025-01-28%20103221.png" alt="edit color"/>
  <img style="width:90%;" src="https://r2.streamteches.com/Screenshot%202025-01-28%20103247.png" alt="edit color"/>
  <h2 style="font-size: 24px; font-weight: bold; margin-bottom: 20px;">1. Things You’ll Need:</h2>
  <ul style="list-style-type: none; padding-left: 0;">
  <li style="margin-bottom: 20px; margin-left: 20px;">
  <strong style="font-size: 18px;">Recipient’s Email:</strong> The email address of the user you want to transfer credits to.
    </li>
    <li style="margin-bottom: 20px; margin-left: 20px;">
    <strong style="font-size: 18px;">Amount of Credits:</strong> Specify the amount of credits you want to transfer. You can transfer:
    <ul style="list-style-type: none; padding-left: 20px;">
        <li style="margin-bottom: 20px; margin-left: 20px;">
          <strong style="font-size: 18px;">Regular Credits:</strong> These are the primary credits you can transfer.
        </li>
        <li style="margin-bottom: 20px; margin-left: 20px;">
        <strong style="font-size: 18px;">YouTube Credits:</strong> If you have special credits for YouTube services, you can transfer them as well.
        </li>
        </ul>
        </li>
  </ul>
        
  <img style="width:90%;" src="https://r2.streamteches.com/Screenshot%202025-01-28%20103403.png" alt="edit color"/>
  <h2 style="font-size: 24px; font-weight: bold; margin-bottom: 20px;">2. How It Works:</h2>
  <ul style="list-style-type: none; padding-left: 0;">
    <li style="margin-bottom: 20px; margin-left: 20px;">
      <strong style="font-size: 18px;">Initiate the Transfer:</strong> You will need to provide the recipient’s email address and the amount of credits (regular or YouTube credits) you wish to send.
    </li>
    <li style="margin-bottom: 20px; margin-left: 20px;">
      <strong style="font-size: 18px;">Validation:</strong> The system checks that you have sufficient credits to make the transfer. If you don’t have enough credits, the system will notify you and prevent the transfer. You cannot transfer credits to your own account. If you try to do that, the system will notify you of the error.
    </li>
    <li style="margin-bottom: 20px; margin-left: 20px;">
      <strong style="font-size: 18px;">Credits Deduction:</strong> Once the transfer is initiated, the specified amount of credits will be deducted from your account (as long as the validation is successful).
    </li>
    <li style="margin-bottom: 20px; margin-left: 20px;">
      <strong style="font-size: 18px;">Credits Addition to the Receiver:</strong> The recipient’s account will be credited with the amount of regular and/or YouTube credits you are transferring.
    </li>
  </ul>

  <h2 style="font-size: 24px; font-weight: bold; margin-bottom: 20px;">3. Notification:</h2>
  <p style="font-size: 18px; line-height: 1.6; margin-bottom: 30px;">
    Once the transfer is successful, both you and the recipient will receive an email notification:
  </p>
  <ul style="list-style-type: none; padding-left: 0;">
    <li style="margin-bottom: 20px; margin-left: 20px;">
      <strong style="font-size: 18px;">Sender’s Notification:</strong> You will get a confirmation email detailing the transfer, including the amount of credits sent, the recipient's name, and other relevant details.
    </li>
    <li style="margin-bottom: 20px; margin-left: 20px;">
      <strong style="font-size: 18px;">Receiver’s Notification:</strong> The recipient will also be notified via email, informing them of the credits received, along with similar details. These emails will serve as a record of the transaction.
    </li>
  </ul>

  <h2 style="font-size: 24px; font-weight: bold; margin-bottom: 20px;">4. Possible Errors:</h2>
  <p style="font-size: 18px; line-height: 1.6; margin-bottom: 30px;">
    If there are any issues during the process (such as insufficient credits or incorrect email), the system will provide an error message to help guide you on what went wrong.
  </p>

  <h2 style="font-size: 24px; font-weight: bold; margin-bottom: 20px;">5. Security Considerations:</h2>
  <ul style="list-style-type: none; padding-left: 0;">
    <li style="margin-bottom: 20px; margin-left: 20px;">
      <strong style="font-size: 18px;">Only Transfer to Valid Email Addresses:</strong> Ensure that the email you are entering is correct. If the recipient does not have an account associated with that email, the transfer will fail.
    </li>
    <li style="margin-bottom: 20px; margin-left: 20px;">
      <strong style="font-size: 18px;">No Transfers to Yourself:</strong> You cannot send credits to your own account.
    </li>
    <li style="margin-bottom: 20px; margin-left: 20px;">
      <strong style="font-size: 18px;">Sufficient Credits:</strong> You must have enough credits in your account to complete the transfer. If you do not have enough, the system will prevent the transfer.
    </li>
  </ul>

  <h2 style="font-size: 24px; font-weight: bold; margin-bottom: 20px;">Summary of Steps for the User:</h2>
  <ul style="list-style-type: none; padding-left: 0;">
    <li style="margin-bottom: 20px; margin-left: 20px;">Enter the email address of the person you want to send credits to.</li>
    <li style="margin-bottom: 20px; margin-left: 20px;">Specify the amount of credits you want to transfer (either regular or YouTube credits).</li>
    <li style="margin-bottom: 20px; margin-left: 20px;">Confirm that you have sufficient credits.</li>
    <li style="margin-bottom: 20px; margin-left: 20px;">Submit the transfer request.</li>
    <li style="margin-bottom: 20px; margin-left: 20px;">Both you and the recipient will receive email notifications confirming the successful transfer.</li>
  </ul>
</div>

`,
  },
  "live-stream-via-obs": {
    title:
      "4A. How to Use RTMP IDs and Keys from Eventsonlife.com and Stream Videos Using OBS",
    content: ` <div style="padding: 20px; max-width: 1200px; margin: 0 auto;">
  <h1 style="font-size: 36px; font-weight: bold; margin-bottom: 20px;">How to Use RTMP IDs and Keys from Eventsonlife.com and Stream Videos Using OBS</h1>
  <p style="font-size: 18px; line-height: 1.6; margin-bottom: 30px;">
    If you're looking to stream videos on Eventsonlife.com using OBS Studio, you can easily connect to the platform using the RTMP IDs and Keys provided by the website. Here's a step-by-step guide to help you set up everything for streaming.
  </p>

  <h2 style="font-size: 24px; font-weight: bold; margin-bottom: 20px;">1. Retrieve RTMP ID and Key from Eventsonlife.com</h2>
  <p style="font-size: 18px; line-height: 1.6; margin-bottom: 30px;">
    Before you start streaming, you’ll need the RTMP Stream Key and Stream URL (RTMP ID) from the Eventsonlife.com website. Here’s how you can retrieve it:
  </p>
  <ul style="list-style-type: none; padding-left: 0;">
    <li style="margin-bottom: 20px; margin-left: 20px;">
      <strong style="font-size: 18px;">Log into Eventsonlife.com:</strong> Visit Eventsonlife.com and log into your account.
    </li>
    <li style="margin-bottom: 20px; margin-left: 20px;">
      <strong style="font-size: 18px;">Go to the Event Details Page:</strong> Navigate to the specific event page where you want to stream.
    </li>
    <li style="margin-bottom: 20px; margin-left: 20px;">
      <strong style="font-size: 18px;">Find RTMP ID and Key:</strong> In the event settings, you should see the option to get your RTMP Stream Key and Stream URL (RTMP ID). Copy them.
    </li>
  </ul>

  <h2 style="font-size: 24px; font-weight: bold; margin-bottom: 20px;">2. Install OBS Studio</h2>
  <p style="font-size: 18px; line-height: 1.6; margin-bottom: 30px;">
    If you don’t have OBS Studio installed on your computer yet, you can download it from the official website:
  </p>
  <ul style="list-style-type: none; padding-left: 0;">
    <li style="margin-bottom: 20px; margin-left: 20px;">
      <strong style="font-size: 18px;">OBS Studio Download:</strong> <a href="https://obsproject.com/" target="_blank" rel="noopener noreferrer">https://obsproject.com/</a>
    </li>
  </ul>
  <p style="font-size: 18px; line-height: 1.6; margin-bottom: 30px;">
    Follow the instructions to install OBS Studio on your computer.
  </p>
    <img style="width:90%;" src="https://r2.streamteches.com/Screenshot%202025-01-28%20103556.png" alt="edit color"/>
    <img style="width:90%;" src="https://r2.streamteches.com/Screenshot%202025-01-28%20103615.png" alt="edit color"/>
    <img style="width:90%;" src="https://r2.streamteches.com/Screenshot%202025-01-28%20103650.png" alt="edit color"/>
    <img style="width:90%;" src="https://r2.streamteches.com/Screenshot%202025-01-28%20103834.png" alt="edit color"/>

  <h2 style="font-size: 24px; font-weight: bold; margin-bottom: 20px;">3. Configure OBS for Eventsonlife.com Streaming</h2>
  <p style="font-size: 18px; line-height: 1.6; margin-bottom: 30px;">
    Once OBS Studio is installed, follow these steps to configure it:
  </p>
  <ul style="list-style-type: none; padding-left: 0;">
    <li style="margin-bottom: 20px; margin-left: 20px;">Open OBS Studio.</li>
    <li style="margin-bottom: 20px; margin-left: 20px;">Go to <strong>Settings</strong> > <strong>Stream</strong>.</li>
    <li style="margin-bottom: 20px; margin-left: 20px;">Set <strong>Stream Type</strong> to “Custom”.</li>
    <li style="margin-bottom: 20px; margin-left: 20px;">In the <strong>Server</strong> field, paste the RTMP Stream URL you got from Eventsonlife.com.</li>
    <li style="margin-bottom: 20px; margin-left: 20px;">In the <strong>Stream Key</strong> field, paste the Stream Key from Eventsonlife.com.</li>
  </ul>

  <h2 style="font-size: 24px; font-weight: bold; margin-bottom: 20px;">4. Start Streaming from OBS</h2>
  <p style="font-size: 18px; line-height: 1.6; margin-bottom: 30px;">
    After you’ve configured OBS with the Stream Key and RTMP URL, you can start streaming:
  </p>
  <ul style="list-style-type: none; padding-left: 0;">
    <li style="margin-bottom: 20px; margin-left: 20px;">Click the <strong>Start Streaming</strong> button in OBS.</li>
    <li style="margin-bottom: 20px; margin-left: 20px;">Your video feed should now appear live on Eventsonlife.com.</li>
  </ul>

  <h2 style="font-size: 24px; font-weight: bold; margin-bottom: 20px;">5. Troubleshooting Tips</h2>
  <p style="font-size: 18px; line-height: 1.6; margin-bottom: 30px;">
    If you encounter issues while streaming, here are a few things to check:
  </p>
  <ul style="list-style-type: none; padding-left: 0;">
    <li style="margin-bottom: 20px; margin-left: 20px;">
      <strong style="font-size: 18px;">Double-check the RTMP URL and Stream Key:</strong> Ensure that you’ve copied and pasted both correctly.
    </li>
    <li style="margin-bottom: 20px; margin-left: 20px;">
      <strong style="font-size: 18px;">OBS Settings:</strong> Verify that OBS is set to stream to the right URL and has the correct stream key.
    </li>
    <li style="margin-bottom: 20px; margin-left: 20px;">
      <strong style="font-size: 18px;">Internet Connection:</strong> Ensure that your internet connection is stable enough to support live streaming.
    </li>
  </ul>

  <p style="font-size: 18px; line-height: 1.6; margin-bottom: 30px;">
    With these simple steps, you can easily stream your event live to Eventsonlife.com using OBS Studio. Happy streaming!
  </p>
</div>
`,
  },
  "guide-to-live-event-coverage-on-eventsonlife": {
    title: "5A. Guide to Live Event Coverage on Eventsonlife",
    content: `<div style="padding: 20px; max-width: 1200px; margin: 0 auto;">
  <h1 style="font-size: 36px; font-weight: bold; margin-bottom: 20px;">Guide to Live Event Coverage on Eventsonlife</h1>
  <p style="font-size: 18px; line-height: 1.6; margin-bottom: 30px;">
    Live event coverage on Eventsonlife is a great way to connect with your audience in real-time, allowing them to be part of your special moments. Whether you're hosting a wedding, a conference, or a party, this guide will help you navigate live streaming with ease.
  </p>

  <h2 style="font-size: 24px; font-weight: bold; margin-bottom: 20px;">1. Preparing for Live Event Coverage</h2>
  <ul style="list-style-type: none; padding-left: 0;">
    <li style="margin-bottom: 20px; margin-left: 20px;">
      <strong style="font-size: 18px;">Plan Ahead:</strong> Ensure you have all the necessary equipment for a smooth live streaming experience, including cameras, microphones, and a stable internet connection.
    </li>
    <li style="margin-bottom: 20px; margin-left: 20px;">
      <strong style="font-size: 18px;">Choose Your Streaming Platform:</strong> Eventsonlife provides the option to stream directly to your event page or through external platforms like YouTube.
    </li>
    <li style="margin-bottom: 20px; margin-left: 20px;">
      <strong style="font-size: 18px;">Test Your Equipment:</strong> Do a dry run before the actual event to ensure everything works as expected. Test your audio, video, and internet connection to avoid issues during the live stream.
    </li>
  </ul>

  <h2 style="font-size: 24px; font-weight: bold; margin-bottom: 20px;">2. Setting Up the Live Stream</h2>
  <ul style="list-style-type: none; padding-left: 0;">
    <li style="margin-bottom: 20px; margin-left: 20px;">
      <strong style="font-size: 18px;">Go to Your Event Page:</strong> Log into your Eventsonlife account and navigate to the event page where you want to stream.
    </li>
    <li style="margin-bottom: 20px; margin-left: 20px;">
      <strong style="font-size: 18px;">Find the Streaming Section:</strong> Look for the live streaming settings in the event management area.
    </li>
    <li style="margin-bottom: 20px; margin-left: 20px;">
      <strong style="font-size: 18px;">Copy Your RTMP Details:</strong> You'll need the Stream Key and RTMP Server URL from Eventsonlife to connect with OBS Studio or any other broadcasting software you are using.
    </li>
  </ul>

  <h2 style="font-size: 24px; font-weight: bold; margin-bottom: 20px;">3. Start Your Live Stream</h2>
  <ul style="list-style-type: none; padding-left: 0;">
    <li style="margin-bottom: 20px; margin-left: 20px;">
      <strong style="font-size: 18px;">Configure OBS:</strong> Enter the RTMP URL and Stream Key into your broadcasting software’s settings (like OBS Studio).
    </li>
    <li style="margin-bottom: 20px; margin-left: 20px;">
      <strong style="font-size: 18px;">Go Live:</strong> Once everything is set up, click “Start Streaming” in OBS or your chosen software to begin broadcasting to Eventsonlife.
    </li>
  </ul>

  <h2 style="font-size: 24px; font-weight: bold; margin-bottom: 20px;">4. Managing the Stream</h2>
  <ul style="list-style-type: none; padding-left: 0;">
    <li style="margin-bottom: 20px; margin-left: 20px;">
      <strong style="font-size: 18px;">Monitor Your Stream:</strong> Keep an eye on your live event’s performance to ensure there are no interruptions.
    </li>
    <li style="margin-bottom: 20px; margin-left: 20px;">
      <strong style="font-size: 18px;">Engage with Viewers:</strong> Respond to comments and questions from viewers to keep the event interactive.
    </li>
  </ul>

  <h2 style="font-size: 24px; font-weight: bold; margin-bottom: 20px;">5. Post-Event Considerations</h2>
  <ul style="list-style-type: none; padding-left: 0;">
    <li style="margin-bottom: 20px; margin-left: 20px;">
      <strong style="font-size: 18px;">Download the Recording:</strong> After the event, you can download the recording from Eventsonlife for future use or sharing.
    </li>
    <li style="margin-bottom: 20px; margin-left: 20px;">
      <strong style="font-size: 18px;">Analyze the Performance:</strong> Eventsonlife offers analytics to help you review the viewer engagement and overall performance of the live stream.
    </li>
  </ul>

  <p style="font-size: 18px; line-height: 1.6; margin-bottom: 30px;">
    Live streaming with Eventsonlife is a fantastic way to bring your audience closer to your event, no matter where they are. Follow these steps, and you’ll be set for a smooth and successful broadcast!
  </p>
</div>
`,
  },
  "how-to-use-the-event-page-edit-features": {
    title: "6A How to Use the Event Page Edit Features",
    content: `<div style="font-family: Arial, sans-serif; line-height: 1.6; margin: 0; padding: 20px; color: #333;">
    <h1 style="font-size: 32px; font-weight: bold; margin-bottom: 20px;">How to Use the Event Page Edit Features</h1>
    <p style="font-size: 18px; line-height: 1.6; margin-bottom: 20px;">
      Creating and managing an event on Eventsonlife.com just became even easier and more fun! With the Event Page Edit section, you can now customize the look and feel of your event page in real time. From adding images and videos to adjusting text styles and colors, you have full control over how your event appears to your audience.
    </p>

    <p style="font-size: 18px; line-height: 1.6; margin-bottom: 30px;">Here’s a complete guide on how to use these features to make your event page stand out!</p>
    <hr style="border-top: 2px solid #333; margin-bottom: 30px;" />
    <img style="width:90%;" src="https://r2.streamteches.com/Screenshot%202025-01-28%20111854.png" alt="edit event"/>
  
    <h2 style="font-size: 24px; font-weight: bold; margin-bottom: 20px;">1. Live Preview of Your Event Page</h2>
    <p style="font-size: 18px; line-height: 1.6; margin-bottom: 20px;">
      The Preview Section within the event page editor allows you to see a live view of how your event page will look once it's published. This means you can make adjustments and immediately see how those changes will affect the final version of your event page.
    </p>
    <ul style="list-style-type: none; padding-left: 0;">
      <li style="margin-bottom: 20px; margin-left: 20px;">
        <strong style="font-size: 18px;">Real-time updates:</strong> See all the changes you make, such as text modifications or image uploads, reflected immediately in the preview.
      </li>
      <li style="margin-bottom: 20px; margin-left: 20px;">
        <strong style="font-size: 18px;">Instant Feedback:</strong> If you want to tweak something before going live, you can make changes and instantly see how those affect the overall look of your page.
      </li>
    </ul>
    <img  style="width:90%;"src="https://r2.streamteches.com/Screenshot%202025-01-28%20112018.png" alt="change colors"/>
    
    <h2 style="font-size: 24px; font-weight: bold; margin-bottom: 20px;">2. Customizing Text and Fonts</h2>
    <p style="font-size: 18px; line-height: 1.6; margin-bottom: 20px;">Personalizing the text on your event page is a breeze with the available customization options. You can adjust:</p>
    <ul style="list-style-type: none; padding-left: 0;">
      <li style="margin-bottom: 20px; margin-left: 20px;">
        <strong style="font-size: 18px;">Text Size:</strong> Make your headings or body text larger or smaller to emphasize important information.
      </li>
      <li style="margin-bottom: 20px; margin-left: 20px;">
        <strong style="font-size: 18px;">Font:</strong> Choose the perfect font to match your event’s theme or branding. You can pick from a range of available fonts to ensure your text is stylish and readable.
        </li>
        <li style="margin-bottom: 20px; margin-left: 20px;">
        <strong style="font-size: 18px;">Color:</strong> Change the text color to fit your event’s design. Whether you want bold, eye-catching text or something more subtle, the color options are extensive.
        </li>
        </ul>
        <p style="font-size: 18px; line-height: 1.6; margin-bottom: 20px;">How to Use Text Customization:</p>
        <ol style="padding-left: 20px;">
        <li style="margin-bottom: 20px; font-size: 18px;">Select the text element you want to modify (like a heading or description).</li>
        <li style="margin-bottom: 20px; font-size: 18px;">Adjust the text size, font, and color using the editing options available.</li>
        <li style="margin-bottom: 20px; font-size: 18px;">Preview the changes in real-time to see how they look on the event page.</li>
        </ol>
        
    <img style="width:90%;" src="https://r2.streamteches.com/Screenshot%202025-01-28%20112102.png" alt="edit color"/>
    <h2 style="font-size: 24px; font-weight: bold; margin-bottom: 20px;">3. Customizing Backgrounds and Gradients</h2>
    <p style="font-size: 18px; line-height: 1.6; margin-bottom: 20px;">
      You can create a unique atmosphere for your event page by modifying the background of the page. Whether you prefer a solid color or a more dynamic gradient effect, you have the flexibility to make it happen.
    </p>
    <ul style="list-style-type: none; padding-left: 0;">
      <li style="margin-bottom: 20px; margin-left: 20px;">
        <strong style="font-size: 18px;">Background Color:</strong> Choose a single color to set the tone of your event.
      </li>
      <li style="margin-bottom: 20px; margin-left: 20px;">
        <strong style="font-size: 18px;">Gradient Backgrounds:</strong> Create a visually appealing gradient background using two or more colors. This can add depth and style to your page, making it look more professional and engaging.
      </li>
    </ul>
    <p style="font-size: 18px; line-height: 1.6; margin-bottom: 20px;">How to Set Backgrounds:</p>
    <ol style="padding-left: 20px;">
      <li style="margin-bottom: 20px; font-size: 18px;">Go to the Background Settings section in the event editor.</li>
      <li style="margin-bottom: 20px; font-size: 18px;">Choose your preferred background option—solid color or gradient.</li>
      <li style="margin-bottom: 20px; font-size: 18px;">Pick the colors or gradient style that suits your event's mood and theme.</li>
      <li style="margin-bottom: 20px; font-size: 18px;">Check the Preview Section to see how the background complements the rest of the page.</li>
    </ol>
  
    <h2 style="font-size: 24px; font-weight: bold; margin-bottom: 20px;">4. Inserting Images and Media Links</h2>
    <p style="font-size: 18px; line-height: 1.6; margin-bottom: 20px;">
      To further enhance your event page, you can insert various media types, such as images, videos, and cards. This helps showcase your event more effectively and gives it a personal touch.
    </p>
    <ul style="list-style-type: none; padding-left: 0;">
      <li style="margin-bottom: 20px; margin-left: 20px;">
        <strong style="font-size: 18px;">Add Images:</strong> You can upload images directly or insert image links from external sources. Images could include event banners, venue photos, promotional posters, etc.
      </li>
      <li style="margin-bottom: 20px; margin-left: 20px;">
        <strong style="font-size: 18px;">Embed Videos:</strong> Insert videos (e.g., promotional videos or event highlights) via YouTube links or by uploading directly to the platform.
      </li>
      <li style="margin-bottom: 20px; margin-left: 20px;">
        <strong style="font-size: 18px;">Add Cards:</strong> Cards can be used to display important information like tickets, schedules, or other interactive elements for your event.
      </li>
    </ul>
    <p style="font-size: 18px; line-height: 1.6; margin-bottom: 20px;">How to Insert Media:</p>
    <ol style="padding-left: 20px;">
      <li style="margin-bottom: 20px; font-size: 18px;">Choose the area where you want to insert your media (image, video, or card).</li>
      <li style="margin-bottom: 20px; font-size: 18px;">For images, either upload the file from your computer or paste a link to the image’s URL.</li>
      <li style="margin-bottom: 20px; font-size: 18px;">For videos, paste the video link from YouTube or another supported platform, or upload the video directly.</li>
      <li style="margin-bottom: 20px; font-size: 18px;">For cards, choose the type of card you’d like to add and enter the necessary information.</li>
      <li style="margin-bottom: 20px; font-size: 18px;">Preview your changes to make sure the media elements appear as expected.</li>
    </ol>
    <img style="width:90%;" src="https://r2.streamteches.com/Screenshot%202025-01-28%20112152.png" alt="edit color"/>
  
    <h2 style="font-size: 24px; font-weight: bold; margin-bottom: 20px;">5. Live Editing for Text and Media</h2>
    <p style="font-size: 18px; line-height: 1.6; margin-bottom: 20px;">
      You have full control over text and media placement on your event page. You can make edits directly on the page with live updates, allowing you to change the appearance of your page with ease.
    </p>
    <ul style="list-style-type: none; padding-left: 0;">
      <li style="margin-bottom: 20px; margin-left: 20px;">
        <strong style="font-size: 18px;">Change Text:</strong> Modify any text element directly, from event titles to descriptions.
      </li>
      <li style="margin-bottom: 20px; margin-left: 20px;">
        <strong style="font-size: 18px;">Move Media:</strong> Drag and drop media elements to change their position on the page.
      </li>
    </ul>
  </div>
  `,
  },
};
