import { FaSquareInstagram } from "react-icons/fa6";
import { FaSquareFacebook } from "react-icons/fa6";
import { FaSquareXTwitter } from "react-icons/fa6";
import { FaSquareYoutube } from "react-icons/fa6";
export const BaseFoooter =()=>{
    return (
        <footer className="mt-4 pt-4 text-center pb-3 text-light footer">
        <h1
          style={{ marginBottom: "10px", fontSize: "2rem" }}
          className="section-font-heading"
        >
          Follow Us
        </h1>
        <p className="section-matter" style={{ fontSize: "1rem" }}>
          Stay updated with the latest news and updates by following us on
          social media.
        </p>
        <div
          className="mt-4"
          style={{
            display: "flex",
            justifyContent: "center",
            flexWrap: "wrap",
          }}
        >
          <a
            className="icon me-1 me-md-4"
            href="https://www.instagram.com"
            target="_blank"
            rel="noopener noreferrer"
            style={{ margin: "0 10px" }}
          >
            <FaSquareInstagram size={20} />
          </a>
          <a
            className="icon me-1 me-md-4"
            href="https://www.facebook.com/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ margin: "0 10px" }}
          >
            <FaSquareFacebook size={20} />
          </a>
          <a
            className="icon me-1 me-md-4"
            href="https://www.twitter.com/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ margin: "0 10px" }}
          >
            <FaSquareXTwitter size={20} />
          </a>
          <a
            className="icon"
            href="https://www.youtube.com/"
            target="_blank"
            rel="noopener noreferrer"
            style={{ margin: "0 10px" }}
          >
            <FaSquareYoutube size={20} />
          </a>
        </div>
  
        <p style={{ marginTop: "15px", fontSize: "1rem" }}>
          Maintained By Infinitum Tech
        </p>
        <div
          style={{
            width: "100vw",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "10px",
            marginTop: "10px",
          }}
        >
          <a
            href="/blogs"
            style={{
              color: "#3b82f6",
              textDecoration: "none",
              fontSize: "1rem",
            }}
          >
            Blogs
          </a>
          |
          <a
            href="/terms-of-service"
            target="_blank"
            style={{
              color: "#3b82f6",
              textDecoration: "none",
              fontSize: "1rem",
            }}
          >
            Terms of Service
          </a>
          |
          <a
            href="/privacy-policy"
            target="_blank"
            style={{
              color: "#3b82f6",
              textDecoration: "none",
              fontSize: "1rem",
            }}
          >
            Privacy Policy
          </a>
          |
          <h3 className="section-font" style={{ fontSize: "1rem" }}>
            Copyright © 2024 Infinitum Tech. All rights reserved
          </h3>
        </div>
      </footer>
    )
}