import React, { useState } from "react";
import Slider from "react-slick";
import { MdKeyboardArrowLeft, MdKeyboardArrowRight } from "react-icons/md";
import styles from "./fancyBoxGallery.module.css";
import { useAppDataContext } from "hooks/useappdatacontext";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { motion } from "framer-motion";

import styles2 from "../../pages/Custom/T2/styles.module.css";

const DefaultGallery = () => {
  const { singleEventData } = useAppDataContext();
  const [overlayImage, setOverlayImage] = useState(null);

  // Fallback images if singleEventData.images is empty or not available

  // If `singleEventData.images` is available and not empty, use it; otherwise, use fallbackImages
  const images = singleEventData?.images?.length > 0 && singleEventData.images;

  // Slider settings with dynamic controls based on image count
  const settings = {
    dots: true,
    infinite: images.length > 1, // No infinite scroll if only one image
    speed: 500,
    slidesToShow: images.length === 2 ? 2 : 2, // Show 1 or 2 slides depending on image count
    slidesToScroll: 1,
    autoplay: images.length > 2, // Enable autoplay only if there are more than 2 images
    arrows: images.length > 1, // Show arrows only if there's more than 1 image
    nextArrow: <MdKeyboardArrowRight />,
    prevArrow: <MdKeyboardArrowLeft />,
    responsive: [
      {
        breakpoint: 850, // For small screens, show 1 image at a time
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          arrows: true,
        },
      },
    ],
  };

  const handleImageClick = (image) => {
    setOverlayImage(image);
  };

  const closeOverlay = () => {
    setOverlayImage(null);
  };

  return (
    <div className={styles.galleryContainer}>
      {images.length > 0 ? (
        images.length === 1 ? (
          <motion.div
            className={styles2.galleryImgT2}
            whileHover={{ scale: 1.05, transition: { duration: 0.3 } }}
          >
            <img
              src={
                singleEventData?.images?.length ? images[0]?.image : images[0]
              } // Ensure correct src for event or fallback images
              alt="Event"
              className={styles.thumbnail}
              onClick={() => handleImageClick(images[0])}
            />
          </motion.div>
        ) : (
          // When there are multiple images, show the slider
          <Slider {...settings}>
            {images.map((image, index) => (
              <motion.div
                key={index}
                style={{ margin: "0px 10px" }}
                className={styles2.galleryImgT2}
                whileHover={{ scale: 1.05, transition: { duration: 0.3 } }}
              >
                <img
                  src={singleEventData?.images?.length ? image?.image : image} // Ensure correct src for event or fallback images
                  alt={`Event ${index + 1}`}
                  className={styles.thumbnail}
                  onClick={() => handleImageClick(image)}
                />
              </motion.div>
            ))}
          </Slider>
        )
      ) : (
        <h1>No images present...</h1>
      )}
      {overlayImage && (
        <div className={styles.overlay} onClick={closeOverlay}>
          <img
            src={overlayImage?.image || overlayImage} // handle event images or fallback images
            alt="Full Size"
            className={styles.fullImage}
          />
        </div>
      )}
    </div>
  );
};

export default DefaultGallery;
