import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
  Typography,
  CircularProgress,
  Box,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  FormLabel,
  Checkbox,
  LinearProgress,
} from "@mui/material";
import { Circle, CopyAll as CopyIcon } from "@mui/icons-material";
import PhoneInput from "react-phone-input-2";
import {
  MdDownload,
  MdDeleteForever,
  // MdAccessTimeFilled,
  MdWarning,
} from "react-icons/md";
import axiosInstance from "utils/axios";
import { useCallback, useEffect, useRef, useState } from "react";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import { FaEdit } from "react-icons/fa";
import { MdSaveAs, MdVisibility, MdVisibilityOff } from "react-icons/md";
import { useAppDataContext } from "hooks/useappdatacontext";
// import DOMPurify from "dompurify";
// import parse from "html-react-parser";
import { toast } from "react-toastify";
import axios from "axios";
import videojs from "video.js";
import { styled } from "@mui/material/styles";
import { useNavigate, useParams } from "react-router-dom";
// import { BsCalendar2DateFill } from "react-icons/bs";

import "./index.css";
import CoupleFrame from "../../assets/images/coupleFrame.png";
// import { FaLocationDot } from "react-icons/fa6";
import { IoCopy } from "react-icons/io5";
import HLSPlayer from "../../components/hlsPlayer";
import FancyBoxGallery from "../../components/fancyBoxGallery/fancyBoxGallery";
import styles from "../../pages/weddingTemplate/weddingTemplate.module.css";
// import EditLoadingView from "./LoadingView";
// import UploadProgressPopup from "./LoadingView/uploadProgress";
import UrlPreview from "../../components/urlPreview/page";
import VideoPlayerForEdit from "./VideoPlayerForEdit";
import { SiTicktick } from "react-icons/si";
import { RxCrossCircled } from "react-icons/rx";

// import image1 from "../../assets/images/image_2_jmzdab.jpg";
// import image2 from "../../assets/images/image_rotated_hvnqjc.jpg";
import { CheckCheck, Heading1, Settings, X, XCircle } from "lucide-react";
import { useAuthContext } from "hooks/useauthcontext";
import styles2 from "../Custom/T2/styles.module.css";
import walletPaper from "../../assets/images/grunge-white-surface-rough-background-textured-rotate.jpg";
import RenderDefaultTemplate from "./RenderDefaultTemplate";
import LoadTemplateSettings from "./LoadTemplateSettings";
import T3RenderTemplate from "./T3RenderTemplate";
import T2 from "./T2Template";
import { capitalizeFirstLetter } from "../weddingTemplate/comonFunctions";

let textStyleingProperties = {
  whiteSpace: "nowrap",
  overflowX: "auto",
  maxWidth: "100%",
};

const EditEvent = () => {
  const { userData } = useAuthContext();
  const navigate = useNavigate();
  const {
    eventData,
    setEventData,
    getAllEvents,
    getEvent,
    updateEventData,
    getChannelData,
    singleChannelData,
    singleEventData,
    setSingleEventData,
    updateCustomHlsApi,
    showLoader,
    loading,
    hlsVideoUrl,
    setShowLoader,
    userCredits,
    recordingCheck,
    checkRecorded,
    deleteChannel,
  } = useAppDataContext();
  const [streamStatus, setStreamStatus] = useState("");
  const [loadingView, setLoadingView] = useState(false);
  const [uploadinProgress, setUploadinProgress] = useState(""); //state update uploading progress
  const [optionData, setOptionData] = useState({});
  const [apiError, setApiError] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [apiSuccess, setApiSuccess] = useState("");
  const [selectedPasswordOption, setSelectedPasswordoption] = useState("");
  const [minimumPassword, setMinimumPassword] = useState("");
  const [fetchedPassword, setFetchedPassword] = useState("");
  const videoRef = useRef(null);
  const invitaionVideoRef = useRef(null);
  const [resolutionInvitaion, setResolutionInvitaion] = useState({
    width: 0,
    height: 0,
  });
  // const [resolution, setResolution] = useState({ width: 0, height: 0 });
  const [hlsLiveUrl, setHlsLiveUrl] = useState("");
  const [error, setError] = useState("");
  const [passwordVisibilty, setPasswordVisibility] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [selectedBanner, setSelectedBanner] = useState(null);
  const [selectedInvitationCard, setSelectedInvitationCard] = useState(null);
  const [selectedInvitationVideo, setSelectedInvitationVideo] = useState(null);
  const [selectedOfflineVideo, setSelectedOfflineVideo] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);
  const [selectedOption, setSelectedOption] = useState("RTMPS");
  const [popCanelToEdit, setPopUpCancelToEdit] = useState(false);

  const [viewDownloadLink, setViewDownloadLink] = useState(false);
  const [videoSrc, setVideoSrc] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [uploadCount, setUploadCount] = useState(0); // Tracks number of uploaded chunks
  const [totalUploads, setTotalUploads] = useState(0); // Tracks total chunks to upload
  const [progress, setProgress] = useState(false); // Tracks upload progress percentage
  const [offlineVideoDimensions, setOfflineVideoDimensions] = useState({
    width: 0,
    height: 0,
  });

  const [uploadingVideo, setUploadingVideo] = useState({
    uploadingInvitation: 0,
    uploadingOfflineVideo: 0,
  });
  const [copied, setCopied] = useState(false);

  const [LoadingViewFor5, setLoadingViewFor5] = useState(true);
  const [btnLoading, setBtnLoading] = useState(0);

  const [showLinkDeletationDialog, setShowLinkDeletationDialog] =
    useState(false);

  const [openStyleSettings, setOpenStyleSettings] = useState(false);
  const [selectedSettingsOption, setSelectedSettingsOption] = useState("image");
  const [selectedMainSettingsOption, setSelectedMainSettingsOption] =
    useState("bg");
  const [btnClicked, setBtnClicked] = useState(false);
  const [openChannelDeletePopUp, setOpenChannelDeletePopUp] = useState(false);

  //state to cahnge the input data after clicking save button on every input field this satate data will be posted to event data
  const [content, setContent] = useState({
    userName: "",
    contact: "",
    eventName: "",
    template: "",
    venue: "",
    venue_date: "",
    venue_time: "",
    template_desc: "",
    live_time: "",
    prlink: "",
    scrolling_mess: "",
    live_vidlink: "",
    template_css: {
      bgColor: "",
      bgImage: "",
      bgOpacity: null,
      textColor: "",
      textFontFamily: "",
      titleFontSize: "",
      titleFontWeight: "",
      headingFontSize: "",
      bgGradient: "",
      template_type: "",
    },
    ads_disabled: false,
  });
  useEffect(() => {
    document.title = `Event on Life - Edit - ${
      singleEventData?.title
        ? capitalizeFirstLetter(singleEventData?.title)
        : ""
    }`;
  }, [singleEventData?.title]);

  useEffect(() => {
    let timer = setTimeout(() => {
      setLoadingViewFor5(false);
    }, 4500);

    return () => {
      clearTimeout(timer);
    };
  }, []);

  useEffect(() => {
    const call = async () => {
      if (singleChannelData?.id) {
        await recordingCheck(singleChannelData?.id);
        // console.log(await res.json());
      }
    };

    call();
  }, [singleChannelData?.recording, eventData?.venueDate]);

  useEffect(() => {
    setLoadingViewFor5(true);
    setTimeout(() => {
      setLoadingViewFor5(false);
    }, 1500);
  }, [content?.template_css?.template_type]);

  useEffect(() => {
    let timer;

    const getNavigateFunction = () => {
      navigate("/dashboard");
      setUploadingVideo({ uploadingInvitation: 0, uploadingOfflineVideo: 0 });
    };

    if (
      uploadingVideo.uploadingInvitation === 100 &&
      uploadingVideo.uploadingOfflineVideo === 0
    ) {
      timer = setTimeout(() => {
        getNavigateFunction();
      }, 2000);
    }

    if (
      uploadingVideo.uploadingOfflineVideo === 100 &&
      uploadingVideo.uploadingInvitation === 0
    ) {
      timer = setTimeout(() => {
        getNavigateFunction();
      }, 2000);
    }

    if (
      uploadingVideo.uploadingInvitation === 100 &&
      uploadingVideo.uploadingOfflineVideo === 100
    ) {
      timer = setTimeout(() => {
        getNavigateFunction();
      }, 2000);
    }

    // Cleanup the timer when component is unmounted or dependencies change
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [uploadingVideo]);

  // const vidRef = useRef(null);
  const offlineVideoRef = useRef(null);

  //loading for url searching
  const [isLoading, setIsLoading] = useState(false);
  const [tick, setTick] = useState();
  const [newPrlinks, setNewPrlinks] = useState(null);

  const handleFileChangeForOffline = (event) => {
    const file = event.target.files[0];
    if (file) {
      const objectUrl = URL.createObjectURL(file);
      setSelectedOfflineVideo(file);
      setVideoSrc(objectUrl);
    }
  };

  const [popCanelTheEditOrConfirm, setpopCanelTheEditOrConfirm] =
    useState(false);
  const playerRef = useRef(null);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const [isChecked, setIsChecked] = useState(false);
  const [openEdit, setOpenEdit] = useState(false);

  const [textEditor, setTextEditor] = useState({
    userName: false,
    contact: false,
    eventName: false,
    template: false,
    venue: false,
    venueDate: false,
    venueTime: false,
    liveTime: false,
    prlink: false,
    scrolling_mess: false,
  });

  const [option, setOption] = useState("");
  const [isOpen, setIsOpen] = useState(false);

  const toggleSidebar = () => {
    setIsOpen((prev) => !prev);
  };

  //sdsjsk
  //useEffect to fetch data feom backend with id retrived from routing
  const { id } = useParams();

  useEffect(() => {
    setShowLoader(false);
    setLoadingView(false);
    handleClickOpenEdit();
  }, []);

  useEffect(() => {
    if (singleEventData?.channattahed) {
      handleGetChannelData(id);
    }
  }, [singleEventData?.channattahed]);
  const [dwnloadlink, setDownloadlink] = useState(false);

  const handleClickOpenEdit = async () => {
    const das = await getEvent(id);
    setSingleEventData(das);
    setSelectedRowId(id);
    setOpenEdit(true);
    // Reset player before reinitializing
    if (playerRef.current) {
      playerRef.current.dispose();
      playerRef.current = null;
    }
    setTimeout(initializePlayer, 1000);
  };
  useEffect(() => {
    if (singleChannelData?.downloadLink) {
      setDownloadlink(true);
    }
  }, [singleChannelData]);

  //effect to set essential singleEventData to contenet state
  useEffect(() => {
    let a;
    if (
      singleEventData?.template_css !== "" &&
      singleEventData?.template_css !== undefined
    ) {
      const parsedData = JSON.parse(singleEventData.template_css); // ✅ Parse first
      if (!parsedData.template_type) {
        parsedData.template_type = "default"; // ✅ Add `template_type` if missing
      }
      console.log("parsedData: ", parsedData);
      a = parsedData;
    }
    setContent({
      userName: singleEventData?.name,
      contact: singleEventData?.contact,
      eventName: singleEventData?.title,
      template: singleEventData?.template,
      venue: singleEventData?.venue,
      venue_date: singleEventData?.venue_date,
      venue_time: singleEventData?.venue_time,
      template_desc: singleEventData?.template_desc,
      live_time: singleEventData?.live_time,
      prlink: singleEventData?.prlink,
      scrolling_mess: singleEventData?.scrolling_mess,
      live_vidlink: singleEventData?.live_vidlink,
      template_css: a,
      ads_disabled: singleEventData?.ads_disabled,
    });
  }, [singleEventData]);

  //hook to define eventData hook variables with singleEventData hooks after page loaded
  useEffect(() => {
    // setPopUpCancelToEdit(false);
    let a;
    if (
      singleEventData?.template_css !== "" &&
      singleEventData?.template_css !== undefined
    ) {
      const parsedData = JSON.parse(singleEventData.template_css); // ✅ Parse first
      if (!parsedData.template_type) {
        parsedData.template_type = "default"; // ✅ Add `template_type` if missing
      }
      a = parsedData;
    }
    setEventData({
      name: singleEventData?.name,
      contact: singleEventData?.contact || "",
      password: singleEventData?.password,
      title: singleEventData?.title || "",
      venue: singleEventData?.venue || "",
      venue_date: singleEventData?.venue_date || "",
      venue_time: singleEventData?.venue_time || "",
      live_vidlink: singleEventData?.live_vidlink,
      banner: singleEventData?.banner,
      invitation_card: singleEventData?.invitation_card,
      invitation_video: singleEventData?.invitation_video,
      offline_video: singleEventData?.offline_video,
      images: singleEventData?.images || [],
      is_secure: singleEventData?.is_secure,
      template: singleEventData?.template || "",
      template_desc: singleEventData?.template_desc || "",
      live_time: singleEventData?.live_time || "",
      prlink: singleEventData?.prlink || "",
      scrolling_mess: singleEventData?.scrolling_mess,
      id: singleEventData?.id,
      template_css: a,
      ads_disabled: singleEventData?.ads_disabled,
    });
  }, [singleEventData]);

  const containerRef = useRef(null); // Reference to the container
  const contentContainerRef = useRef(null);
  const [containerHeight, setContainerHeight] = useState(0);
  const [contentContainerHeight, setContentContainerHeight] = useState(0);

  const calculateHeight = () => {
    if (containerRef.current) {
      setContainerHeight(containerRef.current.scrollHeight); // Get the height of the content
    }
    if (contentContainerRef.current) {
      setContentContainerHeight(contentContainerRef.current.scrollHeight);
    }
  };

  const updateHeightSmoothly = () => {
    requestAnimationFrame(() => {
      calculateHeight();
    });
  };

  useEffect(() => {
    calculateHeight();

    const handleResize = () => {
      updateHeightSmoothly();
    };
    window.addEventListener("resize", handleResize);

    const observer = new MutationObserver(updateHeightSmoothly);
    if (containerRef.current) {
      observer.observe(containerRef.current, {
        childList: true,
        subtree: true,
      });
    }
    if (contentContainerRef.current) {
      observer.observe(contentContainerRef.current, {
        childList: true,
        subtree: true,
      });
    }

    return () => {
      window.removeEventListener("resize", handleResize);
      if (observer) {
        observer.disconnect();
      }
    };
  }, [eventData, content, singleEventData]);

  updateHeightSmoothly();

  //video generating funtion
  const handlerToGenerateVideoLink = async (id) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_MEDIA_URL}/api/stream/initate_mp4/${id}`
      );
      if (response.status === 200) {
        setBtnClicked(true);
        toast.success(response.data.message);
      } else {
        toast.error(response.data.message);
      }
    } catch (error) {
      toast.error("An error occurred");
      console.error(error);
    }
  };

  //video download with link handler
  const handleDownloadVideo = (videoUrl) => {
    const link = document.createElement("a");
    link.href = videoUrl;
    link.download = "";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  //video download event handler
  const handlerToDownloadVideo = async (id) => {
    try {
      const response = await axiosInstance.get(
        `${process.env.REACT_APP_MEDIA_URL}/api/stream/get_dwnlink_mp4/${id}`
      );

      if (response.status === 200) {
        handleDownloadVideo(response.data.Link);
      }
    } catch (error) {
      setApiError("Failed to fetch download link. Please try again.");
    }
  };

  const renderScrollingMess = (hider) => {
    return (
      <>
        {hider && eventData?.scrolling_mess && (
          <div style={{ width: "100%" }} className={styles.navbarForAd}>
            <div style={{ width: "100%" }} className="scrollingmess">
              <div style={{ width: "100%" }} className={styles.advertisement}>
                {eventData?.scrolling_mess}
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  //component to render live video based on available link
  const renderLiveVideoComponent = (hider, preview) => {
    let videoOptions;
    if (hlsVideoUrl) {
      videoOptions = {
        autoplay: false,
        controls: true,
        muted: true,
        sources: [{ src: hlsVideoUrl, type: "application/x-mpegURL" }],
      };
    }

    // Extract YouTube video and live IDs
    const videoId = extractYouTubeVideoId(eventData?.live_vidlink);
    const liveId = extractYouTubeLiveId(eventData?.live_vidlink);

    // Determine the appropriate video URL based on the type
    const videoUrl = videoId
      ? `https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1`
      : "";
    const liveUrl = liveId
      ? `https://www.youtube.com/embed/${liveId}?autoplay=1&mute=1`
      : "";

    const presentDate = new Date();
    const futureDate = new Date(singleChannelData?.dwn_expiry_date); //singleChannelData?.dwn_expiry_date

    const differenceInMilliseconds = futureDate - presentDate;
    const differenceInDays = Math.ceil(
      differenceInMilliseconds / (1000 * 60 * 60 * 24)
    );

    const buttonStyle = {
      width: "150px",
      margin: "auto",
      background: "#ffffff",
      color: "#2575fc",
      padding: "15px 30px",
      border: "none",
      borderRadius: "25px",
      fontSize: "12px",
      fontWeight: "bold",
      cursor: "pointer",
      boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.2)",
      transition: "all 0.3s ease-in-out",
    };

    const handleMouseOver = (e) => {
      e.target.style.background = "#2575fc";
      e.target.style.color = "#ffffff";
      e.target.style.boxShadow = "0px 12px 20px rgba(0, 0, 0, 0.3)";
      e.target.style.transform = "translateY(-2px)";
    };

    const handleMouseOut = (e) => {
      e.target.style.background = "#ffffff";
      e.target.style.color = "#2575fc";
      e.target.style.boxShadow = "0px 8px 15px rgba(0, 0, 0, 0.2)";
      e.target.style.transform = "translateY(0px)";
    };
    return (
      <>
        {/* {(hider === "hider" &&
          eventData.scrolling_mess !== "" &&
          eventData.live_vidlink ||
            optionData.option !== "" ||
            hlsVideoUrl !== "") && (
            
          )} */}
        {hider &&
          optionData?.option === "youtubeChannelId" &&
          optionData?.youtubeLiveUrl && (
            <div style={{ width: "100%" }}>
              <h1
                style={{
                  color: content?.template_css?.textColor
                    ? content?.template_css?.textColor
                    : "black",
                  fontFamily: content?.template_css?.textFontFamily
                    ? content?.template_css?.textFontFamily
                    : "Courier New",
                  fontSize: content?.template_css?.headingFontSize
                    ? content?.template_css?.headingFontSize
                    : "20px",
                }}
                className="editingInvitationHeading"
                // className={styles2.bannerHeadingT2}
              >
                Live Video
              </h1>
              {renderScrollingMess(hider)}
              <iframe
                frameBorder="0"
                style={{
                  width: "80%",
                  margin: "auto",
                  height: "350px",
                  borderRadius: "8px",
                }}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="YouTube Video"
                src={`https://www.youtube.com/embed/${extractYouTubeLiveId(
                  optionData?.youtubeLiveUrl
                )}?autoplay=1&mute=1`}
              ></iframe>
            </div>
          )}
        {hider &&
          optionData?.option === "youtubeStreamedUrl" &&
          optionData?.youtubeStreamedUrl && (
            <div style={{ width: !hider ? "80%" : "100%" }}>
              <h1
                style={{
                  color: content?.template_css?.textColor
                    ? content?.template_css?.textColor
                    : "black",
                  fontFamily: content?.template_css?.textFontFamily
                    ? content?.template_css?.textFontFamily
                    : "Courier New",
                  fontSize: content?.template_css?.headingFontSize
                    ? content?.template_css?.headingFontSize
                    : "20px",
                }}
                className="editingInvitationHeading"
                // className={styles2.bannerHeadingT2}
              >
                Live Video
              </h1>
              {renderScrollingMess(hider)}
              <iframe
                frameBorder="0"
                style={{
                  width: "80%",
                  margin: "auto",
                  height: "350px",
                  borderRadius: "8px",
                }}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="YouTube Video"
                src={`https://www.youtube.com/embed/${extractYouTubeVideoId(
                  optionData?.youtubeStreamedUrl
                )}?autoplay=1&mute=1`}
              ></iframe>
            </div>
          )}
        {hider &&
          !optionData?.youtubeStreamedUrl &&
          !eventData?.live_vidlink &&
          hlsVideoUrl && (
            <div style={{ width: "100%" }}>
              <h1
                style={{
                  color: content?.template_css?.textColor
                    ? content?.template_css?.textColor
                    : "black",
                  fontFamily: content?.template_css?.textFontFamily
                    ? content?.template_css?.textFontFamily
                    : "Courier New",
                  fontSize: content?.template_css?.headingFontSize
                    ? content?.template_css?.headingFontSize
                    : "20px",
                }}
                className="editingInvitationHeading"
                // className={styles2.bannerHeadingT2}
              >
                Live Video
              </h1>
              {renderScrollingMess(hider)}
              <div style={{ width: "80%", margin: "auto" }}>
                <VideoPlayerForEdit options={videoOptions} />
              </div>
            </div>
          )}
        <>
          {(videoId !== null || liveId !== null) && hlsVideoUrl === "" && (
            <div
              className={`${
                !hider
                  ? "box-border-styling"
                  : preview
                  ? "box-border-styling-80"
                  : "box-border-styling-100"
              }`}
            >
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                  // backgroundImage: `${hider && `url('${image1}')`}`,
                }}
                className={`${hider && "video-container"}`}
              >
                {!hider && <h4>Live Video</h4>}

                {videoUrl && !liveUrl && (
                  <div style={{ width: "100%", margin: "auto" }}>
                    {hider && (
                      <h1
                        style={{
                          color: content?.template_css?.textColor
                            ? content?.template_css?.textColor
                            : "black",
                          fontFamily: content?.template_css?.textFontFamily
                            ? content?.template_css?.textFontFamily
                            : "Courier New",
                          fontSize: content?.template_css?.headingFontSize
                            ? content?.template_css?.headingFontSize
                            : "30px",
                        }}
                        className="editingInvitationHeading"
                        // className={styles2.bannerHeadingT2}
                      >
                        Live Video
                      </h1>
                    )}
                    {renderScrollingMess(hider)}
                    <iframe
                      style={{
                        width: hider ? "80%" : "100%",
                        margin: "auto",
                        height: "350px",
                        borderRadius: "8px",
                      }}
                      src={videoUrl}
                      frameBorder="0"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      title="YouTube Video"
                    ></iframe>
                  </div>
                )}

                {liveUrl && !videoUrl && (
                  <div style={{ width: "100%", margin: "auto" }}>
                    {hider && (
                      <>
                        <h1
                          style={{
                            color: content?.template_css?.textColor
                              ? content?.template_css?.textColor
                              : "black",
                            fontFamily: content?.template_css?.textFontFamily
                              ? content?.template_css?.textFontFamily
                              : "Courier New",
                            fontSize: content?.template_css?.headingFontSize
                              ? content?.template_css?.headingFontSize
                              : "30px",
                          }}
                          className="editingInvitationHeading"
                          // className={styles2.bannerHeadingT2}
                        >
                          Live Video
                        </h1>
                        {renderScrollingMess(hider)}
                      </>
                    )}
                    <iframe
                      style={{
                        borderRadius: "8px",
                        width: hider ? "80%" : "100%",
                        margin: "auto",
                        height: "350px",
                      }}
                      src={liveUrl}
                      frameBorder="3"
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      title="YouTube Live"
                    ></iframe>
                  </div>
                )}
              </div>
              {!hider && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "3px",
                    width: "100%",
                    padding: "10px 15px",
                    border: "1px solid black",
                    marginTop: "10px",
                    borderRadius: "5px",
                    background: "white",
                  }}
                >
                  <input
                    type="text"
                    style={{ width: "100%", border: "none", outline: "none" }}
                    value={content?.live_vidlink}
                    onChange={(e) =>
                      setContent({
                        ...content,
                        live_vidlink: e.target.value,
                      })
                    }
                  />
                  {content?.live_vidlink === "" ? (
                    <X
                      onClick={() => {
                        if (userData && userData?.is_admin) {
                          setEventData({
                            ...eventData,
                            live_vidlink: content?.live_vidlink,
                          });
                        } else {
                          setShowLinkDeletationDialog(true);
                        }
                      }}
                      style={{ cursor: "pointer" }}
                    />
                  ) : (
                    <CheckCheck
                      onClick={() => {
                        setEventData({
                          ...eventData,
                          live_vidlink: content?.live_vidlink,
                        });
                      }}
                      style={{ cursor: "pointer" }}
                    />
                  )}
                </div>
              )}
            </div>
          )}
        </>

        {/* {liveUrl && !videoUrl && (
          <div
            className={`${
              !hider ? "box-border-styling" : "box-border-styling-80"
            }`}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                backgroundImage: `${hider && `url('${image1}')`}`,
              }}
              className={`${hider && "video-container-live"} `}
            >
              
              
            </div>
          </div>
        )} */}
        {hlsLiveUrl && !videoUrl && !liveUrl && (
          <div
            className={`${
              !hider
                ? "box-border-styling"
                : preview
                ? "box-border-styling-80"
                : "box-border-styling-100"
            }`}
          >
            <div className="videoContainer">
              {hider && (
                <>
                  <h1
                    style={{
                      color: content?.template_css?.textColor
                        ? content?.template_css?.textColor
                        : "black",
                      fontFamily: content?.template_css?.textFontFamily
                        ? content?.template_css?.textFontFamily
                        : "Courier New",
                      fontSize: content?.template_css?.headingFontSize
                        ? content?.template_css?.headingFontSize
                        : "30px",
                    }}
                    className="editingInvitationHeading"
                    // className={styles2.bannerHeadingT2}
                  >
                    Live Video
                  </h1>
                  {renderScrollingMess(hider)}
                </>
              )}
              <div>
                <HLSPlayer
                  selectedTemplateOption={
                    content?.template_css?.template_type || "t1"
                  }
                  singleChannelData={singleChannelData}
                  editPage={true}
                  hider={true}
                  streamStatus={streamStatus}
                  setStreamStatus={setStreamStatus}
                />
              </div>
            </div>
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              {!hider && (
                <>
                  {singleChannelData?.recording &&
                  singleChannelData?.recording_playback_url ? (
                    <>
                      {singleChannelData?.recording_playback_url !==
                      eventData?.live_vidlink ? (
                        <button
                          onClick={() =>
                            handleTriggerHlsApi(singleChannelData?.id)
                          }
                          className="updateHlsLiveBtn"
                        >
                          {loading ? (
                            <CircularProgress size={24} />
                          ) : (
                            "Stream Recording"
                          )}
                        </button>
                      ) : (
                        <div className="link-input-and-download-btn">
                          {/* {viewDownloadLink && (
                            <input
                              type="text"
                              style={{
                                width: "100%",
                                boxSizing: "border-box",
                                padding: "10px",
                                marginBottom: "5px",
                              }}
                              value={eventData?.live_vidlink}
                              disabled
                            />
                          )} */}
                          {/* {!viewDownloadLink && (
                            <>
                              {futureDate < presentDate && (
                                <button
                                  className="download-button"
                                  style={{
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    backgroundColor: "#4CAF50",
                                    width: "120px",
                                    justifySelf: "center",
                                    marginBottom: "5px",
                                  }}
                                  onClick={() => {
                                    setViewDownloadLink(!viewDownloadLink);
                                  }}
                                >
                                  View Link
                                </button>
                              )}
                            </>
                          )} */}

                          {singleChannelData?.recordLinksent === false && (
                            <div
                              style={{
                                marginTop: "20px",
                                textAlign: "center",
                                fontFamily: "Arial, sans-serif",
                              }}
                            >
                              {!btnClicked && (
                                <p
                                  style={{
                                    fontSize: "16px",
                                    color: "#555",
                                    marginBottom: "10px",
                                  }}
                                >
                                  🚀 Click Here to Generate Your MP4 Link 🎥
                                </p>
                              )}
                              {btnClicked ? (
                                <button
                                  style={{
                                    ...buttonStyle,
                                    display: "flex",
                                    gap: "5px",
                                  }}
                                >
                                  Please Wait
                                  <span className="generate-link-loader"></span>
                                </button>
                              ) : (
                                <button
                                  onClick={() =>
                                    handlerToGenerateVideoLink(
                                      singleChannelData?.id
                                    )
                                  }
                                  style={buttonStyle}
                                  onMouseOver={handleMouseOver}
                                  onMouseOut={handleMouseOut}
                                  disabled={btnClicked}
                                >
                                  Generate Link
                                </button>
                              )}

                              {/* <button
                                style={{
                                  backgroundColor: "#4CAF50",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                  justifySelf: "center",
                                }}
                                className="download-button"
                              ></button> */}
                            </div>
                          )}

                          {dwnloadlink === true ? (
                            <>
                              {differenceInDays >= -0 && (
                                <>
                                  <button
                                    onClick={() =>
                                      handlerToDownloadVideo(
                                        singleChannelData?.id
                                      )
                                    }
                                    style={{
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                      justifySelf: "center",
                                      marginTop: "10px",
                                    }}
                                    className="download-button"
                                  >
                                    <MdDownload fontSize={18} /> Video
                                  </button>
                                  <p className="expiry-warning-text">
                                    <MdWarning className="location-icon" />{" "}
                                    Download Link will be expired in{" "}
                                    {differenceInDays}{" "}
                                    {differenceInDays === 1 ? "day" : "days"}{" "}
                                  </p>
                                </>
                              )}
                              {differenceInDays < -0 && (
                                <p
                                  style={{
                                    color: "red",
                                    margin: "8px 0px 0px 0px",
                                    textAlign: "center",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                  }}
                                >
                                  <MdWarning className="location-icon" />{" "}
                                  Download Link expired
                                </p>
                              )}
                            </>
                          ) : (
                            <>
                              {singleChannelData?.recordLinksent && (
                                <button
                                  disabled
                                  style={{
                                    backgroundColor: "#007bff",
                                    display: "flex",
                                    justifyContent: "center",
                                    alignItems: "center",
                                    justifySelf: "center",
                                  }}
                                  className="download-button"
                                >
                                  Generating...
                                </button>
                              )}
                            </>
                          )}
                        </div>
                      )}
                    </>
                  ) : eventData?.live_vidlink.includes(
                      "891377112047.channel"
                    ) ? null : (
                    singleChannelData?.playback_url !==
                      eventData.live_vidlink &&
                    !singleChannelData?.recording_playback_url && (
                      <input
                        type="text"
                        value={eventData?.live_vidlink}
                        onChange={(e) => {
                          const newValue = e.target.value;
                          const extractedUrl = extractHlsUrl(newValue);
                          setHlsLiveUrl(extractedUrl);
                          setEventData({
                            ...eventData,
                            live_vidlink: newValue,
                          });
                        }}
                        className="edit-modal-input"
                        placeholder="Enter live video URL"
                      />
                    )
                  )}
                </>
              )}
            </div>
          </div>
        )}
      </>
    );
  };

  const extractYouTubeVideoId = (url) => {
    const regExp =
      /^.*((youtu.be\/)|(v\/)|(\/u\/w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
    const match = url?.match(regExp);
    return match && match[7]?.length === 11 ? match[7] : null;
  };

  const extractYouTubeLiveId = (url) => {
    const regExp = /youtube\.com\/live\/([^?&]+)/;
    const match = url?.match(regExp);
    return match ? match[1] : null;
  };

  const handleTriggerHlsApi = async (channelId) => {
    const { success, message } = await updateCustomHlsApi(channelId); // Use object destructuring
    if (success === true) {
      setOpenEdit(false);
      toast.success("Succcessfully Updated Recording");
    } else {
      toast.error("failed to update Recording");
    }
  };

  const editingValidator = () =>
    singleEventData?.name !== eventData?.name ||
    singleEventData?.contact !== eventData?.contact ||
    singleEventData?.template !== eventData?.template ||
    singleEventData?.template_desc !== eventData?.template_desc ||
    singleEventData?.title !== eventData?.title ||
    singleEventData?.venue !== eventData?.venue ||
    singleEventData?.venue_date !== eventData?.venue_date ||
    singleEventData?.venue_time !== eventData?.venue_time ||
    singleEventData?.live_time !== eventData?.live_time ||
    singleEventData?.prlink !== eventData?.prlink ||
    singleEventData?.is_secure !== eventData?.is_secure ||
    singleEventData?.scrolling_mess !== eventData?.scrolling_mess ||
    selectedBanner ||
    eventData?.banner !== singleEventData?.banner ||
    selectedInvitationCard ||
    eventData?.invitation_card !== singleEventData?.invitation_card ||
    selectedInvitationVideo ||
    eventData?.invitation_video !== singleEventData?.invitation_video ||
    selectedOfflineVideo ||
    eventData?.offline_video !== singleEventData?.offline_video ||
    optionData?.option === "file" ||
    optionData?.option !== undefined ||
    eventData?.live_vidlink !== singleEventData?.live_vidlink ||
    hlsVideoUrl !== "" ||
    optionData?.option === "youtubeChannelId" ||
    optionData?.option === "youtubeStreamedUrl" ||
    content?.template_css?.bgColor !== eventData?.template_css?.bgColor ||
    content?.template_css?.bgImage !== eventData?.template_css?.bgImage ||
    content?.template_css?.bgOpacity !== eventData?.template_css?.bgOpacity ||
    content?.template_css?.textColor !== eventData?.template_css?.textColor ||
    content?.template_css?.textFontFamily !==
      eventData?.template_css?.textFontFamily ||
    content?.template_css?.titleFontSize !==
      eventData?.template_css?.titleFontSize ||
    content?.template_css?.titleFontWeight !==
      eventData?.template_css?.titleFontSize ||
    content?.template_css?.headingFontSize !==
      eventData?.template_css?.headingFontSize ||
    content?.template_css?.bgGradient !== eventData?.template_css?.bgGradient ||
    content?.template_css?.template_type !==
      eventData?.template_css?.template_type;

  // content?.ads_disabled !== eventData?.ads_disabled;

  const [refetchedInvitaionVideo, setRefetchInvitaionVideo] = useState(
    eventData.invitation_video !== "" && eventData.invitation_video
  ); // Store the video source
  const [refetchedOfflineVideo, setRefetchedOfflineVideo] = useState(
    eventData.offline_video !== "" && eventData.offline_video
  );

  const extractHlsUrl = (url) => {
    const regExp = /(https?:\/\/[^\s]+\.m3u8)/;
    const match = url?.match(regExp);
    return match ? match[0] : null;
  };

  useEffect(() => {
    setHlsLiveUrl("");
    if (singleEventData?.live_vidlink) {
      setHlsLiveUrl("");
      const hlsLive = extractHlsUrl(singleEventData.live_vidlink);
      if (hlsLive) {
        setHlsLiveUrl(hlsLive);
      }
    }
  }, [singleEventData?.live_vidlink]);

  useEffect(() => {
    if (eventData?.is_secure) {
      setShowPassword(true);
    } else {
      setShowPassword(false);
    }
  }, [eventData?.is_secure]);

  const handleSubmitEdit = async () => {
    if (true) {
      setShowLoader(true);
      if (editingValidator()) {
        setpopCanelTheEditOrConfirm(false);
        setPopUpCancelToEdit(false);
        setOpenEdit(false);
        const formData = new FormData();
        formData.append("id", eventData.id);
        // Append only the fields that have been changed
        if (eventData.name !== singleEventData.name) {
          formData.append("name", eventData.name || "");
        }
        if (eventData.contact !== singleEventData.contact) {
          formData.append("contact", eventData.contact || "");
        }
        // if (eventData.password !== singleEventData.password) {
        //
        // }
        if (eventData.title !== singleEventData.title) {
          formData.append("title", eventData.title || "");
        }
        if (eventData.prlink !== singleEventData.prlink) {
          formData.append("prlink", eventData.prlink);
        }
        if (eventData.venue !== singleEventData.venue) {
          formData.append("venue", eventData.venue || "");
        }
        if (eventData.venue_date !== singleEventData.venue_date) {
          formData.append("venue_date", eventData.venue_date || "");
        }
        if (eventData.venue_time !== singleEventData.venue_time) {
          formData.append("venue_time", eventData.venue_time || "");
        }
        if (singleEventData.live_time !== eventData.live_time) {
          formData.append("live_time", eventData.live_time || "");
        }
        if (eventData.template !== singleEventData.template) {
          formData.append("template", eventData.template || "");
        }
        if (eventData.template_desc !== singleEventData.template_desc) {
          formData.append("template_desc", eventData.template_desc || "");
        }
        if (eventData.live_time !== singleEventData.live_time) {
          formData.append("live_time", eventData?.live_time || "");
        }
        if (eventData?.scrolling_mess !== singleEventData?.scrolling_mess) {
          formData.append("scrolling_mess", eventData?.scrolling_mess);
        }
        const css = {
          bgColor: content?.template_css?.bgColor || "",
          bgImage: content?.template_css?.bgImage || "",
          bgOpacity: content?.template_css?.bgOpacity || null,
          textColor: content?.template_css?.textColor || "",
          textFontFamily: content?.template_css?.textFontFamily || "",
          titleFontSize: content?.template_css?.titleFontSize || "",
          titleFontWeight: content?.template_css?.titleFontWeight || "",
          headingFontSize: content?.template_css?.headingFontSize || "",
          bgGradient:
            content?.template_css?.bgGradient ||
            (!content?.template_css?.bgImage && !content?.template_css?.bgColor
              ? "radial-gradient(circle, #F2C94C, #F2994A)"
              : ""),
          template_type: content?.template_css?.template_type || "default",
        };

        // Serialize the template_css object
        const serializedData = JSON.stringify(css);

        // Append the serialized data to formData
        formData.append("template_css", serializedData);
        if (selectedBanner) {
          formData.append("banner", selectedBanner);
        }
        if (eventData?.banner === null && selectedBanner === null) {
          formData.append("banner", "");
        }
        if (selectedInvitationCard) {
          formData.append("invitation_card", selectedInvitationCard);
        }
        formData.append("ads_disabled", eventData?.ads_disabled);

        if (
          eventData?.invitation_card !== singleEventData?.invitation_card &&
          selectedInvitationCard === null &&
          eventData?.invitation_card === null
        ) {
          formData.append("invitation_card", "");
        }
        if (
          selectedInvitationVideo === null &&
          eventData?.invitation_video === null
        ) {
          formData.append("invitation_video", "");
        }
        if (
          singleChannelData &&
          singleChannelData?.playback_url === eventData?.live_vidlink
        ) {
          if (
            selectedOfflineVideo === null &&
            (eventData?.offline_video === null ||
              eventData?.offline_video === "")
          ) {
            formData.append("offline_video", "");
          }
        }
        if (singleChannelData === null) {
          if (
            selectedOfflineVideo === null &&
            (eventData?.offline_video === null ||
              eventData?.offline_video === "")
          ) {
            formData.append("offline_video", "");
          }
          if (optionData?.option === "youtubeChannelId") {
            formData.append("live_vidlink", optionData?.youtubeLiveUrl);
          }
          if (optionData?.option === "youtubeStreamedUrl") {
            formData.append("live_vidlink", optionData?.youtubeStreamedUrl);
          }
          if (hlsVideoUrl !== "") {
            formData.append("live_vidlink", hlsVideoUrl);
          }
          if (
            eventData?.live_vidlink !== singleEventData?.live_vidlink &&
            eventData?.live_vidlink !== ""
          ) {
            formData.append("live_vidlink", eventData?.live_vidlink);
          }
          if (
            (eventData?.live_vidlink !== singleEventData?.live_vidlink &&
              eventData?.live_vidlink === "" &&
              optionData &&
              Object.keys(optionData).length === 0 &&
              hlsVideoUrl === "") ||
            optionData?.option === "file"
          ) {
            formData.append("live_vidlink", "");
          }
          if (
            eventData?.live_vidlink === "" &&
            singleEventData?.live_vidlink === "" &&
            optionData &&
            Object.keys(optionData).length === 0 &&
            hlsVideoUrl === ""
          ) {
            formData.append("live_vidlink", "");
          }
        }
        if (eventData.is_secure !== singleEventData.is_secure) {
          if (eventData.is_secure) {
            if (!eventData.password) {
              return toast.warning("Password can't be empty");
            }
            if (eventData.password.length < 4) {
              return toast.warning(
                "Minimum length of the password should be at least 4"
              );
            }
            formData.append("is_secure", eventData.is_secure);
            formData.append("password", eventData.password);
          } else {
            formData.append("is_secure", eventData.is_secure);
            formData.append("password", "");
          }
        }

        try {
          formData.append("prlink", selectedRowId);
          await updateEventData(
            formData,
            optionData,
            selectedInvitationVideo,
            selectedOfflineVideo
          );
          setLoadingView(false);
          setProgress(true);
          setPopUpCancelToEdit(false);
          setOpenEdit(false);
          setFetchedPassword("");
          getAllEvents();
          // setShowLoader(false);
          setTextEditor({
            userName: false,
            contact: false,
            eventName: false,
            template: false,
            venue: false,
            venueDate: false,
            venueTime: false,
          });
          setContent({
            userName: "",
            contact: "",
            eventName: "",
            template: "",
            venue: "",
            venue_date: null,
            venue_time: null,
            template_desc: "",
          });
          setPopUpCancelToEdit(false);
          setpopCanelTheEditOrConfirm(false);
          setOpenEdit(false);
          setSelectedBanner(null);
          setSelectedInvitationCard(null);
          setSelectedInvitationVideo(null);
          setSelectedOfflineVideo(null);
          if (
            (optionData && !optionData.file) ||
            selectedInvitationVideo !== null ||
            selectedOfflineVideo !== null
          ) {
            navigate("/dashboard");
          }
        } catch (error) {
          setLoadingView(false);
          console.error("Error updating event data:", error);
        } finally {
          setLoadingView(false);
          if (
            selectedOfflineVideo !== null ||
            selectedInvitationVideo !== null
          ) {
            navigate("/dashboard");
          }
        }
      }
    } else if (
      textEditor.userName &&
      textEditor.venue &&
      textEditor.contact &&
      textEditor.template &&
      textEditor.title &&
      textEditor.venueDate &&
      textEditor.venueTime
    ) {
      toast.error("Please Save or Cancel Edited sections");
      setLoadingView(false);
    }
  };

  //text editor
  const renderTextEditor = (inputType) => {
    return (
      <input
        style={{ width: "100%", overflowX: "auto" }}
        className="para-styling"
        value={content?.[inputType]}
        onChange={(event) => {
          const newContent = event.target.value;
          if (inputType === "venue") {
            if (newContent.length <= 100) {
              setContent({ ...content, venue: newContent });
            }
          } else {
            setContent({ ...content, [inputType]: newContent });
          }
        }}
      />
    );
  };

  // hls player options
  const playerOptions = {
    autoplay: true,
    controls: true,
    fluid: true,
    preload: "auto",
    sources: [
      {
        src: hlsLiveUrl || "",
        type: "application/x-mpegURL",
      },
    ],
  };

  const initializePlayer = () => {
    if (playerRef.current) {
      playerRef.current = null;
    }
    if (videoRef.current && !playerRef.current) {
      playerRef.current = videojs(videoRef.current, playerOptions, () => {
        setLoadingView(false);
      });

      let retryCount = 0; // Initialize retry counter

      // Add error handling
      playerRef.current.on("error", (error) => {
        console.error("Video.js Error:", error);

        if (error.code === 3) {
          // MEDIA_ERR_DECODE
          if (retryCount < 3) {
            retryCount++; // Increment retry counter
            setTimeout(() => {
              playerRef.current.src({
                type: "application/x-mpegURL",
                src: hlsLiveUrl,
              });
            }, 5000);
          } else {
            setError("Event stream is not live.");
          }
        } else {
          if (retryCount < 3) {
            setTimeout(() => {
              playerRef.current.src({
                type: "application/x-mpegURL",
                src: hlsLiveUrl,
              });
              retryCount++; // Increment retry counter
            }, 3000);
          } else {
            setError("Cant Access Stream!! Please Reload the Page");
            // window.location.reload()
          }
        }
      });
      setError("");
    }
  };

  useEffect(() => {
    setTimeout(initializePlayer, 1000);

    return () => {
      if (playerRef.current) {
        playerRef.current.dispose();
        playerRef.current = null;
      }
    };
  }, [hlsLiveUrl]);

  //
  const previewUrl = (file) => URL.createObjectURL(file);

  //
  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  //file setter function
  const handleFileChange = (setter) => (event) => {
    const file = event.target.files[0];
    setter(file);
  };

  const handleFileDelete = (setter) => (event) => {
    setter(null);
    if (setter === setSelectedBanner) {
      setEventData({
        ...eventData,
        banner: null,
      });
    }
    if (setter === setSelectedInvitationCard) {
      setEventData({
        ...eventData,
        invitation_card: null,
      });
    }
    if (setter === setSelectedInvitationVideo) {
      setEventData({
        ...eventData,
        invitation_video: null,
      });
    }
    if (setter === setSelectedOfflineVideo) {
      setEventData({
        ...eventData,
        offline_video: null,
      });
    }
  };
  // const handleImagesChange = (event) => {
  //   const files = Array.from(event.target.files);
  //   setSelectedImages((prevImages) => [...prevImages, ...files]);
  // };

  const handleImagesChange = async (event) => {
    const files = Array.from(event.target.files);
    const newFiles = files.filter((file) => {
      return !selectedImages.some(
        (selectedImage) => selectedImage.name === file.name
      );
    });
    setSelectedImages((prevImages) => [...prevImages, ...newFiles]);
    await postImagesToAnotherAPI(newFiles);
  };

  const handleGetChannelData = async (id) => {
    await getChannelData(id);
  };

  const postImagesToAnotherAPI = async (images) => {
    const formData = new FormData();
    images.forEach((image, index) => {
      formData.append(`images`, image);
    });

    try {
      setShowLoader(true);
      const response = await axios.post(
        `${process.env.REACT_APP_MEDIA_URL}/api/events/add_event_image/${singleEventData.id}/`,
        formData
      );

      if (response.status === 200) {
        // Assuming response.data.images contains the newly uploaded images
        const newUploadedImages = response.data;

        // Filter out duplicates based on image names or ids
        const uniqueNewImages = newUploadedImages.filter(
          (newImage) =>
            !eventData.images.some(
              (existingImage) => existingImage.image === newImage.image
            )
        );

        // Merge existing and unique new images into the eventData
        setEventData((prevEventData) => ({
          ...prevEventData,
          images: [...prevEventData.images, ...uniqueNewImages],
        }));

        // Optionally clear the selectedImages after successful upload
        setSelectedImages([]);
        setShowLoader(false);
      }
    } catch (error) {
      setShowLoader(false);
      console.error("Error uploading images:", error);
    }
  };

  const deleteImage = async (imageToDelete) => {
    setShowLoader(true);
    try {
      const data = {
        event_id: singleEventData?.id,
        image_id: imageToDelete,
      };

      const res = await axios.post(
        `${process.env.REACT_APP_MEDIA_URL}/api/events/delete_event_image/`,
        data
      );

      if (res.status === 200) {
        setEventData({
          ...eventData,
          images: eventData.images.filter(
            (image) => image?.id !== imageToDelete
          ),
        });
        setSelectedImages((prevImages) =>
          prevImages.filter((image) => image?.id !== imageToDelete)
        );
        setShowLoader(false);
      } else {
        setShowLoader(false);
        console.error("Failed to delete image:", res.statusText);
      }
    } catch (error) {
      setShowLoader(false);
      console.error("Error deleting image:", error);
    }
  };

  const handleOptionChange = (e) => {
    setSelectedOption(e.target.value);
  };

  // Function to close edit modal
  const handleCloseEdit = () => {
    if (
      // !textEditor.userName &&
      // !textEditor.venue &&
      // !textEditor.contact &&
      // !textEditor.title &&
      // !textEditor.venueDate &&
      // !textEditor.venueTime &&
      // !textEditor.template
      true
    ) {
      if (editingValidator()) {
        setpopCanelTheEditOrConfirm(true);
      } else {
        setpopCanelTheEditOrConfirm(false);
        setTextEditor({
          userName: false,
          contact: false,
          eventName: false,
          template: false,
          venue: false,
          venueDate: false,
          venueTime: false,
        });
        setOpenEdit(false);
        setSelectedRowId(null);
        if (playerRef.current) {
          playerRef.current.dispose();
          playerRef.current = null;
        }
        navigate("/dashboard");
      }
    } else {
      toast.error("Please Save or Cancel Edited section");
    }

    // setHlsLiveUrl("");//due to this set after closing edit event then hls link is erasing...

    // Dispose of player on modal close
  };

  const handlerEditEvent = () => {
    if (
      // !textEditor.userName &&
      // !textEditor.venue &&
      // !textEditor.contact &&
      // !textEditor.title &&
      // !textEditor.venueDate &&
      // !textEditor.venueTime
      true
    ) {
      if (editingValidator()) {
        setPopUpCancelToEdit(true);
      } else {
        setTextEditor({
          userName: false,
          contact: false,
          eventName: false,
          template: false,
          venue: false,
          venueDate: false,
          venueTime: false,
        });
        setPopUpCancelToEdit(false);
        setOpenEdit(false);
        setSelectedRowId(null);
        if (playerRef.current) {
          playerRef.current.dispose();
          playerRef.current = null;
        }
      }
    } else {
      toast.error("Please Save or Cancel Edited section");
    }

    // setHlsLiveUrl("");//due to this set after closing edit event then hls link is erasing...

    // Dispose of player on modal close
  };
  const handleEditSelection = () => {
    setEventData({
      ...eventData,
      is_secure: !eventData?.is_secure,
    });
  };
  const handleEditPassword = (e) => {
    const newpass = e.target.value;
    setEventData({
      ...eventData,
      password: newpass,
    });
  };

  const handleCheckboxChange = () => {
    const newCheckedState = !isChecked;
    setIsChecked(newCheckedState);

    setEventData((prev) => ({
      ...prev,
      is_secure: !eventData?.is_secure,
    }));

    // resetForm();
  };

  const handleSelectedOptionChange = async (event) => {
    const value = event.target.value;
    setSelectedPasswordoption(value);
    resetForm();

    if (value === "generatePassword") {
      try {
        const response = await axiosInstance.get(
          `${process.env.REACT_APP_MEDIA_URL}/api/events/create_Secret/`
        );
        setFetchedPassword(response.data.message);
        setEventData((prev) => ({
          ...prev,
          password: response.data.message,
        }));
      } catch (error) {
        setApiError("Failed to fetch password. Please try again.");
      }
    }
  };

  const handlePasswordChange = (event) => {
    const value = event.target.value;
    setEventData((prev) => ({
      ...prev,
      password: value,
    }));
    setFetchedPassword(value);
    validatePassword(value);
  };

  const validatePassword = (value) => {
    if (value.length > 0 && value.length <= 8) {
      setMinimumPassword("It's a Valid Password");
    } else if (value.length === 0) {
      setMinimumPassword("");
    } else {
      setPasswordError("Password must be at least 8 characters long.");
    }
  };

  const resetForm = () => {
    setEventData((prev) => ({
      ...prev,
      password: "",
    }));
    setPasswordError("");
    setApiError("");
    setApiSuccess("");
    setFetchedPassword("");
  };

  const renderEditPassword = () => {
    return (
      <div>
        {singleEventData?.is_secure ? (
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(eventData?.is_secure)}
                  onChange={handleEditSelection}
                />
              }
              label="Protect with password"
              className="label-text-style"
            />
            {showPassword && (
              <Box>
                <TextField
                  type="text"
                  value={eventData?.password}
                  onChange={(e) => {
                    const password = e.target.value;
                    // Prevent empty value or too long
                    if (password.length > 0 && password.length >= 8) {
                      handleEditPassword(e);
                    }
                  }}
                  fullWidth
                  inputProps={{ maxLength: 8, minLength: 1 }}
                />
                {apiError && <Typography color="error">{apiError}</Typography>}
              </Box>
            )}
          </Box>
        ) : (
          <Box>
            <FormControlLabel
              control={
                <Checkbox
                  checked={Boolean(eventData?.is_secure)}
                  onChange={handleCheckboxChange}
                />
              }
              label="Protect with password"
              className="label-text-style"
            />

            {eventData?.is_secure && (
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <FormControl
                  component="fieldset"
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "20px",
                  }}
                >
                  <FormLabel component="div">Select an option</FormLabel>
                  <RadioGroup
                    value={selectedPasswordOption}
                    onChange={handleSelectedOptionChange}
                    sx={{
                      height: "40px",
                      display: "flex",
                    }}
                  >
                    <FormControlLabel
                      value="generatePassword"
                      control={<Radio />}
                      label="Generate password"
                    />
                    <FormControlLabel
                      value="customPassword"
                      control={<Radio />}
                      label="Set custom password"
                    />
                  </RadioGroup>
                </FormControl>

                {selectedPasswordOption === "generatePassword" && (
                  <Box>
                    <TextField
                      type="text"
                      placeholder="Auto-generated password"
                      value={fetchedPassword}
                      fullWidth
                      slotProps={{ readOnly: true }}
                    />
                    {apiError && (
                      <Typography color="error">{apiError}</Typography>
                    )}
                  </Box>
                )}

                {selectedPasswordOption === "customPassword" && (
                  <Box>
                    <div
                      style={{
                        position: "relative",
                      }}
                    >
                      <TextField
                        type={!passwordVisibilty ? "text" : "password"}
                        placeholder="Enter password"
                        value={fetchedPassword}
                        onChange={handlePasswordChange}
                        fullWidth
                        error={Boolean(passwordError)}
                        helperText={passwordError}
                        inputProps={{ maxLength: 8, minLength: 1 }}
                      />
                      <button
                        style={{
                          border: "none",
                          background: "transparent",
                          cursor: "pointer",
                          position: "absolute",
                          right: 10,
                          top: "50%",
                          transform: "translateY(-50%)",
                        }}
                        onClick={() => {
                          setPasswordVisibility(!passwordVisibilty);
                        }}
                      >
                        {passwordVisibilty ? (
                          <MdVisibility size={25} />
                        ) : (
                          <MdVisibilityOff size={25} />
                        )}
                      </button>
                    </div>

                    {minimumPassword && (
                      <p style={{ color: "green", marginTop: "2px" }}>
                        {minimumPassword}
                      </p>
                    )}

                    {apiError && (
                      <Typography color="error">{apiError}</Typography>
                    )}
                    {apiSuccess && (
                      <Typography color="success.main">{apiSuccess}</Typography>
                    )}
                  </Box>
                )}
              </Box>
            )}
          </Box>
        )}
      </div>
    );
  };

  const renderAds = () => (
    <div className="ads-enable-container">
      <FormControlLabel
        control={
          <Checkbox
            disabled={
              userData && userData?.is_admin === true
                ? false
                : Boolean(eventData?.ads_disabled)
            }
            checked={Boolean(content?.ads_disabled)}
            onChange={() =>
              setContent({
                ...content,
                ads_disabled: !Boolean(content?.ads_disabled),
              })
            }
          />
        }
        sx={{ marginBottom: "0px" }}
        label="Disable Advertisements?"
        className="label-text-style"
      />
      {userData && userData.is_admin === false && (
        <>
          {userCredits && userCredits?.ads_credits === 0 && (
            <p style={{ color: "red", fontSize: "12px" }}>
              Dont have enough credits{" "}
              <a href="https://www.eventsonlife.com/payments" target="_blank">
                Buy Now
              </a>
            </p>
          )}
        </>
      )}
    </div>
  );

  const handleToCancelTheEvent = () => {
    setOpenEdit(false);
    setPopUpCancelToEdit(false);
    setpopCanelTheEditOrConfirm(false);
    setSelectedBanner(null);
    setSelectedInvitationCard(null);
    setSelectedInvitationVideo(null);
    setSelectedOfflineVideo(null);
    navigate("/dashboard");
  };

  const handleEventEditCancel = () => {
    setPopUpCancelToEdit(false);
    setOpenEdit(true);
  };

  const fileInvitationVideoUploadFiles3 = async (file, type, apiUrl) => {
    if (!file) return;
    setUploading(true);
    setError(null);

    const uploadWithProgress = (url, file) => {
      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open("PUT", url, true);

        // Update progress
        xhr.upload.onprogress = function (event) {
          if (event.lengthComputable) {
            const progress = (event.loaded / event.total) * 100;

            setUploadingVideo((prevState) => ({
              ...prevState,

              ...(selectedInvitationVideo
                ? { uploadingInvitation: progress }
                : {}),
            }));
          }
        };

        xhr.onload = function () {
          if (xhr.status === 200) {
            resolve(xhr.response);
          } else {
            reject(new Error("Error uploading the file"));
            toast.error("Error While uploading Invitation Video");
          }
        };

        xhr.onerror = function () {
          reject(new Error("Network error"));
          toast.error("Network error");
        };

        xhr.send(file);
      });
    };

    try {
      // Modify the file name
      const encodedFileName = encodeURIComponent(file.name);
      const modifiedFileName = modifyFileName(encodedFileName);

      // Create a new File object with the modified name
      const modifiedFile = new File([file], modifiedFileName, {
        type: file.type,
      });

      // Request a presigned URL from the backend with the modified file name
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/events/file_upload_link/`,
        {
          params: {
            file_name: modifiedFileName, // Use the modified file name (not encoded)
            file_type: modifiedFile.type,
            id: singleEventData.id,
            type,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );

      const { link, key } = response.data;

      // Upload the file to the presigned URL with progress tracking
      await uploadWithProgress(link, modifiedFile);

      // After the upload is successful, send the final link to your API
      const options = {
        id: singleEventData.id,
        finalLink: key,
      };

      const postResponse = await axios.post(apiUrl, options, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });

      if (postResponse.status === 200) {
        setUploading(false); // Set uploading to false after everything is done
        toast.success("Invitation Video successfully Uploaded!");
        setLoadingView(false);
      }
    } catch (err) {
      console.error("Upload error:", err);
      setError(err.response?.data || err.message);
      toast.error(
        "Error uploading file: " + (err.response?.data || err.message)
      );
    } finally {
      setUploading(false); // Ensure uploading state is set to false even if there's an error
    }
  };

  const fileOfflineVideoUploadFiles3 = async (file, type, apiUrl) => {
    if (!file) return;
    setUploading(true);
    setError(null);

    const uploadWithProgress = (url, file) => {
      return new Promise((resolve, reject) => {
        const xhr = new XMLHttpRequest();
        xhr.open("PUT", url, true);

        // Update progress
        xhr.upload.onprogress = function (event) {
          if (event.lengthComputable) {
            const progress = (event.loaded / event.total) * 100;

            setUploadingVideo((prevState) => ({
              ...prevState,

              ...(selectedOfflineVideo || optionData?.option === "file"
                ? { uploadingOfflineVideo: progress }
                : {}),
            }));
          }
        };

        xhr.onload = function () {
          if (xhr.status === 200) {
            resolve(xhr.response);
          } else {
            reject(new Error("Error uploading the file"));
            toast.error("Error Whiel uploading Offline Video");
          }
        };

        xhr.onerror = function () {
          reject(new Error("Network error"));
          toast.error("Network error");
        };

        xhr.send(file);
      });
    };

    try {
      // Modify the file name
      const encodedFileName = encodeURIComponent(file.name);
      const modifiedFileName = modifyFileName(encodedFileName);

      // Create a new File object with the modified name
      const modifiedFile = new File([file], modifiedFileName, {
        type: file.type,
      });

      // Request a presigned URL from the backend with the modified file name
      const response = await axios.get(
        `${process.env.REACT_APP_BASE_URL}/events/file_upload_link/`,
        {
          params: {
            file_name: modifiedFileName, // Use the modified file name (not encoded)
            file_type: modifiedFile.type,
            id: singleEventData.id,
            type,
          },
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        }
      );

      const { link, key } = response.data;

      // Upload the file to the presigned URL with progress tracking
      await uploadWithProgress(link, modifiedFile);

      // After the upload is successful, send the final link to your API

      const options = {
        id: singleEventData.id,
        finalLink: key,
      };

      const postResponse = await axios.post(apiUrl, options, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      });

      if (postResponse.status === 200) {
        setUploading(false); // Set uploading to false after everything is done
        setLoadingView(false);
        toast.success("Offline Video successfully uploaded !");
      }
    } catch (err) {
      console.error("Upload error:", err);
      setError(err.response?.data || err.message);
      toast.error(
        "Error uploading file: " + (err.response?.data || err.message)
      );
    } finally {
      setUploading(false); // Ensure uploading state is set to false even if there's an error
    }
  };

  const handleEventEditConfirmation = () => {
    setBtnLoading(2);
    setPopUpCancelToEdit(false);
    setPopUpCancelToEdit(!popCanelToEdit);
    if (selectedInvitationVideo) {
      setShowLoader(true);
      const apiUrl = `${process.env.REACT_APP_MEDIA_URL}/api/events/inviVideo_chunk/`;
      fileInvitationVideoUploadFiles3(
        selectedInvitationVideo,
        "invitation_video",
        apiUrl
      );
    }
    if (optionData?.option === "file" || selectedOfflineVideo) {
      setShowLoader(true);
      const apiUrl = `${process.env.REACT_APP_MEDIA_URL}/api/events/offline_chunk/`;
      fileOfflineVideoUploadFiles3(
        optionData?.file || selectedOfflineVideo,
        "offline_video",
        apiUrl
      );
    }
    setEventData((prevData) => {
      const newEventData = {
        ...prevData,
        name: content.userName,
        contact: content.contact,
        title: content.eventName,
        template: content.template,
        template_desc: content.template_desc,
        venue: content.venue,
        venue_date: content.venue_date,
        venue_time: content.venue_time,
        live_time: content.live_time,
      };
      if (
        !selectedOfflineVideo &&
        (!selectedInvitationVideo || !optionData?.option === "file")
      ) {
        handleSubmitEdit();
      }
      return newEventData;
    });
  };

  const modifyFileName = (fileName) => {
    // Replace spaces with hyphens and remove special characters except - . _
    return fileName
      .toLowerCase() // Optional: convert to lowercase
      .replace(/\s+/g, "-") // Replace spaces with hyphens
      .replace(/[^a-z0-9-_.]/g, "") // Remove any special characters except - . _
      .replace(/-+/g, "-"); // Replace multiple hyphens with a single hyphen
  };

  const renderEdtiedContents = () => (
    <DialogContent>
      <p style={{ marginBottom: "5px", textDecoration: "underline" }}>
        Confirm List of Changes
      </p>
      <ol>
        {singleEventData?.name !== eventData?.name && (
          <div className="changesOfList">
            Username has been Changed from{" "}
            <span style={{ color: "green", margin: "0px 5px" }}>
              {singleEventData?.name}
            </span>
            to
            <span style={{ margin: "0px 5px" }}>{eventData?.name}</span>
          </div>
        )}

        {singleEventData?.contact !== eventData?.contact && (
          <div className="changesOfList">
            {" "}
            Contact Number has been Changed from{" "}
            <span style={{ color: "green", margin: "0px 5px" }}>
              {singleEventData?.contact}
            </span>{" "}
            to <span style={{ margin: "0px 5px" }}>{eventData?.contact}</span>
          </div>
        )}

        {singleEventData?.template !== eventData?.template && (
          <div style={{ display: "flex", mmarginTop: "10px" }}>
            Event Type has been Changed from{" "}
            <span style={{ color: "green", margin: "0px 5px" }}>
              {singleEventData?.template}
            </span>{" "}
            to <span style={{ margin: "0px 5px" }}>{eventData?.template}</span>
          </div>
        )}
        {singleEventData?.template_desc !== eventData?.template_desc && (
          <div className="changesOfList">
            Template Description has been Changed from{" "}
            <span style={{ color: "green", margin: "0px 5px" }}>
              {singleEventData?.template_desc}
            </span>{" "}
            to{" "}
            <span style={{ margin: "0px 5px" }}>
              {eventData?.template_desc}
            </span>
          </div>
        )}

        {singleEventData?.title !== eventData?.title && (
          <div className="changesOfList">
            Event Name has been Changed from{" "}
            <span style={{ color: "green", margin: "0px 5px" }}>
              {singleEventData?.title}
            </span>{" "}
            to <span style={{ margin: "0px 5px" }}>{eventData?.title}</span>
          </div>
        )}

        {content.prlink && singleEventData?.prlink !== eventData?.prlink && (
          <div className="changesOfList">
            {" "}
            Link Changed from{" "}
            <span style={{ color: "green" }}>
              {singleEventData?.prlink}
            </span> to <span>{eventData?.prlink}</span>
          </div>
        )}

        {singleEventData?.venue !== eventData?.venue && (
          <div className="changesOfList">
            Venue has been Changed from{" "}
            <span style={{ color: "green", margin: "0px 5px" }}>
              {singleEventData?.venue}
            </span>{" "}
            to <span style={{ margin: "0px 5px" }}>{eventData?.venue}</span>
          </div>
        )}

        {singleEventData?.venue_date !== eventData?.venue_date && (
          <div className="changesOfList">
            Venue Date has been Changed from{" "}
            <span style={{ color: "green" }}>
              {singleEventData?.venue_date}
            </span>{" "}
            to <span>{eventData?.venue_date}</span>
          </div>
        )}
        {singleEventData?.venue_time !== eventData?.venue_time && (
          <div className="changesOfList">
            Venue Time has been Changed from{" "}
            <span style={{ color: "green" }}>
              {singleEventData?.venue_time}
            </span>{" "}
            to {eventData?.venue_time}
          </div>
        )}
        {singleEventData?.live_time !== eventData?.live_time && (
          <div className="changesOfList">
            Venue Live Time has been Changed from{" "}
            <span style={{ color: "green" }}>{singleEventData?.live_time}</span>{" "}
            {eventData.live_time}
          </div>
        )}
        {singleEventData?.scrolling_mess !== eventData?.scrolling_mess && (
          <div className="changesOfList">
            Scrolling Mess has been Changed from{" "}
            <span style={{ color: "green", margin: "0px 3px" }}>
              {singleEventData?.scrolling_mess}{" "}
            </span>{" "}
            to{" "}
            <span style={{ margin: "0px 3px" }}>
              {eventData?.scrolling_mess}
            </span>
          </div>
        )}
        {(content?.template_css?.bgColor !== eventData?.template_css?.bgColor ||
          content?.template_css?.bgImage !== eventData?.template_css?.bgImage ||
          content?.template_css?.bgOpacity !==
            eventData?.template_css?.bgOpacity ||
          content?.template_css?.textColor !==
            eventData?.template_css?.textColor ||
          content?.template_css?.textFontFamily !==
            eventData?.template_css?.textFontFamily ||
          content?.template_css?.titleFontSize !==
            eventData?.template_css?.titleFontSize ||
          content?.template_css?.titleFontWeight !==
            eventData?.template_css?.titleFontWeight ||
          content?.template_css?.headingFontSize !==
            eventData?.template_css?.headingFontSize ||
          content?.template_css?.bgGradient !==
            eventData?.template_css?.bgGradient ||
          content?.template_css?.template_type !==
            eventData?.template_css?.template_type) && (
          <p className="changesOfList">You Have Changed Template Settings</p>
        )}

        {(selectedBanner instanceof File ||
          eventData.banner !== singleEventData?.banner) && (
          <p style={{ marginTop: "10px" }}>You Have Changed the Event Banner</p>
        )}

        {(selectedInvitationCard instanceof File ||
          eventData.invitation_card !== singleEventData?.invitation_card) && (
          <p style={{ marginTop: "10px" }}>You Have Changed Invitation Card</p>
        )}

        {selectedInvitationVideo instanceof File ||
        eventData?.invitation_video !== singleEventData?.invitation_video ? (
          <p style={{ marginTop: "10px" }}>You Have Changed Invitation Video</p>
        ) : null}

        {selectedOfflineVideo instanceof File ||
        eventData.live_vidlink !== singleEventData?.live_vidlink ||
        eventData.offline_video !== singleEventData?.offline_video ||
        hlsVideoUrl !== "" ||
        optionData?.option === "youtubeChannelId" ||
        optionData?.option === "youtubeStreamedUrl" ||
        optionData?.option === "file" ? (
          <div>
            <p style={{ marginTop: "10px" }}>You Have Changed Live Video</p>
          </div>
        ) : null}

        {eventData.is_secure !== singleEventData?.is_secure && (
          <div className="changesOfList">
            Event protection has been{" "}
            {eventData.is_secure ? (
              <span style={{ color: "green", margin: "0px 0px 0px 2px" }}>
                Enabled
              </span>
            ) : (
              <span style={{ color: "red", margin: "0px 0px 0px 2px" }}>
                Disabled
              </span>
            )}
          </div>
        )}
        {/* {content?.ads_disabled !== eventData?.ads_disabled && (
          <p className="changesOfList">You Have Changed Advertisement Option</p>
        )} */}
      </ol>
    </DialogContent>
  );

  const renderCancelSection = () => (
    <Dialog fullWidth open={popCanelTheEditOrConfirm}>
      <DialogTitle
        sx={{ textAlign: "center", marginBottom: " 10px", fontWeight: "700" }}
      >
        Cancel Edited Inputs
      </DialogTitle>
      {renderEdtiedContents()}
      <DialogActions
        sx={{
          marginBottom: "20px",
          display: "flex",
          justifyContent: "space-around",
        }}
      >
        <Button
          variant="outlined"
          color="danger"
          sx={{ color: "red" }}
          onClick={handleToCancelTheEvent}
        >
          Cancel
        </Button>

        <Button
          variant="outlined"
          color="success"
          sx={{ color: "green" }}
          onClick={handleEventEditConfirmation}
          style={{ marginLeft: "auto" }}
        >
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );

  const renderConfirmSection = () => {
    return (
      <Dialog fullWidth open={popCanelToEdit}>
        <DialogTitle
          sx={{ textAlign: "center", marginBottom: " 10px", fontWeight: "700" }}
        >
          Confirmation Your Edited Section
        </DialogTitle>
        {renderEdtiedContents()}
        <DialogActions
          sx={{
            marginBottom: "20px",
            display: "flex",
            justifyContent: "space-around",
          }}
        >
          <Button
            sx={{ color: "red" }}
            variant="outlined"
            color="danger"
            onClick={handleEventEditCancel}
          >
            Cancel
          </Button>

          <Button
            variant="outlined"
            color="success"
            sx={{ color: "green", marginLeft: "auto" }}
            onClick={handleEventEditConfirmation}
            disabled={btnLoading === 1}
          >
            {btnLoading === 0 ? "Save" : <Circle />}
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

  const renderNavigation = (mobile) => {
    const footerStyle = mobile
      ? { borderTopLeftRadius: "30px", borderTopRightRadius: "30px" }
      : {};

    function capitalizeFirstLetter(str) {
      return str
        ?.split(" ") // Split the string into words by space
        ?.map(
          (word) =>
            word?.charAt(0)?.toUpperCase() + word?.slice(1).toLowerCase()
        ) // Capitalize first letter of each word
        ?.join(" "); // Join the words back together with space
    }

    return (
      <>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            backgroundColor: "#333",
            // ...footerStyle,
            color: content?.template_css?.textColor
              ? content?.template_css?.textColor
              : "black",
            fontFamily: content?.template_css?.textFontFamily
              ? content?.template_css?.textFontFamily
              : "Courier New",
          }}
        >
          <h1
            style={{
              fontSize: content?.template_css?.titleFontSize
                ? content?.template_css?.titleFontSize
                : "30px",
              fontWeight: content?.template_css?.titleFontWeight
                ? content?.template_css?.titleFontWeight
                : "Normal",
              fontFamily: content?.template_css?.textFontFamily
                ? content?.template_css?.textFontFamily
                : "Courier New",
              // background:
              //   "linear-gradient(45deg, rgb(0, 0, 0), rgb(205, 205, 205), rgb(217, 164, 8), rgb(255, 173, 0)) text",
              // color: "transparent",
              // fontWeight: "bold",
              // textAlign: "center",
              // fontFamily: "cursive",
            }}
          >
            {capitalizeFirstLetter(eventData?.title)}
          </h1>
          {eventData?.scrolling_mess &&
            !optionData?.option &&
            !hlsVideoUrl &&
            eventData?.live_vidlink === "" &&
            eventData?.offline_video === "" && (
              <div style={{ width: "100%" }}>
                <div style={{ width: "100%" }} className="scrollingmess">
                  <div
                    style={{ width: "100%" }}
                    className={styles.advertisement}
                  >
                    {eventData?.scrolling_mess}
                  </div>
                </div>
              </div>
            )}
        </div>
      </>
    );
  };

  const renderSpecificBannerView = () => {
    switch (singleEventData.template) {
      case "Birthday":
      case "Saree Ceremony":
        return "https://res.cloudinary.com/dmhx6frzp/image/upload/v1728558003/5395531_fkgvby.jpg";
      case "Marriage":
        return "https://res.cloudinary.com/dmhx6frzp/image/upload/v1728635524/japanese-paper-textures_vhxore.jpg";
      case "Cultural Event":
        return "https://res.cloudinary.com/dmhx6frzp/image/upload/v1728636985/frame-with-flowers-it-that-says-golden_nkloxz.jpg";
      case "Family Reunion":
      case "Party":
        return "";
      default:
        return CoupleFrame;
    }
  };

  const renderBannerImage = (preview) => {
    return (
      <>
        {
          selectedBanner ? (
            <div>
              <h1
                style={{
                  color: content?.template_css?.textColor
                    ? content?.template_css?.textColor
                    : "black",
                  fontFamily: content?.template_css?.textFontFamily
                    ? content?.template_css?.textFontFamily
                    : "Courier New",
                  fontSize: content?.template_css?.headingFontSize
                    ? content?.template_css?.headingFontSize
                    : "20px",
                }}
                className="editingInvitationHeading"
                // className={styles2.bannerHeadingT2}
              >
                Banner
              </h1>
              <div
                id="home"
                style={{
                  width: "100%",
                  margin: "auto",
                  backgroundSize: "cover",
                }}
                // className={styles2.bannerSectionT2}
              >
                <img
                  src={previewUrl(selectedBanner)}
                  alt="Banner Preview"
                  style={{
                    borderRadius: "8px",
                    width: "80%",
                    margin: "auto",
                    height: "inherit",
                  }}
                />
              </div>
            </div>
          ) : (
            eventData?.banner !== null && (
              <div>
                <h1
                  style={{
                    color: content?.template_css?.textColor
                      ? content?.template_css?.textColor
                      : "black",
                    fontFamily: content?.template_css?.textFontFamily
                      ? content?.template_css?.textFontFamily
                      : "Courier New",
                    fontSize: content?.template_css?.headingFontSize
                      ? content?.template_css?.headingFontSize
                      : "20px",
                  }}
                  className="editingInvitationHeading"
                  // className={styles2.bannerHeadingT2}
                >
                  Banner
                </h1>
                <div
                  style={{
                    width: "100%",
                    margin: "auto",
                    backgroundSize: "cover",
                  }}
                  // className={styles2.bannerSectionT2}
                >
                  <img
                    style={{
                      borderRadius: "8px",
                      width: "80%",
                      height: "inherit",
                    }}
                    src={eventData?.banner}
                    alt="Frame"
                  />
                </div>
              </div>
            )
          )
          //  : (
          //   <div className={styles.frameContainer}>
          //     <img
          //       src={CoupleFrame}
          //       alt="Frame"
          //       style={{ width: "70%" }}
          //       className={styles.frameImage}
          //     />
          //     <div
          //       style={{
          //         fontSize: `${
          //           (eventData?.title && eventData?.title.length > 20) ||
          //           eventData?.title.length < 40
          //             ? "20px"
          //             : eventData?.title.length >= 40
          //             ? "15px"
          //             : "20px"
          //         } `,
          //       }}
          //       className={styles.frameContent}
          //     >
          //       {(eventData?.title)}
          //     </div>
          //   </div>
          // )
        }
      </>

      // (
      //   <div className={styles.frameContainer}>
      //     {/* <img src={CoupleFrame} alt="Frame" className={styles.frameImage} /> */}
      //     <img
      //       src="https://res.cloudinary.com/dmhx6frzp/image/upload/v1728558003/5395531_fkgvby.jpg"
      //       alt="Frame"
      //       className={styles.frameImage}
      //     />
      //     {/* <p className={styles.frameContent}>
      //     Danial <br />& <br />
      //     Floes
      //     </p> */}
      //     <p className={styles.frameContent}>{(eventData?.title ? eventData?.title : singleEventData?..title)}</p>
      //   </div>
      // )
      //   }
      // </>
    );
  };

  // Handle video error or black screen by forcing a refresh
  const handleError = useCallback(() => {
    // You can add a timestamp or unique query param to force reload
    setRefetchInvitaionVideo(
      (prevSrc) => `${prevSrc}?t=${new Date().getTime()}`
    ); // Force refresh by adding a timestamp
  }, []);

  const renderInvitationSection = () => {
    function formatTime(timeString) {
      if (!timeString) return "Invalid time"; // Handle undefined or empty strings

      const [hour, minute] = timeString.split(":");
      const hourInt = parseInt(hour, 10);
      const isPM = hourInt >= 12;

      const formattedHour = hourInt % 12 === 0 ? 12 : hourInt % 12; // Handle 12-hour clock
      const period = isPM ? "PM" : "AM";

      return `${formattedHour.toString().padStart(2, "0")}:${minute} ${period}`;
    }
    return (
      <div style={{ display: "flex", flexDirection: "column", gap: "20px" }}>
        {selectedInvitationCard ? (
          <div id="invitation">
            <h1
              style={{
                color: content?.template_css?.textColor
                  ? content?.template_css?.textColor
                  : "black",
                fontFamily: content?.template_css?.textFontFamily
                  ? content?.template_css?.textFontFamily
                  : "Courier New",
                fontSize: content?.template_css?.headingFontSize
                  ? content?.template_css?.headingFontSize
                  : "30px",
              }}
              className="editingInvitationHeading"
              // className={styles2.bannerHeadingT2}
            >
              Invitation Card
            </h1>
            {selectedInvitationCard && (
              <img
                src={previewUrl(selectedInvitationCard)}
                style={{
                  width: "80%",
                  maxHeight: "500px",
                  border:
                    content?.template_css?.template_type !== "custom" &&
                    "20px white solid",
                  boxShadow:
                    content?.template_css?.template_type !== "custom" &&
                    "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                }}
                alt="Invitation card"
              />
            )}
          </div>
        ) : (
          <>
            {
              eventData && eventData.invitation_card ? (
                <div id="invitation">
                  <h1
                    style={{
                      color: content?.template_css?.textColor
                        ? content?.template_css?.textColor
                        : "black",
                      fontFamily: content?.template_css?.textFontFamily
                        ? content?.template_css?.textFontFamily
                        : "Courier New",
                      fontSize: content?.template_css?.headingFontSize
                        ? content?.template_css?.headingFontSize
                        : "30px",
                    }}
                    className="editingInvitationHeading"
                    // className={styles2.bannerHeadingT2}
                  >
                    Invitation Card
                  </h1>
                  <img
                    src={eventData.invitation_card}
                    style={{
                      width: "80%",
                      maxHeight: "500px",
                      border:
                        content?.template_css?.template_type !== "custom" &&
                        "20px white solid",
                      boxShadow:
                        content?.template_css?.template_type !== "custom" &&
                        "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                      borderRadius:
                        content?.template_css?.template_type !== "custom" &&
                        "10px",
                    }}
                    alt="Invitation card"
                  />
                </div>
              ) : (
                <div>
                  <h1
                    style={{
                      fontSize: content?.template_css?.headingFontSize
                        ? content?.template_css?.headingFontSize
                        : "30px",
                      color: content?.template_css?.textColor
                        ? content?.template_css?.textColor
                        : "black",
                      fontFamily: content?.template_css?.textFontFamily
                        ? content?.template_css?.textFontFamily
                        : "Courier New",
                    }}
                  >
                    {eventData?.template ? eventData?.template : ""} Invitation
                  </h1>
                  <div
                    className="timings"
                    style={{
                      color: content?.template_css?.textColor
                        ? content?.template_css?.textColor
                        : "black",
                      fontFamily: content?.template_css?.textFontFamily
                        ? content?.template_css?.textFontFamily
                        : "Courier New",
                      fontSize: "16px",
                    }}
                  >
                    <p>
                      <span>
                        <b>Date:</b>{" "}
                        {eventData?.venue_date
                          ? new Date(eventData?.venue_date).toLocaleDateString(
                              "en-GB",
                              {
                                day: "2-digit",
                                month: "short",
                                year: "numeric",
                              }
                            )
                          : "N/A"}
                      </span>
                      {" | "}
                      <span>
                        <b>Time:</b>{" "}
                        {eventData?.venue_time
                          ? formatTime(eventData?.venue_time)
                          : "N/A"}
                      </span>
                      {" | "}
                      <span>
                        <b>Live Streaming:</b>{" "}
                        {eventData?.live_time
                          ? formatTime(eventData?.live_time)
                          : "N/A"}
                      </span>
                    </p>
                    <p>
                      <span>
                        <b>Venue:</b>{" "}
                        {eventData?.venue ? eventData?.venue : "N/A"}
                      </span>
                    </p>
                    <p>
                      <span>
                        {eventData?.template_desc
                          ? eventData?.template_desc
                          : ""}
                      </span>
                    </p>
                  </div>
                </div>
              )
              // (
              //   <div className="invitation-frame">
              //     <div>
              //       <div
              //         className={styles.marginBottom}
              //         style={{
              //           display: "flex",
              //           alignItems: "center",
              //           justifyContent: "center",
              //         }}
              //       >
              //         <FaLocationDot className="location-icon" />

              //         {(eventData?.venue)}
              //       </div>

              //       <div
              //         className="time-date"
              //         style={{
              //           display: "flex",
              //           justifyContent: "space-between",
              //           alignItems: "center",
              //           gap: "4px",
              //         }}
              //       >
              //         <div style={{ display: "flex", alignItems: "center" }}>
              //           <BsCalendar2DateFill className="location-icon" />
              //           <div style={{ fontSize: "12px" }}>
              //             {eventData?.venue_date}
              //           </div>
              //         </div>

              //         <p style={{ display: "flex", alignItems: "center" }}>
              //           <MdAccessTimeFilled className="location-icon" />{" "}
              //           <span style={{ fontSize: "12px" }}>
              //             {new Date(
              //               `1970-01-01T${eventData?.venue_time}`
              //             ).toLocaleTimeString([], {
              //               hour: "numeric",
              //               minute: "2-digit",
              //               hour12: true,
              //             })}
              //           </span>
              //         </p>
              //       </div>
              //       {/* <p
              //         style={{
              //           display: "flex",
              //           justifyContent: "center",
              //           alignItems: "center",
              //           margin: "8px 0px 0px 0px",
              //         }}
              //       >
              //         <MdPhoneCallback className="location-icon" />
              //         {eventData?.contact}
              //       </p> */}
              //       <div style={{ margin: "8px 0px 0px 0px" }}>
              //         {(eventData?.template_desc)}
              //       </div>
              //     </div>
              //   </div>
              // )
            }
          </>
        )}
        {selectedInvitationVideo && (
          <>
            {/* {isPortrait ? ( */}
            <div style={{ width: "100%" }} className="video-container2">
              <h1
                style={{
                  color: content?.template_css?.textColor
                    ? content?.template_css?.textColor
                    : "black",
                  fontFamily: content?.template_css?.textFontFamily
                    ? content?.template_css?.textFontFamily
                    : "Courier New",
                  fontSize: content?.template_css?.headingFontSize
                    ? content?.template_css?.headingFontSize
                    : "20px",
                }}
                className="editingInvitationHeading"
                // className={styles2.bannerHeadingT2}
              >
                Invitaion Video
              </h1>
              <video
                style={{
                  width: "80%",
                  border:
                    content?.template_css?.template_type !== "custom" &&
                    "20px white solid",
                  boxShadow:
                    content?.template_css?.template_type !== "custom" &&
                    "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                  borderRadius:
                    content?.template_css?.template_type !== "custom" && "10px",
                }}
                controls
                src={previewUrl(selectedInvitationVideo)}
                type="video/mp4"
              />
            </div>
          </>
        )}

        {eventData?.invitation_video && selectedInvitationVideo === null && (
          <>
            <div style={{ width: "100%" }} className="video-container2">
              <h1
                style={{
                  color: content?.template_css?.textColor
                    ? content?.template_css?.textColor
                    : "black",
                  fontFamily: content?.template_css?.textFontFamily
                    ? content?.template_css?.textFontFamily
                    : "Courier New",
                  fontSize: content?.template_css?.headingFontSize
                    ? content?.template_css?.headingFontSize
                    : "20px",
                }}
                className="editingInvitationHeading"
                // className={styles2.bannerHeadingT2}
              >
                Invitaion Video
              </h1>

              <video
                style={{
                  width: "80%",
                  border:
                    content?.template_css?.template_type !== "custom" &&
                    "20px white solid",
                  boxShadow:
                    content?.template_css?.template_type !== "custom" &&
                    "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                  borderRadius:
                    content?.template_css?.template_type !== "custom" && "10px",
                }}
                controls
                src={eventData?.invitation_video}
                type="video/mp4"
              />
            </div>
          </>
        )}
      </div>
    );
  };

  const renderGallerySection = () => {
    return (
      <div id="gallery" className={styles.galleryBlock}>
        {eventData &&
          Array.isArray(eventData.images) &&
          eventData.images.length > 0 && (
            <>
              <h1
                style={{
                  color: content?.template_css?.textColor
                    ? content?.template_css?.textColor
                    : "black",
                  fontFamily: content?.template_css?.textFontFamily
                    ? content?.template_css?.textFontFamily
                    : "Courier New",
                  fontSize: content?.template_css?.headingFontSize
                    ? content?.template_css?.headingFontSize
                    : "20px",
                }}
                className={styles.galleryHeading}
                // className={styles2.bannerHeadingT2}
              >
                Our Wedding Gallery
              </h1>
              <div style={{ width: "100%" }}>
                <FancyBoxGallery preview={true} />
              </div>
            </>
          )}
      </div>
    );
  };

  const renderFooterSection = (mobile) => {
    return (
      <div
        style={{
          color: content?.template_css?.textColor
            ? content?.template_css?.textColor
            : "black",
          fontFamily: content?.template_css?.textFontFamily
            ? content?.template_css?.textFontFamily
            : "Courier New",
          marginTop: "30px",
        }}
      >
        {/* <p className={styles.footerHeading}>Forever our love, Thank you</p> */}
        <p>Copyright 2024, Made with love by Infinitum Tech</p>
      </div>
    );
  };

  // Handle video error or black screen by forcing a refresh
  const handleOfflineVideo = useCallback(() => {
    const reloadUrl = `${
      refetchedOfflineVideo || eventData?.offline_video
    }?t=${new Date().getTime()}`;
    if (offlineVideoRef.current) {
      offlineVideoRef.current.src = reloadUrl;
      offlineVideoRef.current.load(); // Ensure the video reloads
    }
  }, [refetchedOfflineVideo, eventData?.offline_video]);

  const renderOfflineVideoModificationAndViewingection = (hider) => {
    return (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
        }}
      >
        {hider && (
          <h1
            style={{
              color: content?.template_css?.textColor
                ? content?.template_css?.textColor
                : "black",
              fontFamily: content?.template_css?.textFontFamily
                ? content?.template_css?.textFontFamily
                : "Courier New",
              fontSize: content?.template_css?.headingFontSize
                ? content?.template_css?.headingFontSize
                : "30px",
            }}
            className="editingInvitationHeading"
            // className={styles2.bannerHeadingT2}
          >
            Live Video
          </h1>
        )}
        {hider &&
          eventData.scrolling_mess !== "" &&
          eventData.offline_video && (
            <div style={{ width: "100%" }} className={styles.navbarForAd}>
              <div style={{ width: "100%" }} className="scrollingmess">
                <div style={{ width: "100%" }} className={styles.advertisement}>
                  {eventData?.scrolling_mess}
                </div>
              </div>
            </div>
          )}
        {selectedOfflineVideo === null ||
          (eventData?.offline_video === null &&
            singleEventData?.offline_video === null && (
              <div
                style={{
                  display: "felx",
                  flexDirection: "column",
                  alignItems: "center",
                }}
                className="btns-flex box-border-styling"
              >
                {!hider && <h4>Offline Video</h4>}
                {!hider && (
                  <Button
                    component="label"
                    variant="contained"
                    style={{ fontSize: "10px", margin: "auto" }}
                    startIcon={<CloudUploadIcon />}
                    className="btnsflexbtns"
                  >
                    Offline Video
                    <VisuallyHiddenInput
                      type="file"
                      onChange={handleFileChange(setSelectedOfflineVideo)}
                    />
                  </Button>
                )}
              </div>
            ))}

        {hider && optionData?.option === "file" && (
          <>
            {renderScrollingMess(hider)}
            <div style={{ width: "100%" }}>
              <video
                style={{ width: "80%", margin: "auto", borderRadius: "8px" }}
                controls
                src={previewUrl(optionData?.file)}
              />
            </div>
          </>
        )}

        {selectedOfflineVideo && selectedOfflineVideo !== null ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
            className={`${
              hider ? "video-container" : "btns-flex box-border-styling"
            } `}
          >
            {!hider && <h4>Offline Video</h4>}
            {renderScrollingMess(hider)}
            <video
              src={videoSrc}
              controls
              style={{
                borderRadius: "8px",
                width: "100%",
              }}
            />

            {!hider && (
              <div className="btns-flex">
                <Button
                  component="label"
                  style={{ fontSize: "10px" }}
                  variant="contained"
                  startIcon={<CloudUploadIcon />}
                >
                  Offline video
                  <VisuallyHiddenInput
                    type="file"
                    onChange={handleFileChangeForOffline}
                  />
                </Button>
                <Button
                  component="label"
                  variant="contained"
                  color="error"
                  style={{ fontSize: "10px" }}
                  startIcon={<MdDeleteForever />}
                >
                  Offline Video
                  <VisuallyHiddenInput
                    onClick={handleFileDelete(setSelectedOfflineVideo)}
                  />
                </Button>
              </div>
            )}
          </div>
        ) : (
          eventData?.offline_video &&
          eventData?.offline_video != null && (
            <div
              className={`${hider && "video-container"} ${
                !hider && "btns-flex box-border-styling"
              } `}
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                // border: !hider && "30px white solid",
                // boxShadow: !hider && "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                // borderRadius: !hider && "10px",
                // backgroundImage: `${
                //   hider &&
                //   `${!isLandscape ? `url('${image2}')` : `url('${image1}')`}`
                // }`,
              }}
            >
              {!hider && <h4>Offline Video</h4>}
              {renderScrollingMess(hider)}
              <video
                controls
                src={refetchedOfflineVideo || eventData?.offline_video}
                onError={handleOfflineVideo}
                onStalled={handleOfflineVideo}
                style={{
                  borderRadius: "8px",
                  marginTop: "10px",
                  width: "80%",
                  margin: "auto",
                }}
              />
              {!hider && (
                <div className="btns-flex">
                  <Button
                    component="label"
                    variant="contained"
                    style={{ fontSize: "10px" }}
                    startIcon={<CloudUploadIcon />}
                  >
                    Offline Video
                    <VisuallyHiddenInput
                      type="file"
                      onChange={handleFileChangeForOffline}
                    />
                  </Button>
                  <Button
                    component="label"
                    variant="contained"
                    color="error"
                    style={{ fontSize: "10px" }}
                    startIcon={<MdDeleteForever />}
                  >
                    Offline Video
                    <VisuallyHiddenInput
                      onClick={handleFileDelete(setSelectedOfflineVideo)}
                    />
                  </Button>
                </div>
              )}
            </div>
          )
        )}
      </div>
    );
  };

  const onChangeUrlInput = (e) => {
    let timer;
    const newTitleUrl = e.target.value.replace(/[^a-zA-Z0-9]/g, "");
    const restrictedInputs = [
      /http/i,
      /https/i,
      /:/,
      /\//,
      /"/,
      /'/,
      /\?/,
      /\{/,
      /\}/,
      /\[/,
      /\]/,
      /\!/,
      /\#/,
      /\%/,
      /\^/,
      /\(/,
      /\)/,
      /%/,
      /\s/,
      /;/,
      /_/,
      /\=/,
      /\+/,
      /\@/,
      /\$/,
      /\%/,
      /\&/,
      /\*/,
      /\</,
      /\>/,
      /\`/,
      /\~/,
      /\,/,
      /\./,
    ];

    const isValid = !restrictedInputs.some((regex) => regex.test(newTitleUrl));

    if (!isValid) {
      toast.error("Invalid input. Please avoid special characters");
      setTick(false);
      return;
    }

    if (newTitleUrl)
      setContent({
        ...content,
        prlink: newTitleUrl,
      });

    const searchUrl = async () => {
      try {
        setIsLoading(true);
        setNewPrlinks(null);
        setTick();
        const token = localStorage.getItem("accessToken");
        if (!token) {
          toast.error("No Token Found");
          setIsLoading(false);
          return;
        }

        const url = `${process.env.REACT_APP_BASE_URL}/events/check_link/`;
        const options = {
          method: "POST",
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ prlink: newTitleUrl }),
        };

        const res = await fetch(url, options);
        const data = await res.json();

        if (res.ok || res.status === 200) {
          setTick(true);
        } else {
          setNewPrlinks(data.new_prlinks);
          setTick(false);
        }
      } catch (e) {
        console.error("Error during fetch:", e);
      } finally {
        setIsLoading(false);
      }
    };

    if (timer) {
      clearTimeout(timer); // Clear any previous timer
    }

    if (newTitleUrl.length >= 3) {
      timer = setTimeout(() => {
        searchUrl(); // Call the searchUrl function after 3 seconds
      }, 2000);
    }
  };

  const handleTemplateSettings = (e) => {
    const { name, value } = e.target;
    if (name === "bgColor") {
      setContent({
        ...content,
        template_css: {
          ...content?.template_css,
          [name]: value,
          bgImage: "",
        },
      });
      setEventData({
        ...eventData,
        template_css: { ...eventData?.template_css, bgImage: "" },
      });
    } else if (name === "bgImage") {
      setContent({
        ...content,
        template_css: { ...content?.template_css, [name]: value, bgColor: "" },
      });
      setEventData({
        ...eventData,
        template_css: { ...eventData?.template_css, bgColor: "" },
      });
    } else if (name === "bgOpacity") {
      if (value > 0.001) {
        setContent({
          ...content,
          template_css: {
            ...content?.template_css,
            [name]: parseFloat(value / 100),
          },
        });
      }
    } else {
      setContent({
        ...content,
        template_css: { ...content?.template_css, [name]: value },
      });
    }
  };

  const dateFormatted = new Date(content?.venue_date);
  const day = dateFormatted.getDate();
  const month = dateFormatted.getMonth();
  const year = dateFormatted.getFullYear();
  const date = `${day}-${month + 1 < 10 ? `0${month + 1}` : month + 1}-${year}`;
  const [copiedKeys, setCopiedKeys] = useState({
    "RTMP ID": false,
    "RTMP Access Key": false,
    "RTMPS ID": false,
    "RTMPS Access Key": false,
    "SRT ID": false,
  });

  const InputGroup = ({ label, value }) => {
    const handleCopy = (label) => {
      navigator.clipboard.writeText(value);
      setCopiedKeys((prev) => ({ ...prev, [label]: true }));
      setTimeout(() => {
        setCopiedKeys((prev) => ({ ...prev, [label]: false }));
      }, 1500);
    };
    const displayValue =
      label === "Access Key" ? "*".repeat(value?.length || 0) : value;
    return (
      <div
        className="input-wrapper"
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          gap: "10px",
        }}
      >
        <input
          type="text"
          value={displayValue}
          readOnly
          className="input-field"
          style={{ width: "85%" }} // Input takes up remaining space
        />

        {copiedKeys[label] ? (
          <span className="edit-coped-success">Copied!</span>
        ) : (
          <IoCopy
            onClick={() => handleCopy(label)}
            className="clipboard-icon"
            style={{
              cursor: "pointer",
              color: "#555",
              fontSize: "1.2rem",
            }}
          />
        )}
      </div>
    );
  };

  return (
    <>
      <div
        style={{
          display: `${LoadingViewFor5 === false ? "inline" : "none"}`,
        }}
        className="mainContainerForPage"
      >
        <div
          style={{
            minWidth: "350px",
            width: "100%",
            padding: "60px 16px 0px 16px",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div className="edit-section">
            <h1
              style={{
                textAlign: "center",
                fontFamily: "cursive",
                color: "slategrey",
                marginBottom: "20px",
              }}
            >
              Edit Event
            </h1>
            <div
              style={{
                width: "100%",
                position: "relative",
                zIndex: "2",
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                paddingRight: "18px",
                gap: "8px",
              }}
            >
              {!isOpen && (
                <p className="template-button-para">Templates Options</p>
              )}

              <button
                style={{
                  padding: "5px 10px",
                  borderRadius: "5px",
                  backgroundColor: "white",
                  border: "none",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  color: "white",
                  fontfamily: "'Montserrat'",
                }}
                className="anim-bg-gradient"
                onClick={() => {
                  setOpenStyleSettings(!openStyleSettings);
                }}
              >
                <p>Template Settings</p>

                <Settings />
              </button>

              {openStyleSettings && (
                <LoadTemplateSettings
                  content={content}
                  eventData={eventData}
                  setEventData={setEventData}
                  selectedMainSettingsOption={selectedMainSettingsOption}
                  setSelectedMainSettingsOption={setSelectedMainSettingsOption}
                  setContent={setContent}
                  handleTemplateSettings={handleTemplateSettings}
                  setSelectedSettingsOption={setSelectedSettingsOption}
                  selectedSettingsOption={selectedSettingsOption}
                />
              )}
            </div>
            <div
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: "16px",
              }}
            >
              <div>
                <div>
                  <div
                    style={{
                      padding: "20px",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      fontFamily: "Arial, sans-serif",
                      backgroundColor: "#f9f9f9",
                      boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <label
                      htmlFor="username"
                      style={{
                        fontWeight: "bold",
                        marginBottom: "10px",
                        display: "block",
                      }}
                    >
                      User Name
                    </label>
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          borderColor: `${
                            content?.userName === "" ? "red" : "#ccc"
                          }`,
                          ...textStyleingProperties,
                        }}
                        className="para-styling"
                      >
                        {content?.userName}
                      </div>
                      {textEditor?.userName ? (
                        <button
                          onClick={() => {
                            if (content.userName === "") {
                              toast.error("Must Input Username");
                              return;
                            } else {
                              setTextEditor({ ...textEditor, userName: false });
                              setEventData({
                                ...eventData,
                                name: content.userName,
                              });
                            }
                          }}
                          style={{
                            backgroundColor: "#2196F3",
                            cursor: "pointer",
                          }}
                          className="btn-styling"
                        >
                          <MdSaveAs style={{ marginRight: "3px" }} />
                          Save
                        </button>
                      ) : (
                        <>
                          <button
                            onClick={() => {
                              setTextEditor({ ...textEditor, userName: true });
                            }}
                            style={{
                              backgroundColor: "#4CAF50",
                              cursor: "pointer",
                            }}
                            className="btn-styling"
                          >
                            <FaEdit style={{ marginRight: "3px" }} />
                            Edit
                          </button>
                        </>
                      )}
                    </div>
                    {textEditor?.userName && renderTextEditor("userName")}
                  </div>
                  <div
                    style={{
                      padding: "20px",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      fontFamily: "Arial, sans-serif",
                      backgroundColor: "#f9f9f9",
                      boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <label
                      style={{
                        fontWeight: "bold",
                        marginBottom: "10px",
                        display: "block",
                      }}
                      htmlFor="contact"
                    >
                      Contact Number
                    </label>
                    <div style={{ display: "flex" }}>
                      <PhoneInput
                        country={"in"}
                        disabled={true}
                        sx={{
                          borderColor: `${
                            content?.contact === "" ? "red" : "#ccc"
                          }`,
                        }}
                        value={eventData?.contact}
                      />
                      {/* <p
                    style={{
                      borderColor: `${
                        content?.contact === "" ? "red" : "#ccc"
                      }`,
                    }}
                    className="para-styling"
                  >
                    {(content?.contact)}
                  </p> */}
                      {textEditor?.contact ? (
                        <button
                          onClick={() => {
                            if (content.contact === "") {
                              toast.error("Must Input Contact Number");
                              return;
                            } else if (content.contact.length !== 12) {
                              toast.error("Contact Number Must be 10 digits");
                              return;
                            } else {
                              setTextEditor({ ...textEditor, contact: false });
                              setEventData({
                                ...eventData,
                                contact: content.contact,
                              });
                            }
                          }}
                          style={{
                            backgroundColor: "#2196F3",
                            cursor: "pointer",
                          }}
                          className="btn-styling"
                        >
                          <MdSaveAs style={{ marginRight: "3px" }} />
                          Save
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            setTextEditor({ ...textEditor, contact: true });
                            // setContent(eventData?.contact);
                          }}
                          style={{
                            backgroundColor: "#4CAF50",
                            cursor: "pointer",
                          }}
                          className="btn-styling"
                        >
                          <FaEdit style={{ marginRight: "3px" }} />
                          Edit
                        </button>
                      )}
                    </div>
                    {textEditor?.contact && (
                      <PhoneInput
                        country={"in"}
                        value={content?.contact}
                        onChange={(value) =>
                          setContent({
                            ...content,
                            contact: value,
                          })
                        }
                      />
                      // {errorMessage.contact && (
                      //   <p style={{ color: "red" }}>{errorMessage.contact}</p>
                      // )}
                    )}
                  </div>
                  <div
                    style={{
                      padding: "20px",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      fontFamily: "Arial, sans-serif",
                      backgroundColor: "#f9f9f9",
                      boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <label
                      style={{
                        fontWeight: "bold",
                        marginBottom: "10px",
                        display: "block",
                      }}
                      htmlFor="event-name"
                    >
                      Event Name
                    </label>
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          borderColor: `${
                            content?.eventName === "" ? "red" : "#ccc"
                          }`,
                          ...textStyleingProperties,
                        }}
                        className="para-styling"
                      >
                        {content?.eventName}
                      </div>
                      {textEditor?.eventName ? (
                        <button
                          onClick={() => {
                            // if (content.eventName === "") {
                            //   toast.error("Must Input Event Name");
                            //   return;
                            // } else {
                            setTextEditor({
                              ...textEditor,
                              eventName: false,
                            });
                            setEventData({
                              ...eventData,
                              title: content.eventName,
                            });
                            // setContent("");
                            // }
                          }}
                          style={{
                            backgroundColor: "#2196F3",
                            cursor: "pointer",
                          }}
                          className="btn-styling"
                        >
                          <MdSaveAs style={{ marginRight: "3px" }} />
                          Save
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            setTextEditor({ ...textEditor, eventName: true });
                            // setContent(eventData?.contact);
                          }}
                          style={{
                            backgroundColor: "#4CAF50",
                            cursor: "pointer",
                          }}
                          className="btn-styling"
                        >
                          <FaEdit style={{ marginRight: "3px" }} />
                          Edit
                        </button>
                      )}
                    </div>
                    {textEditor?.eventName && renderTextEditor("eventName")}
                  </div>
                  <div
                    style={{
                      padding: "20px",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      fontFamily: "Arial, sans-serif",
                      backgroundColor: "#f9f9f9",
                      boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <label
                      style={{
                        fontWeight: "bold",
                        marginBottom: "10px",
                        display: "block",
                      }}
                      htmlFor="event-type"
                    >
                      Select Event
                    </label>
                    <div>
                      <div style={{ display: "flex" }}>
                        <div
                          style={{
                            borderColor: `${
                              content?.template_desc === "" ? "red" : "#ccc"
                            }`,
                            ...textStyleingProperties,
                          }}
                          className="para-styling"
                        >
                          {content?.template_desc}
                        </div>
                        {textEditor?.template ? (
                          <button
                            onClick={() => {
                              // if (content.template_desc === "") {
                              //   toast.error("Must Input Event Discription");
                              //   return;
                              // }
                              // if (content.template === "") {
                              //   toast.error("Must Input Custom Event");
                              // } else {
                              setTextEditor({
                                ...textEditor,
                                template: false,
                              });
                              setEventData({
                                ...eventData,
                                template: content.template,
                                template_desc: content.template_desc,
                              });
                              // }
                            }}
                            style={{
                              backgroundColor: "#2196F3",
                              cursor: "pointer",
                            }}
                            className="btn-styling"
                          >
                            <MdSaveAs style={{ marginRight: "3px" }} />
                            Save
                          </button>
                        ) : (
                          <button
                            onClick={() => {
                              setTextEditor({ ...textEditor, template: true });
                            }}
                            style={{
                              backgroundColor: "#4CAF50",
                              cursor: "pointer",
                            }}
                            className="btn-styling"
                          >
                            <FaEdit style={{ marginRight: "3px" }} />
                            Edit
                          </button>
                        )}
                      </div>
                      <select
                        disabled={!textEditor?.template}
                        id="event-type"
                        value={
                          content?.template &&
                          ![
                            "Birthday",
                            "Marriage",
                            "Saree Ceremony",
                            "Cultural Event",
                            "Family Reunion",
                            "Party",
                          ].includes(content?.template)
                            ? "others"
                            : content?.template
                        }
                        onChange={(e) => {
                          setOption(e.target.value);
                          setContent({
                            ...content,
                            template: e.target.value,
                          });
                        }}
                        className="select"
                      >
                        <option value="Birthday">Birthday</option>
                        <option value="Marriage">Marriage</option>
                        <option value="Saree Ceremony">Saree Ceremony</option>
                        <option value="Cultural Event">Cultural Event</option>
                        <option value="Family Reunion">Family Reunion</option>
                        <option value="Party">Party</option>
                        <option value="others">
                          Others{" "}
                          {![
                            "Birthday",
                            "Marriage",
                            "Saree Ceremony",
                            "Cultural Event",
                            "Family Reunion",
                            "Party",
                            "others",
                          ].includes(content?.template) &&
                            `(${content.template})`}
                        </option>
                      </select>
                      {textEditor?.template &&
                        (![
                          "Birthday",
                          "Marriage",
                          "Saree Ceremony",
                          "Cultural Event",
                          "Family Reunion",
                          "Party",
                          "others",
                        ].includes(content?.template) ||
                          content?.template === "others") && (
                          <input
                            className="para-styling"
                            type="text"
                            value={content?.template}
                            style={{
                              borderColor: `${
                                content?.template === "" ? "red" : "#ccc"
                              }`,
                            }}
                            onChange={(e) => {
                              setContent({
                                ...content,
                                template: e.target.value,
                              });
                            }}
                          />
                        )}
                      {textEditor?.template &&
                        renderTextEditor("template_desc")}
                    </div>
                  </div>

                  <div
                    style={{
                      padding: "20px",
                      border: "1px solid #ccc",
                      borderRadius: "8px",
                      fontFamily: "Arial, sans-serif",
                      backgroundColor: "#f9f9f9",
                      boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <div style={{ position: "relative", width: "100%" }}>
                      <label
                        htmlFor="prlink"
                        style={{
                          fontWeight: "bold",
                          marginBottom: "10px",
                          display: "block",
                        }}
                      >
                        Set Your Own URL
                      </label>
                      <p style={{ color: "red", fontSize: "12px" }}>
                        Note: Format like "ram123"
                      </p>

                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          gap: "10px",
                        }}
                      >
                        {/* URL Display and Save/Edit Button Container */}
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            position: "relative",
                          }}
                        >
                          <p
                            style={{
                              borderColor: `${
                                content?.prlink === "" ? "red" : "#ccc"
                              }`,
                              flex: 1,
                              wordBreak: "break-word",
                              padding: "10px",
                              border: "1px solid #ccc",
                              borderRadius: "5px",
                              backgroundColor: "#fff",
                              margin: "0",
                              ...textStyleingProperties,
                            }}
                            className="para-styling"
                          >
                            {content?.prlink}
                          </p>

                          {isLoading && (
                            <div
                              style={{
                                position: "absolute",
                                right: "90px",
                                top: "55%",
                                transform: "translateY(-50%)",
                              }}
                            >
                              <CircularProgress size={24} />
                            </div>
                          )}

                          <div
                            style={{
                              position: "absolute",
                              right: "90px",
                              top: "55%",
                              transform: "translateY(-50%)",
                            }}
                          >
                            {tick === true ? (
                              <SiTicktick
                                style={{ color: "#3498db" }}
                                size={24}
                              />
                            ) : (
                              tick === false && (
                                <RxCrossCircled
                                  style={{ color: "red" }}
                                  size={24}
                                />
                              )
                            )}
                          </div>
                          {textEditor?.prlink ? (
                            <button
                              onClick={() => {
                                if (content.prlink === "") {
                                  toast.error("Must Input URL");
                                } else {
                                  setTextEditor({
                                    ...textEditor,
                                    prlink: false,
                                  });
                                  setEventData({
                                    ...eventData,
                                    prlink: content?.prlink,
                                  });
                                  setSelectedRowId(content?.prlink);
                                }
                              }}
                              style={{
                                backgroundColor: "#2196F3",
                                padding: "8px 16px",
                                borderRadius: "5px",
                                color: "#fff",
                                border: "none",
                                cursor: "pointer",
                              }}
                              className="btn-styling"
                            >
                              <MdSaveAs style={{ marginRight: "3px" }} />
                              Save
                            </button>
                          ) : (
                            <button
                              onClick={() => {
                                setTextEditor({ ...textEditor, prlink: true });
                              }}
                              style={{
                                backgroundColor: "#4CAF50",
                                cursor: "pointer",
                              }}
                              className="btn-styling"
                            >
                              <FaEdit style={{ marginRight: "3px" }} />
                              Edit
                            </button>
                          )}
                        </div>

                        {/* Editable Input for URL */}
                        {textEditor?.prlink && (
                          <input
                            id="prlink"
                            placeholder="Set Your Own URL"
                            type="text"
                            value={content.prlink}
                            onChange={(e) => onChangeUrlInput(e)}
                            style={{
                              width: "100%",
                              padding: "10px",
                              borderRadius: "5px",
                              border: "1px solid #ccc",
                              fontSize: "16px",
                              fontFamily: "Arial, sans-serif",
                              backgroundColor: "#fff",
                              textDecoration: `${
                                newPrlinks ? "line-through" : "none"
                              }`,
                            }}
                          />
                        )}
                      </div>

                      {newPrlinks && (
                        <p style={{ color: "red", marginBottom: "10px" }}>
                          Link Already Used
                        </p>
                      )}

                      {/* Sample Buttons */}
                      <div
                        style={{
                          display: "flex",
                          gap: "8px",
                          flexWrap: "wrap",
                        }}
                      >
                        {newPrlinks && (
                          <p style={{ margin_top: "3px" }}>Samples: </p>
                        )}

                        {newPrlinks &&
                          newPrlinks.map((each, index) => (
                            <button
                              style={{
                                border: "none",
                                backgroundColor: "#d4f5d4",
                                padding: "6px 12px",
                                borderRadius: "4px",
                                color: "#054c05",
                                cursor: "pointer",
                                fontSize: "14px",
                              }}
                              key={index}
                              onClick={() => {
                                setContent({ ...content, prlink: each });
                                setNewPrlinks(null);
                                setTick(true);
                              }}
                            >
                              {each}
                            </button>
                          ))}
                      </div>

                      {/* Final Link Display */}
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          marginTop: "5px",
                        }}
                      >
                        <p
                          style={{
                            color: "#32cd32",
                            fontSize: "16px",
                            fontWeight: "bold",
                            textDecoration: "underline",
                            letterSpacing: "0.5px",
                            marginRight: "10px",
                            maxWidth: "80%",
                            overflowWrap: "break-word",
                          }}
                        >
                          https://www.eventsonlife.com/{content?.prlink}
                        </p>
                        <button
                          onClick={() => {
                            const url = `https://eventsonlife.com/${content?.prlink}`;
                            navigator.clipboard
                              .writeText(url)
                              .then(() => {
                                setCopied(true);
                                // Reset copied state after 2 seconds
                                setTimeout(() => setCopied(false), 2000);
                              })
                              .catch((err) =>
                                console.error("Failed to copy text: ", err)
                              );
                          }}
                          style={{
                            backgroundColor: "#32cd32",
                            border: "none",
                            padding: "5px 10px",
                            color: "white",
                            cursor: "pointer",
                            borderRadius: "5px",
                            fontWeight: "bold",
                          }}
                        >
                          {copied ? (
                            <SiTicktick
                              style={{ height: "20px", width: "20px" }}
                            />
                          ) : (
                            <CopyIcon
                              style={{ height: "20px", width: "20px" }}
                            />
                          )}
                        </button>
                      </div>
                    </div>
                  </div>

                  <div
                    style={{
                      padding: "20px",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      fontFamily: "Arial, sans-serif",
                      backgroundColor: "#f9f9f9",
                      boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <label
                      style={{
                        fontWeight: "bold",
                        marginBottom: "10px",
                        display: "block",
                      }}
                      htmlFor="venue"
                    >
                      Venue
                    </label>
                    <div style={{ display: "flex" }}>
                      <div
                        style={{
                          borderColor: `${
                            content?.venue === "" ? "red" : "#ccc"
                          }`,
                          ...textStyleingProperties,
                        }}
                        className="para-styling"
                      >
                        {content?.venue}
                      </div>
                      {textEditor?.venue ? (
                        <button
                          onClick={() => {
                            // if (content.venue === "") {
                            //   toast.error("Must Input Event Address");
                            // } else {
                            setTextEditor({ ...textEditor, venue: false });
                            setEventData({
                              ...eventData,
                              venue: content?.venue,
                            });
                            // }
                          }}
                          style={{
                            backgroundColor: "#2196F3",
                            cursor: "pointer",
                          }}
                          className="btn-styling"
                        >
                          <MdSaveAs style={{ marginRight: "3px" }} />
                          Save
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            setTextEditor({ ...textEditor, venue: true });
                            // setContent(eventData?.contact);
                          }}
                          style={{
                            backgroundColor: "#4CAF50",
                            cursor: "pointer",
                          }}
                          className="btn-styling"
                        >
                          <FaEdit style={{ marginRight: "3px" }} />
                          Edit
                        </button>
                      )}
                    </div>
                    <div
                      style={{
                        color: "red",
                        textAlign: "right",
                        fontSize: "10px",
                      }}
                    >
                      {content?.venue?.length >= 97 &&
                        `upto 100 chars only ${content?.venue?.length}`}
                    </div>
                    {textEditor?.venue && renderTextEditor("venue")}
                  </div>
                  <div
                    style={{
                      padding: "20px",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      fontFamily: "Arial, sans-serif",
                      backgroundColor: "#f9f9f9",
                      boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <label
                      style={{
                        fontWeight: "bold",
                        marginBottom: "10px",
                        display: "block",
                      }}
                      htmlFor="venue-date"
                    >
                      Venue Date
                    </label>

                    <div style={{ display: "flex" }}>
                      <p className="para-styling">{date}</p>

                      {textEditor?.venueDate ? (
                        <button
                          onClick={() => {
                            setTextEditor({ ...textEditor, venueDate: false });
                            setEventData({
                              ...eventData,
                              venue_date: content.venue_date,
                            });
                          }}
                          style={{
                            backgroundColor: "#2196F3",
                            cursor: "pointer",
                          }}
                          className="btn-styling"
                        >
                          <MdSaveAs style={{ marginRight: "3px" }} />
                          Save
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            setTextEditor({ ...textEditor, venueDate: true });
                            // setContent(eventData?.contact);
                          }}
                          style={{
                            backgroundColor: "#4CAF50",
                            cursor: "pointer",
                          }}
                          className="btn-styling"
                        >
                          <FaEdit style={{ marginRight: "3px" }} />
                          Edit
                        </button>
                      )}
                    </div>
                    {textEditor?.venueDate && (
                      <TextField
                        margin="dense"
                        name="venue_date"
                        type="date"
                        fullWidth
                        variant="outlined"
                        value={content?.venue_date}
                        inputProps={{
                          style: {
                            backgroundColor: "rgb(255, 255, 255)",
                          },
                        }}
                        onChange={(e) =>
                          setContent({ ...content, venue_date: e.target.value })
                        }
                        InputLabelProps={{
                          shrink: true,
                        }}
                        className="edit-data-input-style"
                      />
                    )}
                  </div>
                  <div
                    style={{
                      padding: "20px",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      fontFamily: "Arial, sans-serif",
                      backgroundColor: "#f9f9f9",
                      boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <label
                      style={{
                        fontWeight: "bold",
                        marginBottom: "10px",
                        display: "block",
                      }}
                      htmlFor="venue-time"
                    >
                      Venue Time
                    </label>
                    <div style={{ display: "flex" }}>
                      <p className="para-styling">{content?.venue_time}</p>

                      {textEditor?.venueTime ? (
                        <button
                          onClick={() => {
                            setTextEditor({ ...textEditor, venueTime: false });
                            setEventData({
                              ...eventData,
                              venue_time: content.venue_time,
                            });
                          }}
                          style={{
                            backgroundColor: "#2196F3",
                            cursor: "pointer",
                          }}
                          className="btn-styling"
                        >
                          <MdSaveAs style={{ marginRight: "3px" }} />
                          Save
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            setTextEditor({ ...textEditor, venueTime: true });
                            // setContent(eventData?.contact);
                          }}
                          style={{
                            backgroundColor: "#4CAF50",
                            cursor: "pointer",
                          }}
                          className="btn-styling"
                        >
                          <FaEdit style={{ marginRight: "3px" }} />
                          Edit
                        </button>
                      )}
                    </div>
                    {textEditor?.venueTime && (
                      <TextField
                        margin="dense"
                        name="venue_time"
                        type="time"
                        fullWidth
                        variant="outlined"
                        inputProps={{
                          style: {
                            backgroundColor: "rgb(255, 255, 255)",
                          },
                        }}
                        value={content?.venue_time}
                        onChange={(e) =>
                          setContent({ ...content, venue_time: e.target.value })
                        }
                        className="edit-data-input-style"
                      />
                    )}
                  </div>
                  <div
                    style={{
                      padding: "20px",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      fontFamily: "Arial, sans-serif",
                      backgroundColor: "#f9f9f9",
                      boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <label
                      style={{
                        fontWeight: "bold",
                        marginBottom: "10px",
                        display: "block",
                      }}
                      htmlFor="venue-time"
                    >
                      Live Time
                    </label>
                    <div style={{ display: "flex" }}>
                      <p className="para-styling">{content?.live_time}</p>

                      {textEditor?.liveTime ? (
                        <button
                          onClick={() => {
                            setTextEditor({ ...textEditor, liveTime: false });
                            setEventData({
                              ...eventData,
                              live_time: content.live_time,
                            });
                          }}
                          style={{
                            backgroundColor: "#2196F3",
                            cursor: "pointer",
                          }}
                          className="btn-styling"
                        >
                          <MdSaveAs style={{ marginRight: "3px" }} />
                          Save
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            setTextEditor({ ...textEditor, liveTime: true });
                            // setContent(eventData?.contact);
                          }}
                          style={{
                            backgroundColor: "#4CAF50",
                            cursor: "pointer",
                          }}
                          className="btn-styling"
                        >
                          <FaEdit style={{ marginRight: "3px" }} />
                          Edit
                        </button>
                      )}
                    </div>
                    {textEditor?.liveTime && (
                      <TextField
                        margin="dense"
                        name="live_time"
                        type="time"
                        fullWidth
                        variant="outlined"
                        inputProps={{
                          style: {
                            backgroundColor: "rgb(255, 255, 255)",
                          },
                        }}
                        value={content?.live_time}
                        onChange={(e) =>
                          setContent({ ...content, live_time: e.target.value })
                        }
                        className="edit-data-input-style"
                      />
                    )}
                  </div>
                  <div
                    style={{
                      padding: "20px",
                      border: "1px solid #ccc",
                      borderRadius: "5px",
                      fontFamily: "Arial, sans-serif",
                      backgroundColor: "#f9f9f9",
                      boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
                    }}
                  >
                    <label
                      style={{
                        fontWeight: "bold",
                        marginBottom: "10px",
                        display: "block",
                      }}
                      htmlFor="vscrolling Mess"
                    >
                      Scrolling Message
                    </label>
                    <div style={{ display: "flex" }}>
                      <div
                        className="para-styling"
                        style={{ ...textStyleingProperties }}
                      >
                        {content?.scrolling_mess}
                      </div>

                      {textEditor?.scrolling_mess ? (
                        <button
                          onClick={() => {
                            setTextEditor({
                              ...textEditor,
                              scrolling_mess: false,
                            });
                            setEventData({
                              ...eventData,
                              scrolling_mess: content.scrolling_mess,
                            });
                          }}
                          style={{
                            backgroundColor: "#2196F3",
                            cursor: "pointer",
                          }}
                          className="btn-styling"
                        >
                          <MdSaveAs style={{ marginRight: "3px" }} />
                          Save
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            setTextEditor({
                              ...textEditor,
                              scrolling_mess: true,
                            });
                            // setContent(eventData?.contact);
                          }}
                          style={{
                            backgroundColor: "#4CAF50",
                            cursor: "pointer",
                          }}
                          className="btn-styling"
                        >
                          <FaEdit style={{ marginRight: "3px" }} />
                          Edit
                        </button>
                      )}
                    </div>
                    {textEditor?.scrolling_mess &&
                      renderTextEditor("scrolling_mess")}
                  </div>
                </div>
              </div>

              {selectedBanner && selectedBanner != null ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                  className="box-border-styling"
                >
                  {content?.template_css?.template_type === "t2" ? (
                    <h4>Event Picture</h4>
                  ) : (
                    <h4>Banner Section</h4>
                  )}
                  <img
                    src={previewUrl(selectedBanner)}
                    alt="Banner Preview"
                    style={{
                      borderRadius: "8px",
                      marginTop: "10px",
                      backgroundSize: "contain",
                      width: "100%",
                    }}
                  />
                  <div className="btns-flex">
                    <Button
                      component="label"
                      variant="contained"
                      style={{ fontSize: "10px" }}
                      startIcon={<CloudUploadIcon />}
                    >
                      {content?.template_css?.template_type === "t2"
                        ? "Event"
                        : "Banner"}
                      <VisuallyHiddenInput
                        type="file"
                        onChange={handleFileChange(setSelectedBanner)}
                      />
                    </Button>
                    <Button
                      component="label"
                      variant="contained"
                      color="error"
                      style={{ fontSize: "10px" }}
                      startIcon={<MdDeleteForever />}
                    >
                      {content?.template_css?.template_type === "t2"
                        ? "Event"
                        : "Banner"}
                      <VisuallyHiddenInput
                        onClick={handleFileDelete(setSelectedBanner)}
                      />
                    </Button>
                  </div>
                </div>
              ) : (
                eventData?.banner &&
                eventData?.banner != null && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                    className="box-border-styling"
                  >
                    {content?.template_css?.template_type === "t2" ? (
                      <h4>Event Picture</h4>
                    ) : (
                      <h4>Banner Section</h4>
                    )}
                    <img
                      src={`${eventData?.banner}`}
                      alt="Banner"
                      style={{
                        maxWidth: "100%",
                        borderRadius: "8px",
                        marginTop: "10px",
                      }}
                    />
                    <div className="btns-flex">
                      <Button
                        component="label"
                        variant="contained"
                        style={{ fontSize: "10px" }}
                        startIcon={<CloudUploadIcon />}
                      >
                        {content?.template_css?.template_type === "t2"
                          ? "Event"
                          : "Banner"}
                        <VisuallyHiddenInput
                          type="file"
                          onChange={handleFileChange(setSelectedBanner)}
                        />
                      </Button>
                      <Button
                        component="label"
                        variant="contained"
                        color="error"
                        style={{ fontSize: "10px" }}
                        startIcon={<MdDeleteForever />}
                      >
                        {content?.template_css?.template_type === "t2"
                          ? "Event"
                          : "Banner"}
                        <VisuallyHiddenInput
                          onClick={handleFileDelete(setSelectedBanner)}
                        />
                      </Button>
                    </div>
                  </div>
                )
              )}
              {selectedBanner === null &&
                (eventData?.banner === "" || eventData?.banner === null) && (
                  <div
                    style={{ flexDirection: "column", alignItems: "center" }}
                    className="btns-flex box-border-styling"
                  >
                    {content?.template_css?.template_type === "t2" ? (
                      <h4>Event Picture</h4>
                    ) : (
                      <h4>Banner Section</h4>
                    )}
                    <Button
                      component="label"
                      variant="contained"
                      sx={{ fontSize: "10px", margin: "auto" }}
                      startIcon={<CloudUploadIcon />}
                      className="btnsflexbtns"
                    >
                      {content?.template_css?.template_type === "t2"
                        ? "Event"
                        : "Banner"}
                      <VisuallyHiddenInput
                        type="file"
                        onChange={handleFileChange(setSelectedBanner)}
                      />
                    </Button>
                  </div>
                )}
              {selectedInvitationCard && selectedInvitationCard != null ? (
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                  }}
                  className="box-border-styling"
                >
                  <h4>Invitation Card</h4>
                  <img
                    src={previewUrl(selectedInvitationCard)}
                    alt="Invitation Card Preview"
                    style={{
                      maxWidth: "100%",
                      borderRadius: "8px",
                      marginTop: "10px",
                      height: " auto",
                      maxHeight: "450px",
                    }}
                  />
                  <div className="btns-flex">
                    <Button
                      component="label"
                      variant="contained"
                      style={{ fontSize: "10px" }}
                      startIcon={<CloudUploadIcon />}
                    >
                      Invitation card
                      <VisuallyHiddenInput
                        type="file"
                        onChange={handleFileChange(setSelectedInvitationCard)}
                      />
                    </Button>
                    <Button
                      component="label"
                      variant="contained"
                      color="error"
                      style={{ fontSize: "10px" }}
                      startIcon={<MdDeleteForever />}
                    >
                      Invitation_card
                      <VisuallyHiddenInput
                        onClick={handleFileDelete(setSelectedInvitationCard)}
                      />
                    </Button>
                  </div>
                </div>
              ) : (
                eventData?.invitation_card &&
                eventData?.invitation_card != null && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                    }}
                    className="box-border-styling"
                  >
                    <h4>Invitation Card</h4>
                    <img
                      src={`${eventData.invitation_card}`}
                      alt="Invitation Card"
                      style={{
                        maxWidth: "100%",
                        borderRadius: "8px",
                        marginTop: "10px",

                        height: " auto",
                        maxHeight: "450px",
                      }}
                    />
                    <div className="btns-flex">
                      <Button
                        component="label"
                        variant="contained"
                        style={{ fontSize: "10px" }}
                        startIcon={<CloudUploadIcon />}
                      >
                        Invitation card
                        <VisuallyHiddenInput
                          type="file"
                          onChange={handleFileChange(setSelectedInvitationCard)}
                        />
                      </Button>
                      <Button
                        component="label"
                        variant="contained"
                        color="error"
                        style={{ fontSize: "10px" }}
                        startIcon={<MdDeleteForever />}
                      >
                        Invitation card
                        <VisuallyHiddenInput
                          onClick={handleFileDelete(setSelectedInvitationCard)}
                        />
                      </Button>
                    </div>
                  </div>
                )
              )}
              {selectedInvitationCard === null &&
                (eventData?.invitation_card === "" ||
                  eventData.invitation_card === null) && (
                  <div
                    style={{ flexDirection: "column", alignItems: "center" }}
                    className="btns-flex box-border-styling"
                  >
                    <h4>Invitation Card</h4>
                    <Button
                      component="label"
                      variant="contained"
                      startIcon={<CloudUploadIcon />}
                      className="btnsflexbtns"
                      style={{ fontSize: "10px", margin: "auto" }}
                    >
                      Invitation Card
                      <VisuallyHiddenInput
                        type="file"
                        onChange={handleFileChange(setSelectedInvitationCard)}
                      />
                    </Button>
                  </div>
                )}
              {content?.template_css?.template_type !== "t2" && (
                <>
                  {selectedInvitationVideo &&
                  selectedInvitationVideo !== null ? (
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                        width: "100%",
                      }}
                      className="box-border-styling"
                    >
                      <h4>Invitation Video</h4>
                      <video
                        controls
                        src={previewUrl(selectedInvitationVideo)}
                        style={{
                          width: "inherit",
                          borderRadius: "8px",
                          margin: "10px 0px",
                        }}
                      />
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                        }}
                      >
                        <Button
                          component="label"
                          variant="contained"
                          style={{ fontSize: "10px" }}
                          startIcon={<CloudUploadIcon />}
                        >
                          Invitation video
                          <VisuallyHiddenInput
                            type="file"
                            onChange={handleFileChange(
                              setSelectedInvitationVideo
                            )}
                          />
                        </Button>
                        <Button
                          component="label"
                          variant="contained"
                          color="error"
                          style={{ fontSize: "10px" }}
                          startIcon={<MdDeleteForever />}
                        >
                          Invitation Video
                          <VisuallyHiddenInput
                            onClick={handleFileDelete(
                              setSelectedInvitationVideo
                            )}
                          />
                        </Button>
                      </div>
                    </div>
                  ) : (
                    eventData?.invitation_video &&
                    eventData?.invitation_video != null && (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                          width: "100%",
                        }}
                        className="box-border-styling"
                      >
                        <h4>Invitation Video</h4>
                        <video
                          controls
                          src={eventData?.invitation_video}
                          style={{
                            width: "inherit",
                            borderRadius: "8px",
                            marginTop: "10px",
                          }}
                        />
                        <div className="btns-flex">
                          <Button
                            component="label"
                            variant="contained"
                            style={{ fontSize: "10px" }}
                            startIcon={<CloudUploadIcon />}
                          >
                            Invitation video
                            <VisuallyHiddenInput
                              type="file"
                              onChange={handleFileChange(
                                setSelectedInvitationVideo
                              )}
                            />
                          </Button>
                          <Button
                            component="label"
                            variant="contained"
                            color="error"
                            style={{ fontSize: "10px" }}
                            startIcon={<MdDeleteForever />}
                          >
                            Invitation Video
                            <VisuallyHiddenInput
                              onClick={handleFileDelete(
                                setSelectedInvitationVideo
                              )}
                            />
                          </Button>
                        </div>
                      </div>
                    )
                  )}
                  {selectedInvitationVideo === null &&
                    (eventData?.invitation_video === "" ||
                      eventData.invitation_video === null) && (
                      <div
                        style={{
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                        className="btns-flex box-border-styling"
                      >
                        <h4>Invitation Video</h4>
                        <Button
                          component="label"
                          variant="contained"
                          startIcon={<CloudUploadIcon />}
                          className="btnsflexbtns"
                          style={{ fontSize: "10px", margin: "auto" }}
                        >
                          Invitation video
                          <VisuallyHiddenInput
                            type="file"
                            onChange={handleFileChange(
                              setSelectedInvitationVideo
                            )}
                          />
                        </Button>
                      </div>
                    )}
                </>
              )}

              {(singleEventData?.offline_video && eventData?.offline_video) ||
              selectedOfflineVideo
                ? renderOfflineVideoModificationAndViewingection()
                : renderLiveVideoComponent()}
              {(eventData?.offline_video === null ||
                eventData?.offline_video === "") &&
                (eventData?.live_vidlink === "" ||
                  eventData?.live_vidlink === "null" ||
                  eventData?.live_vidlink === null) && (
                  <div
                    style={{ flexDirection: "column", textAlign: "center" }}
                    className="btns-flex box-border-styling"
                  >
                    {/* <h4 style={{ textAlign: "center" }}>Offline Video</h4>

                    <div className="" style={{ marginTop: "10px" }}>
                      <Button
                        component="label"
                        variant="contained"
                        style={{ fontSize: "10px" }}
                        startIcon={<CloudUploadIcon />}
                      >
                        Offline Video
                        <VisuallyHiddenInput
                          type="file"
                          onChange={handleFileChangeForOffline}
                          // onChange={handleFileChange(setSelectedOfflineVideo)}
                        />
                      </Button>
                    </div> */}
                    {singleChannelData === null && (
                      <UrlPreview
                        selectedRowId={eventData?.id}
                        edit={true}
                        title={eventData.title}
                        setOptionData={setOptionData}
                        setSelectedOfflineVideo={setSelectedOfflineVideo}
                      />
                    )}
                  </div>
                )}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
                className="box-border-styling"
              >
                <h4>Gallery</h4>
                <div
                  style={{
                    display: "flex",
                    gap: "10px",
                    flexWrap: "wrap",
                    marginTop: "10px",
                  }}
                >
                  {eventData?.images?.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        position: "relative",
                        width: "100px", // set a fixed width for all images
                        height: "120px", // set a fixed height for all images
                        display: "flex", // flex container for alignment
                        justifyContent: "center", // center horizontally
                        alignItems: "center", // center vertically
                      }}
                    >
                      <img
                        src={`${item.image}`}
                        alt={`Event ${index + 1}`}
                        style={{
                          width: "90px", // takes the full width of the container
                          height: "70px", // takes the full height of the container
                          objectFit: "cover", // ensures the image covers the area while maintaining aspect ratio
                          borderRadius: "8px", // rounded corners
                        }}
                      />
                      <button
                        onClick={() => deleteImage(item.id)}
                        style={{
                          position: "absolute",
                          top: "10px",
                          right: "-5px",
                          background: "red",
                          color: "white",
                          border: "none",
                          borderRadius: "50%",
                          cursor: "pointer",
                          padding: "5px",
                        }}
                      >
                        X
                      </button>
                    </div>
                  ))}
                  {selectedImages.map((image, index) => (
                    <img
                      key={index}
                      src={previewUrl(image)}
                      alt={`Selected ${index + 1}`}
                      style={{
                        maxWidth: "100px",
                        maxHeight: "100px",
                        borderRadius: "8px",
                        objectFit: "cover",
                      }}
                    />
                  ))}
                </div>
                {/* {loading && <CircularProgress />} */}
                {eventData &&
                  Array.isArray(eventData.images) &&
                  eventData.images.length > 0 && (
                    <div className="btns-flex">
                      <Button
                        component="label"
                        variant="contained"
                        style={{ fontSize: "10px", marginTop: "10px" }}
                        startIcon={<CloudUploadIcon />}
                        // disabled={loading}
                        className="btnsflexbtns"
                      >
                        Images
                        <VisuallyHiddenInput
                          type="file"
                          multiple
                          onChange={handleImagesChange}
                        />
                      </Button>
                    </div>
                  )}
                {Array.isArray(selectedImages) &&
                  selectedImages.length === 0 &&
                  Array.isArray(eventData.images) &&
                  eventData.images.length === 0 && (
                    <div className="btns-flex">
                      <Button
                        component="label"
                        variant="contained"
                        style={{ fontSize: "10px", marginTop: "10px" }}
                        startIcon={<CloudUploadIcon />}
                        className="btnsflexbtns"
                      >
                        Images
                        <VisuallyHiddenInput
                          type="file"
                          multiple
                          onChange={handleImagesChange}
                        />
                      </Button>
                    </div>
                  )}
              </div>
              <div>
                {(singleChannelData || eventData?.channattahed) && (
                  <div>
                    {singleChannelData &&
                    (singleChannelData?.recording
                      ? singleChannelData?.recording_playback_url === null ||
                        singleChannelData?.recording_playback_url === ""
                      : singleChannelData?.rtmp_id !== "Removed") ? (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                        className="box-border-styling"
                      >
                        <div
                          style={{ width: "100%", position: "relative" }}
                          className="stream-sontainer"
                        >
                          {!checkRecorded && streamStatus !== "LIVE" && (
                            <XCircle
                              onClick={() => {
                                setOpenChannelDeletePopUp(true);
                              }}
                              style={{
                                position: "absolute",
                                right: "0",
                                color: "red",
                                cursor: "pointer",
                              }}
                            />
                          )}

                          <h2 className="heading">Select Stream Type</h2>

                          <div className="radio-group">
                            <label className="radio-label">
                              <input
                                type="radio"
                                value="RTMP"
                                checked={selectedOption === "RTMP"}
                                onChange={handleOptionChange}
                                className="radio-input"
                              />
                              RTMP
                            </label>
                            <label className="radio-label">
                              <input
                                type="radio"
                                value="RTMPS"
                                checked={selectedOption === "RTMPS"}
                                onChange={handleOptionChange}
                                className="radio-input"
                              />
                              RTMPS
                            </label>
                            <label className="radio-label">
                              <input
                                type="radio"
                                value="SRT"
                                checked={selectedOption === "SRT"}
                                onChange={handleOptionChange}
                                className="radio-input"
                              />
                              SRT
                            </label>
                          </div>

                          <div
                            className={`${
                              new Date(singleEventData?.venue_date).setHours(
                                0,
                                0,
                                0,
                                0
                              ) !== new Date().setHours(0, 0, 0, 0) &&
                              `input-container-blur`
                            } input-container`}
                          >
                            {selectedOption === "RTMP" && (
                              <div className="input-group">
                                <InputGroup
                                  label="RTMP ID"
                                  value={singleChannelData?.rtmp_id}
                                />
                                <InputGroup
                                  label="RTMP Access Key"
                                  value={singleChannelData?.Stream_access_key}
                                />
                              </div>
                            )}

                            {selectedOption === "RTMPS" && (
                              <div className="input-group">
                                <InputGroup
                                  label="RTMPS ID"
                                  value={singleChannelData?.rtmps_id}
                                />
                                <InputGroup
                                  label="RTMPS Access Key"
                                  value={singleChannelData?.Stream_access_key}
                                />
                              </div>
                            )}

                            {selectedOption === "SRT" && (
                              <div className="input-group">
                                <InputGroup
                                  label="SRT ID"
                                  value={singleChannelData?.srt_id}
                                />
                              </div>
                            )}
                          </div>
                          {new Date(singleEventData?.venue_date).setHours(
                            0,
                            0,
                            0,
                            0
                          ) !== new Date().setHours(0, 0, 0, 0) && (
                            <p style={{ color: "red" }}>
                              Note: Keys Will be revelead on Event Scheduled
                              Date
                            </p>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "column",
                          alignItems: "center",
                        }}
                        className="box-border-styling"
                      >
                        <div className="stream-sontainer">
                          <h2 className="heading">Select Stream Type</h2>
                          <p>
                            Your Streaming is Completed. So, you don't have
                            access to take keys
                          </p>
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
              <div>
                {renderEditPassword()}
                {/* {renderAds()} */}
              </div>
            </div>

            <DialogActions
              sx={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              <Button
                onClick={handleCloseEdit}
                variant="outlined"
                color="primary"
                className="edit-btn-action-style"
              >
                Cancel
              </Button>

              {editingValidator() ? (
                <Button
                  onClick={handlerEditEvent}
                  variant="contained"
                  color="primary"
                  className="edit-btn-action-style"
                  style={{ marginLeft: "auto" }}
                >
                  Save
                </Button>
              ) : null}
            </DialogActions>
            {renderConfirmSection()}
            {renderCancelSection()}
          </div>
          <div className="preview-section">
            {content?.template_css?.template_type === "custom" && (
              <div
                style={{
                  width: "100%",
                  position:
                    (content?.template_css?.bgImage !== "" ||
                      content?.template_css?.bgColor !== "") &&
                    "relative",
                }}
              >
                <div
                  style={{
                    minHeight: content?.template_css?.bgImage !== "" && "100vh",
                    height:
                      content?.template_css?.bgImage !== "" &&
                      `${containerHeight + 50}px`,
                    width: content?.template_css?.bgImage !== "" && "100%",
                    position:
                      content?.template_css?.bgImage !== "" && "absolute",
                    backgroundImage:
                      content?.template_css?.bgImage !== ""
                        ? `url(${content?.template_css?.bgImage})`
                        : undefined,

                    backgroundRepeat:
                      content?.template_css?.bgImage !== "" && "no-repeat",
                    backgroundSize:
                      content?.template_css?.bgImage !== "" && "cover",

                    opacity: content?.template_css?.bgOpacity
                      ? parseFloat(content?.template_css?.bgOpacity)
                      : eventData?.template_css?.bgOpacity
                      ? parseFloat(eventData?.template_css?.bgOpacity)
                      : 1,
                  }}
                  className="imageContainer"
                ></div>
                <div
                  style={{
                    position:
                      content?.template_css?.bgImage !== "" && "absolute",
                    background:
                      content?.template_css?.bgGradient ||
                      (!content?.template_css?.bgImage &&
                        !content?.template_css?.bgColor &&
                        "radial-gradient(circle, #F2C94C, #F2994A)"),
                    backgroundColor:
                      content?.template_css?.bgColor &&
                      content?.template_css?.bgColor,
                    fontFamily:
                      content?.template_css?.textFontFamily !== ""
                        ? content?.template_css?.textFontFamily
                        : eventData?.template_css?.textFontFamily,
                  }}
                  ref={containerRef}
                  className="template-global contentContainer"
                >
                  <h1
                    style={{
                      textAlign: "center",
                      fontFamily: "cursive",
                      paddingBottom: "20px",
                    }}
                  >
                    Preview section
                  </h1>
                  <div style={{ width: "100%" }}>
                    {eventData?.title && renderNavigation()}

                    {renderBannerImage()}
                    {/* {renderVenueSection()}  */}
                    {renderInvitationSection()}
                    {(singleEventData?.offline_video &&
                      eventData?.offline_video) ||
                    selectedOfflineVideo ||
                    optionData?.option === "file"
                      ? renderOfflineVideoModificationAndViewingection("hide")
                      : renderLiveVideoComponent("hide")}
                    {renderGallerySection()}
                    {renderFooterSection()}
                  </div>
                </div>
              </div>
            )}
            {content?.template_css?.template_type === "dafault" && (
              <div
                style={{
                  backgroundImage: `url(${walletPaper})`,
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundAttachment: "fixed",
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  alignItems: "center",
                  justifyItems: "center",
                  textAlign: "center",
                }}
              >
                <RenderDefaultTemplate
                  hlsLiveUrl={hlsLiveUrl}
                  streamStatus={streamStatus}
                  setStreamStatus={setStreamStatus}
                  selectedOfflineVideo={selectedOfflineVideo}
                  optionData={optionData}
                  videoSrc={videoSrc}
                  selectedInvitationCard={selectedInvitationCard}
                  selectedInvitationVideo={selectedInvitationVideo}
                  selectedBanner={selectedBanner}
                />
              </div>
            )}
            {content?.template_css?.template_type === "t2" && (
              <T2
                edit={true}
                hlsLiveUrl={hlsLiveUrl}
                streamStatus={streamStatus}
                setStreamStatus={setStreamStatus}
                selectedOfflineVideo={selectedOfflineVideo}
                optionData={optionData}
                videoSrc={videoSrc}
                selectedInvitationCard={selectedInvitationCard}
                selectedInvitationVideo={selectedInvitationVideo}
                selectedBanner={selectedBanner}
                selectedTemplateOption={content?.template_css?.template_type}
              />
            )}
            {content?.template_css?.template_type === "t3" && (
              <div
                style={{
                  backgroundSize: "cover",
                  backgroundPosition: "center",
                  backgroundRepeat: "no-repeat",
                  backgroundAttachment: "fixed",
                  display: "flex",
                  flexDirection: "column",
                  gap: "20px",
                  alignItems: "center",
                  justifyItems: "center",
                  textAlign: "center",
                }}
              >
                <T3RenderTemplate
                  hlsLiveUrl={hlsLiveUrl}
                  streamStatus={streamStatus}
                  setStreamStatus={setStreamStatus}
                  selectedOfflineVideo={selectedOfflineVideo}
                  optionData={optionData}
                  videoSrc={videoSrc}
                  selectedInvitationCard={selectedInvitationCard}
                  selectedInvitationVideo={selectedInvitationVideo}
                  selectedBanner={selectedBanner}
                />
              </div>
            )}
          </div>

          <div className="slider-btn">
            <div
            // style={{
            //   position: content?.template_css?.bgImage && "relative",
            // }}
            >
              <div className={`sidebar ${isOpen ? "open" : ""}`}>
                <button onClick={toggleSidebar} className="toggle-button">
                  <span className="toggle-text">
                    {isOpen ? "Close" : "Preview"}
                  </span>
                </button>
                <div
                  className={`sidebar-content ${isOpen ? "visible" : "hidden"}`}
                >
                  {content?.template_css?.template_type === "custom" && (
                    <div
                      style={{
                        position: "relative",
                        // backgroundImage: `url(${walletPaper})`,
                        // backgroundSize: "cover",
                        // backgroundPosition: "center",
                        // backgroundRepeat: "no-repeat",
                        // backgroundAttachment: "fixed",
                      }}
                    >
                      <div
                        style={{
                          minHeight:
                            content?.template_css?.bgImage !== "" && "100vh",
                          height:
                            content?.template_css?.bgImage !== "" &&
                            `${contentContainerHeight + 50}px`,
                          width:
                            content?.template_css?.bgImage !== "" && "100%",
                          position:
                            content?.template_css?.bgImage !== "" && "absolute",
                          backgroundImage:
                            content?.template_css?.bgImage !== ""
                              ? `url(${content?.template_css?.bgImage})`
                              : undefined,

                          backgroundRepeat:
                            content?.template_css?.bgImage !== "" &&
                            "no-repeat",
                          backgroundSize:
                            content?.template_css?.bgImage !== "" && "cover",

                          opacity: content?.template_css?.bgOpacity
                            ? parseFloat(content?.template_css?.bgOpacity)
                            : eventData?.template_css?.bgOpacity
                            ? parseFloat(eventData?.template_css?.bgOpacity)
                            : 1,
                        }}
                      ></div>
                      <div
                        ref={contentContainerRef}
                        style={{
                          position:
                            content?.template_css?.bgImage !== "" && "absolute",
                          background:
                            content?.template_css?.bgGradient ||
                            (!content?.template_css?.bgImage &&
                              !content?.template_css?.bgColor &&
                              "radial-gradient(circle, #F2C94C, #F2994A)"),

                          backgroundColor:
                            content?.template_css?.bgColor &&
                            content?.template_css?.bgColor,

                          fontFamily:
                            content?.template_css?.textFontFamily ||
                            eventData?.template_css?.textFontFamily,
                        }}
                        // style={{
                        //   backgroundImage:
                        //     'url("../../../../public/grunge-white-surface-rough-background-textured.jpg")',
                        //   backgroundSize: "contain",
                        //   height: "100vh",
                        // }}
                      >
                        {eventData?.title && renderNavigation(true)}
                        <div className="template-global">
                          <div>
                            {renderBannerImage(true)}
                            {/* {renderVenueSection()} */}
                            {renderInvitationSection(true)}
                            {(singleEventData?.offline_video &&
                              eventData?.offline_video) ||
                            selectedOfflineVideo ||
                            optionData?.option === "file"
                              ? renderOfflineVideoModificationAndViewingection(
                                  "hide"
                                )
                              : renderLiveVideoComponent("hide", "preview")}
                            {renderGallerySection()}
                          </div>

                          {renderFooterSection(true)}
                        </div>
                      </div>
                    </div>
                  )}
                  {content?.template_css?.template_type === "dafault" && (
                    <div
                      style={{
                        backgroundImage: `url(${walletPaper})`,
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundAttachment: "fixed",
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                        alignItems: "center",
                        justifyItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <RenderDefaultTemplate
                        hlsLiveUrl={hlsLiveUrl}
                        streamStatus={streamStatus}
                        setStreamStatus={setStreamStatus}
                        selectedOfflineVideo={selectedOfflineVideo}
                        optionData={optionData}
                        videoSrc={videoSrc}
                        selectedInvitationCard={selectedInvitationCard}
                        selectedInvitationVideo={selectedInvitationVideo}
                        selectedBanner={selectedBanner}
                      />
                    </div>
                  )}
                  {content?.template_css?.template_type === "t2" && (
                    <T2
                      edit={true}
                      hlsLiveUrl={hlsLiveUrl}
                      streamStatus={streamStatus}
                      setStreamStatus={setStreamStatus}
                      selectedOfflineVideo={selectedOfflineVideo}
                      optionData={optionData}
                      videoSrc={videoSrc}
                      selectedInvitationCard={selectedInvitationCard}
                      selectedInvitationVideo={selectedInvitationVideo}
                      selectedBanner={selectedBanner}
                      selectedTemplateOption={
                        content?.template_css?.template_type
                      }
                    />
                  )}
                  {content?.template_css?.template_type === "t3" && (
                    <div
                      style={{
                        backgroundSize: "cover",
                        backgroundPosition: "center",
                        backgroundRepeat: "no-repeat",
                        backgroundAttachment: "fixed",
                        display: "flex",
                        flexDirection: "column",
                        gap: "20px",
                        alignItems: "center",
                        justifyItems: "center",
                        textAlign: "center",
                      }}
                    >
                      <T3RenderTemplate
                        hlsLiveUrl={hlsLiveUrl}
                        streamStatus={streamStatus}
                        setStreamStatus={setStreamStatus}
                        selectedOfflineVideo={selectedOfflineVideo}
                        optionData={optionData}
                        videoSrc={videoSrc}
                        selectedInvitationCard={selectedInvitationCard}
                        selectedInvitationVideo={selectedInvitationVideo}
                        selectedBanner={selectedBanner}
                      />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>

          <Dialog
            open={
              showLoader &&
              (uploadingVideo.uploadingInvitation ||
                uploadingVideo.uploadingOfflineVideo)
            }
            fullWidth
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <DialogTitle
              sx={{
                textAlign: "center",
                marginBottom: "10px",
                fontWeight: "700",
                color: "#FF5722",
                fontSize: "14px",
              }}
            >
              Files Are Being Uploaded. Please Do Not Close This Window.
            </DialogTitle>
            <div
              style={{
                padding: "0px 30px 10px 30px",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              {selectedInvitationVideo && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                    marginBottom: "10px",
                  }}
                >
                  {uploadingVideo.uploadingInvitation === 100 ? (
                    <div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <h3
                          style={{
                            width: "150px",
                            color: "#4CAF50",
                            fontSize: "16px",
                            fontWeight: "600",
                          }}
                        >
                          {selectedInvitationVideo.name.length > 10
                            ? selectedInvitationVideo.name.substring(0, 10) +
                              "..."
                            : selectedInvitationVideo.name}
                          :
                        </h3>
                        <SiTicktick style={{ color: "#4CAF50" }} />{" "}
                        {parseInt(uploadingVideo.uploadingInvitation)}%
                      </div>
                    </div>
                  ) : (
                    <div>
                      <p
                        style={{
                          textAlign: "center",
                          fontSize: "14px",
                          color: "#333",
                        }}
                      >
                        {selectedInvitationVideo.name.length > 10
                          ? selectedInvitationVideo.name.substring(0, 10) +
                            "..."
                          : selectedInvitationVideo.name}
                      </p>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <LinearProgress
                          style={{
                            height: "10px",
                            borderRadius: "5px",
                            backgroundColor: "#e0e0e0",
                            borderColor: "#9e9e9e",
                          }}
                          sx={{
                            width: "200px",
                            "& .MuiLinearProgress-bar": {
                              backgroundColor:
                                uploadingVideo.uploadingInvitation === 100
                                  ? "#4CAF50"
                                  : "#2196F3",
                            },
                          }}
                          variant="determinate"
                          value={uploadingVideo.uploadingInvitation}
                        />
                        {parseInt(uploadingVideo.uploadingInvitation)}%
                      </div>
                    </div>
                  )}
                </div>
              )}

              {(selectedOfflineVideo || optionData?.option === "file") && (
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                  }}
                >
                  {uploadingVideo.uploadingOfflineVideo === 100 ? (
                    <div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <h3
                          style={{
                            width: "150px",
                            color: "#FF9800",
                            fontSize: "16px",
                            fontWeight: "600",
                          }}
                        >
                          {(optionData?.option === "file" &&
                            optionData?.file.name) ||
                          (selectedOfflineVideo && selectedOfflineVideo.name)
                            .length > 10
                            ? (optionData?.option === "file"
                                ? optionData?.file.name
                                : selectedOfflineVideo.name
                              ).substring(0, 10) + "..."
                            : optionData?.option === "file"
                            ? optionData?.file.name
                            : selectedOfflineVideo.name}
                          :
                        </h3>
                        <SiTicktick style={{ color: "#FF9800" }} />{" "}
                        {parseInt(uploadingVideo.uploadingOfflineVideo)}%
                      </div>
                    </div>
                  ) : (
                    <div>
                      <p
                        style={{
                          textAlign: "center",
                          fontSize: "14px",
                          color: "#333",
                        }}
                      >
                        {(optionData?.option === "file" &&
                          optionData?.file.name) ||
                        (selectedOfflineVideo && selectedOfflineVideo.name)
                          .length > 10
                          ? (optionData?.option === "file"
                              ? optionData?.file.name
                              : selectedOfflineVideo.name
                            ).substring(0, 10) + "..."
                          : optionData?.option === "file"
                          ? optionData?.file.name
                          : selectedOfflineVideo.name}
                      </p>

                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "5px",
                        }}
                      >
                        <LinearProgress
                          style={{
                            height: "10px",
                            borderRadius: "5px",
                            backgroundColor: "#e0e0e0",
                            borderColor: "#9e9e9e",
                          }}
                          sx={{
                            width: "200px",
                            "& .MuiLinearProgress-bar": {
                              backgroundColor:
                                uploadingVideo.uploadingOfflineVideo === 100
                                  ? "#FF9800"
                                  : "#2196F3",
                            },
                          }}
                          variant="determinate"
                          value={uploadingVideo.uploadingOfflineVideo}
                        />
                        {parseInt(uploadingVideo.uploadingOfflineVideo)}%
                      </div>
                    </div>
                  )}
                </div>
              )}
            </div>
          </Dialog>
          <Dialog
            open={userData?.is_admin === false && showLinkDeletationDialog}
            onClose={() => setShowLinkDeletationDialog(false)}
          >
            <DialogTitle>
              Are you sure you want to remove this link?
            </DialogTitle>
            <DialogContent>
              <p>
                Removing this link will result in the loss of credits. Once
                lost, the credits cannot be redeemed. Do you want to proceed?
              </p>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={() => {
                  setShowLinkDeletationDialog(false);
                  setEventData({
                    ...eventData,
                    live_vidlink: content?.live_vidlink,
                  });
                }}
                color="primary"
              >
                Yes
              </Button>
              <Button
                onClick={() => setShowLinkDeletationDialog(false)}
                color="secondary"
              >
                No
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={openChannelDeletePopUp}
            onClose={() => setOpenChannelDeletePopUp(false)}
            aria-labelledby="confirm-delete-title"
            PaperProps={{
              style: {
                padding: "20px",
                borderRadius: "10px",
                minWidth: "400px",
                maxWidth: "600px",
              },
            }}
          >
            <DialogTitle
              id="confirm-delete-title"
              style={{
                textAlign: "center",
                fontWeight: "bold",
                fontSize: "1.5rem",
              }}
            >
              Confirm Deletion
            </DialogTitle>
            <DialogContent
              style={{
                textAlign: "center",
                fontSize: "1rem",
                color: "#555",
                marginBottom: "20px",
              }}
            >
              Are you sure you want to delete this channel? This action cannot
              be undone. After Deletion Page Will be Reloaded.
            </DialogContent>
            <DialogActions
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "10px",
              }}
            >
              <Button
                variant="contained"
                onClick={() => {
                  setOpenChannelDeletePopUp(false);
                  deleteChannel(singleChannelData?.id, true);
                }}
                style={{
                  backgroundColor: "#d32f2f",
                  color: "#fff",
                  textTransform: "none",
                  fontWeight: "bold",
                }}
              >
                Yes
              </Button>
              <Button
                variant="outlined"
                onClick={() => setOpenChannelDeletePopUp(false)}
                style={{
                  color: "#555",
                  borderColor: "#555",
                  textTransform: "none",
                  fontWeight: "bold",
                }}
              >
                No
              </Button>
            </DialogActions>
          </Dialog>
          {/* <Dialog
            open={openliveComplitionDialog}
            onClose={() => {
              setOpenliveComplitionDialog(false);
            }}
            sx={{
              "& .MuiDialog-paper": {
                borderRadius: "15px",
                padding: "20px",
                backgroundColor: "#f9f9f9",
              },
            }}
          >
            <DialogTitle
              sx={{
                fontSize: "18px",
                fontWeight: "bold",
                textAlign: "center",
                color: "#333",
              }}
            >
              Click Yes to Continue the Live
            </DialogTitle>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: "10px",
                marginTop: "15px",
              }}
            >
              <Button
                variant="contained"
                color="primary"
                onClick={() => setOpenliveComplitionDialog(false)}
                sx={{
                  borderRadius: "20px",
                  padding: "8px 16px",
                  fontSize: "14px",
                  fontWeight: "bold",
                }}
              >
                Yes
              </Button>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => {
                  setOpenliveComplitionDialog(false);
                }}
                sx={{
                  borderRadius: "20px",
                  padding: "8px 16px",
                  fontSize: "14px",
                  fontWeight: "bold",
                }}
              >
                No
              </Button>
            </div>
          </Dialog> */}
        </div>
      </div>
      <div
        style={{
          display: `${LoadingViewFor5 ? "flex" : "none"}`,
          opacity: "1",
          transition: "opacity 1s ease-in-out",
        }}
        className="loaderContainer"
      >
        <div className="loader"></div>
      </div>
    </>
  );
};

export default EditEvent;
