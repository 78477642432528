import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import NavBar from "../../components/navBar/navBar";
import FancyBoxGallery from "../../components/fancyBoxGallery/fancyBoxGallery";
import HLSPlayer from "components/hlsPlayer";
import CoupleFrame from "../../assets/images/coupleFrame.png";
import { useAppDataContext } from "hooks/useappdatacontext";
import stylesTwo from "./weddingTemplate.module.css";
import { FaLocationDot } from "react-icons/fa6";
import { X } from "lucide-react";
import Slider from "react-slick";
import styles from "../Custom/T2/styles.module.css";
import { motion } from "framer-motion";
import DefaultTemplate from "./DefaultTemplate";
import {
  capitalizeFirstLetter,
  extractHlsUrl,
  extractYouTubeLiveId,
  extractYouTubeVideoId,
} from "./comonFunctions";
import stylez from "../Custom/T30/t3.module.css";
import { RenderScrollingView } from "./scrollingAds";
import { WhatsAppButton } from "./whatsAppSection";
import { RenderFooterSection } from "./fotterSection";
import {
  T3renderBannerImage,
  T3renderFooterSection,
  T3renderGallerySection,
  T3renderInvitationSection,
  T3renderLiveVideoSection,
  T3WhatsAppButton,
} from "../Custom/T30/T3";
import T2Template from "./T2Template";

// import LoadingView from "./LoadingView";

const WeddingTemplate = () => {
  // contexts
  const { getEvent, singleEventData, setShowLoader, getAds, ads } =
    useAppDataContext();

  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    speed: 1000,
    autoplaySpeed: ads?.delaytime * 1000 || 2000,
    autoplay: true,
  };
  const [streamStatus, setStreamStatus] = useState("");

  // states
  const [showTemplate, setShowTemplate] = useState(true);
  const [newdas, setnewdas] = useState([]);
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [LoadingViewFor5, setLoadingViewFor5] = useState(true);
  const [id, setID] = useState(null);
  const videoRef = useRef(null);
  const videoInvitationRef = useRef(null);

  const [idOfflinePortrait, setIdOfflinePortrait] = useState(false);
  const [idInvitationPortrait, setIdInvitationPortrait] = useState(false);
  const [css, setCss] = useState({});
  const [invitationPopOver, setInvitationPopOver] = useState(null);

  const containerRef = useRef(null); // Reference to the container
  const [containerHeight, setContainerHeight] = useState(0);
  const [count, setCount] = useState([]);

  const [isOpen, setIsOpen] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const [templateDecider, setTemplateDecider] = useState("");

  useEffect(() => {
    document.title = `Event on Life - ${
      singleEventData?.title
        ? capitalizeFirstLetter(singleEventData?.title)
        : ""
    }`;
  }, [singleEventData?.title]);

  useEffect(() => {
    let r;
    if (singleEventData?.template_css) {
      r = JSON.parse(singleEventData?.template_css);
    }
    if (r?.template_type === undefined) {
      setTemplateDecider("default");
    } else {
      setTemplateDecider(r?.template_type);
    }
  }, [singleEventData]);

  useEffect(() => {
    setIsOpen(true);
  }, []);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        // Trigger the fade-out of h1 after the door animation
        const h1 = document.querySelector("h1");
        h1.style.animation = "fadeOut 2s forwards";

        // After h1 fades out, show the main content
        setTimeout(() => setShowContent(true), 750); // Wait for the h1 fade out
      }, 2500); // Wait for doors to open
    }
  }, [isOpen]);
  const [progress, setProgress] = useState(0);
  const calculateTimeLeft = () => {
    const eventDateTime = new Date(
      `${singleEventData?.venue_date}T${singleEventData.venue_time}`
    ).getTime();
    const now = new Date().getTime();
    const difference = eventDateTime - now;

    if (difference <= 0) return { days: 0, hours: 0, minutes: 0, seconds: 0 };

    return {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [singleEventData?.venue_date, singleEventData?.venue_time]);

  useEffect(() => {
    // Calculate the progress (percentage of time left)
    setProgress(((60 - timeLeft.seconds) / 60) * 100);
  }, [timeLeft]);

  // useEffect(() => {
  //   // Calculate the progress (percentage of time left)
  //   setProgress(((60 - timeLeft.seconds) / 60) * 100);
  // }, [timeLeft]);

  const getVisitorsCount = async () => {
    let url = `${process.env.REACT_APP_BASE_URL}/events/get_event_visitors/`;
    if (id) {
      try {
        let res = await fetch(url, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ prlink: id }),
        });
        if (res.ok) {
          const { visitors } = await res.json();
          setCount(visitors);
        }
      } catch (e) {
        console.error(e.message);
      }
    }
  };

  const handlerToIncreaseCount = async () => {
    const eventId = localStorage.getItem(`eventsentID:${id}`);

    if (eventId === null && id) {
      const url = `${process.env.REACT_APP_BASE_URL}/events/increase_visitors/`;
      localStorage.setItem(`eventsentID:${id}`, true);
      try {
        let r = await fetch(url, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ prlink: id }),
        });
      } catch (e) {
        console.error(e.message);
      }
    }
  };

  useEffect(() => {
    getVisitorsCount();
    handlerToIncreaseCount();
  }, [id]);

  useEffect(() => {
    const fetchAds = async () => {
      try {
        await getAds(); // Assuming getAds is an API call function
      } catch (error) {
        console.error("Error fetching ads:", error);
      }
    };

    fetchAds();
  }, []);
  // const [isHovered, setIsHovered] = useState(false);
  const [slideSettings, setSettings] = useState({ ...settings });
  const sliderRef = useRef(null);

  const handleMouseEnter = useCallback(() => {
    // Stop autoplay on hover
    setSettings((prevSettings) => ({
      ...prevSettings,
      autoplay: false,
    }));
  }, []);

  const handleMouseLeave = useCallback(() => {
    // Start autoplay on mouse leave
    setSettings((prevSettings) => ({
      ...prevSettings,
      autoplay: true,
    }));
  }, []);

  useEffect(() => {
    if (ads?.delaytime) {
      setSettings((prevSettings) => ({
        ...prevSettings,
        autoplaySpeed: ads.delaytime * 1000, // Update speed in milliseconds
      }));
    }
  }, [ads?.delaytime]);

  const calculateHeight = () => {
    if (containerRef.current) {
      setContainerHeight(containerRef.current.scrollHeight);
    }
  };

  const updateHeightSmoothly = () => {
    requestAnimationFrame(() => {
      calculateHeight();
    });
  };

  useEffect(() => {
    calculateHeight();

    const handleResize = () => {
      updateHeightSmoothly();
    };
    window.addEventListener("resize", handleResize);

    const observer = new MutationObserver(updateHeightSmoothly);
    if (containerRef.current) {
      observer.observe(containerRef.current, {
        childList: true,
        subtree: true,
      });
    }

    return () => {
      window.removeEventListener("resize", handleResize);
      if (observer) {
        observer.disconnect();
      }
    };
  }, [singleEventData]);

  updateHeightSmoothly();

  const params = useParams();

  useEffect(() => {
    // Set the decoded ID
    setID(params.id);
  }, [params.id]);

  // useEffect(() => {
  //   let timer = setTimeout(() => {
  //     setLoadingViewFor5(false);
  //   }, 4500);

  //   return () => {
  //     clearTimeout(timer);
  //   };
  // }, []);

  const path = useParams();

  useEffect(() => {
    const { id } = path;
    const getSingleEvent = async () => {
      setShowLoader(true);
      const newdas = await getEvent(id);
      if (newdas) {
        setnewdas(newdas);
      }
      setShowLoader(false);
      if (newdas && newdas.is_secure === true) {
        setShowTemplate(false);
      }
    };
    if (id) {
      getSingleEvent();
    }
  }, [id]);

  useEffect(() => {
    if (
      singleEventData?.template_css !== "" &&
      singleEventData?.template_css !== undefined
    ) {
      const jsonString = singleEventData?.template_css;
      setCss(JSON.parse(jsonString));
    }
  }, [singleEventData]);

  // Handle video dimensions after metadata is loaded
  const handleVideoMetadata = (ref, setPortraitState) => {
    if (ref.current) {
      const videoWidth = ref.current.videoWidth;
      const videoHeight = ref.current.videoHeight;
      setPortraitState(videoHeight > videoWidth);
    }
  };

  // When videoRef metadata is loaded
  const handleOfflineVideoMetadata = () => {
    handleVideoMetadata(videoRef, setIdOfflinePortrait);
  };

  // When videoInvitationRef metadata is loaded
  const handleInvitationVideoMetadata = () => {
    handleVideoMetadata(videoInvitationRef, setIdInvitationPortrait);
  };

  const handleSecureValidation = () => {
    if (password === newdas.password) {
      setShowTemplate(true);
    } else {
      setError("Password Invalid");
    }
  };

  const renderBannerImage = () => {
    const fontSize =
      singleEventData?.title &&
      singleEventData?.title.length > 20 &&
      singleEventData?.title.length < 40
        ? "20px"
        : singleEventData?.title && singleEventData?.title.length >= 40
        ? "16px"
        : "24px";
    return (
      singleEventData &&
      singleEventData?.banner && (
        <div>
          <p
            style={{
              color: css?.textColor ? css?.textColor : "black",
              fontFamily: css?.textFontFamily
                ? css?.textFontFamily
                : "Courier New",
              fontSize: css?.headingFontSize ? css?.headingFontSize : "30px",
            }}
            className={stylesTwo.invitationHeading}
          >
            Banner
          </p>

          <div id="home" className={stylesTwo.videoContainer}>
            <img
              style={{ width: "inherit", height: "inherit", cursor: "pointer" }}
              src={singleEventData?.banner}
              alt="Banner_image"
              onClick={() => setInvitationPopOver(singleEventData?.banner)}
            />
          </div>
        </div>
      )
    );
  };

  const renderNavigation = () => {
    return (
      <>
        {!singleEventData?.live_vidlink && !singleEventData?.offline_video && (
          <div
            style={{ width: "100vw" }}
            className={stylesTwo.advertisementContainer}
          >
            <div className={stylesTwo.advertisement}>
              <div>
                {singleEventData?.scrolling_mess &&
                  singleEventData?.scrolling_mess}
              </div>
            </div>
          </div>
        )}
      </>
    );
  };

  // extracts day, month, and year
  function formatTime(timeString) {
    if (!timeString) return "Invalid time"; // Handle undefined or empty strings

    const [hour, minute] = timeString.split(":");
    const hourInt = parseInt(hour, 10);
    const isPM = hourInt >= 12;

    const formattedHour = hourInt % 12 === 0 ? 12 : hourInt % 12; // Handle 12-hour clock
    const period = isPM ? "PM" : "AM";

    return `${formattedHour.toString().padStart(2, "0")}:${minute} ${period}`;
  }

  const renderInvitationSection = () => {
    return (
      <>
        {singleEventData && singleEventData.invitation_card ? (
          <div id="invitation">
            <p
              style={{
                color: css?.textColor ? css?.textColor : "black",
                fontFamily: css?.textFontFamily
                  ? css?.textFontFamily
                  : "Courier New",
                fontSize: css?.headingFontSize ? css?.headingFontSize : "30px",
              }}
              className={stylesTwo.invitationHeading}
            >
              Invitation Card
            </p>

            <div>
              {singleEventData?.invitation_card && (
                <motion.img
                  style={{
                    borderRadius: "10px",
                  }}
                  className={stylesTwo.invitaitonImage}
                  src={singleEventData?.invitation_card}
                  alt="Invitation card"
                  onClick={() =>
                    setInvitationPopOver(singleEventData?.invitation_card)
                  }
                  whileHover={{ scale: 1.05, transition: { duration: 0.3 } }}
                />
              )}
            </div>
          </div>
        ) : (
          <div
            style={{
              color: css?.textColor ? css?.textColor : "black",
              fontFamily: css?.textFontFamily
                ? css?.textFontFamily
                : "Courier New",
            }}
            className={stylesTwo.timings}
          >
            <h1
              className={stylesTwo.bannerz}
              style={{
                color: css?.textColor ? css?.textColor : "black",
                fontFamily: css?.textFontFamily
                  ? css?.textFontFamily
                  : "Courier New",
                fontSize: css?.headingFontSize ? css?.headingFontSize : "30px",
              }}
            >
              {newdas.template ? newdas.template : ""} Invitation
            </h1>
            <p>
              <span>
                <b>Date:</b>{" "}
                {newdas.venue_date
                  ? new Date(newdas.venue_date).toLocaleDateString("en-GB", {
                      day: "2-digit",
                      month: "short",
                      year: "numeric",
                    })
                  : "N/A"}
              </span>
              {" | "}
              <span>
                <b>Time:</b>{" "}
                {newdas.venue_time ? formatTime(newdas.venue_time) : "N/A"}
              </span>
              {" | "}
              <span>
                <b>Live Streaming:</b>{" "}
                {newdas.live_time ? formatTime(newdas.live_time) : "N/A"}
              </span>
            </p>
            <p>
              <span>
                <b>Venue:</b> {newdas.venue ? newdas.venue : "N/A"}
              </span>
            </p>
            <p>
              <span>{newdas.template_desc ? newdas.template_desc : ""}</span>
            </p>
          </div>
        )}
        {singleEventData && singleEventData?.invitation_video && (
          <>
            <p
              style={{
                color: css?.textColor ? css?.textColor : "black",
                fontFamily: css?.textFontFamily
                  ? css?.textFontFamily
                  : "Courier New",
                fontSize: css?.headingFontSize ? css?.headingFontSize : "30px",
              }}
              className={stylesTwo.invitationHeading}
            >
              Invitation Video
            </p>
            <div
              className={`${
                idInvitationPortrait
                  ? stylesTwo.videoContainerPortrait
                  : stylesTwo.videoContainer
              }`}
            >
              <video
                ref={videoInvitationRef}
                style={{ width: "inherit" }}
                controls
                onLoadedMetadata={handleInvitationVideoMetadata}
              >
                <source
                  src={singleEventData?.invitation_video}
                  type="video/mp4"
                />
              </video>
            </div>
          </>
        )}
      </>
    );
  };

  const renderLiveVideoSection = () => {
    const videoId = extractYouTubeVideoId(singleEventData?.live_vidlink);
    const liveId = extractYouTubeLiveId(singleEventData?.live_vidlink);
    const hlsUrl = extractHlsUrl(singleEventData?.live_vidlink);

    if (videoId) {
      return (
        <>
          <p
            style={{
              color: css?.textColor ? css?.textColor : "black",
              fontFamily: css?.textFontFamily
                ? css?.textFontFamily
                : "Courier New",
              fontSize: css?.headingFontSize ? css?.headingFontSize : "30px",
            }}
            className={stylesTwo.invitationHeading}
          >
            Live Video
          </p>
          <RenderScrollingView />
          <div className={stylesTwo.videoContainer}>
            <iframe
              style={{ width: "inherit", height: "inherit" }}
              src={`https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="YouTube Video"
            ></iframe>
          </div>
        </>
      );
    } else if (liveId) {
      return (
        <>
          <p
            style={{
              color: css?.textColor ? css?.textColor : "black",
              fontFamily: css?.textFontFamily
                ? css?.textFontFamily
                : "Courier New",
              fontSize: css?.headingFontSize ? css?.headingFontSize : "30px",
            }}
            className={stylesTwo.invitationHeading}
          >
            Live Video
          </p>

          <div className={stylesTwo.videoContainer}>
            <iframe
              style={{ width: "inherit", height: "inherit" }}
              title="Live"
              src={`https://www.youtube.com/embed/${liveId}?autoplay=1&mute=1`}
              frameBorder="0"
              allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </>
      );
    } else if (hlsUrl) {
      return (
        <>
          <p
            style={{
              color: css?.textColor ? css?.textColor : "black",
              fontFamily: css?.textFontFamily
                ? css?.textFontFamily
                : "Courier New",
              fontSize: css?.headingFontSize ? css?.headingFontSize : "30px",
            }}
            className={stylesTwo.invitationHeading}
          >
            Live Video
          </p>
          <RenderScrollingView />
          <div
            className={`video-container-hlsplayer ${stylesTwo.videoContainer}`}
          >
            <HLSPlayer
              selectedTemplateOption={templateDecider}
              weddingPage={true}
              streamStatus={streamStatus}
              setStreamStatus={setStreamStatus}
            />
          </div>
          {/* <section
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
            id="live"
            className={`${styles.bannerSectionT2} ${styles.eachSectionT2} `}
          >
            <h1 className={styles.bannerHeadingT2}>Live Video</h1>
            <RenderScrollingView idOfflinePortrait={idOfflinePortrait} />
            <div
              className={`video-container-hlsplayer ${stylesTwo.videoContainer}`}
              style={{
                maxWidth: "1020px",
                border: "none",
                boxShadow: "none",
                borderRadius: "none",
              }}
            >
              <HLSPlayer
                selectedTemplateOption="default"
                weddingPage={true}
                streamStatus={streamStatus}
                setStreamStatus={setStreamStatus}
              />
            </div>
          </section> */}
        </>
      );
    } else if (
      singleEventData?.offline_video !== null &&
      singleEventData?.offline_video !== ""
    ) {
      return (
        <>
          <p
            style={{
              color: css?.textColor ? css?.textColor : "black",
              fontFamily: css?.textFontFamily
                ? css?.textFontFamily
                : "Courier New",
              fontSize: css?.headingFontSize ? css?.headingFontSize : "30px",
            }}
            className={stylesTwo.invitationHeading}
          >
            Live Video
          </p>
          <RenderScrollingView />
          <div
            className={`${
              idOfflinePortrait
                ? stylesTwo.videoContainerPortrait
                : stylesTwo.videoContainer
            }`}
          >
            <video
              ref={videoRef}
              style={{ width: "inherit" }}
              src={singleEventData?.offline_video}
              type="video/mp4"
              controls
              onLoadedMetadata={handleOfflineVideoMetadata}
            />
          </div>
          {/* <section
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
            id="live"
            className={`${styles.bannerSectionT2} ${styles.eachSectionT2} `}
          >
            <h1 className={styles.bannerHeadingT2}>Live Video</h1>
            <RenderScrollingView idOfflinePortrait={idOfflinePortrait} />
            <div
              className={`${
                idOfflinePortrait
                  ? stylesTwo.videoContainerPortrait
                  : stylesTwo.videoContainer
              }`}
            >
              <video
                width="100%"
                height="auto"
                controls
                style={{ maxWidth: "1020px" }}
                ref={videoRef}
                src={singleEventData?.offline_video}
                type="video/mp4"
                onLoadedMetadata={handleOfflineVideoMetadata}
              />
            </div>
          </section> */}
        </>
      );
    }
  };

  const renderGallerySection = () => {
    return (
      <div id="gallery" className={stylesTwo.galleryBlock}>
        {singleEventData &&
          Array.isArray(singleEventData.images) &&
          singleEventData.images.length > 0 && (
            <>
              <p
                style={{
                  color: css?.textColor ? css?.textColor : "black",
                  fontFamily: css?.textFontFamily
                    ? css?.textFontFamily
                    : "Courier New",
                  fontSize: css?.headingFontSize
                    ? css?.headingFontSize
                    : "30px",
                }}
                className={stylesTwo.invitationHeading}
              >
                Our Wedding Gallery
              </p>
              <div style={{ width: "100%" }}>
                <FancyBoxGallery />
              </div>
            </>
          )}
      </div>
      // <section
      //   id="gallery"
      //   style={{
      //     paddingTop: "20px",
      //     paddingBottom: "20px",
      //     margin: "50px 0px",
      //   }}
      //   className={styles.eachSectionT2}
      // >
      //   {singleEventData &&
      //     Array.isArray(singleEventData.images) &&
      //     singleEventData.images.length > 0 && (
      //       <>
      //         <h1 className={styles.bannerHeadingT2}>Captured Moment</h1>
      //         <div
      //           style={{
      //             display: "flex",
      //             gap: "25px",
      //             alignItems: "center",
      //             justifyContent: "center",
      //             width: "90%",
      //             margin: "auto",
      //           }}
      //         >
      //           <FancyBoxGallery />
      //         </div>
      //       </>
      //     )}
      // </section>
    );
  };

  const filteredAds =
    ads?.scrolldata?.length > 0
      ? ads.scrolldata.filter((banner) => {
          const expiresOn = new Date(banner?.expires_on); // Convert expires_on to a Date object
          const now = new Date(); // Get the current date and time

          // Reset the time to 00:00:00 for both dates
          expiresOn.setHours(0, 0, 0, 0);
          now.setHours(0, 0, 0, 0);

          // Compare the dates: Include ads that haven't expired or those with no expiration date
          return !banner?.expires_on || expiresOn >= now;
        })
      : [];

  const handleWhatsAppClick = (number) => {
    const phoneNumber = "919030908181"; // Replace with your WhatsApp number (include country code, e.g., 911234567890)
    const message = "Greetings From eventsonlife.com"; // Replace with your default message
    const url = `https://wa.me/${number}?text=${encodeURIComponent(message)}`;
    window.open(url, "_blank"); // Opens WhatsApp in a new tab
  };

  const renderImage = (type, imgLink, altName, link, number) => {
    if (type === "NONE") {
      return (
        <img src={imgLink} alt={altName} className={stylesTwo.bannerImage} />
      );
    } else if (type === "LINK") {
      return (
        <a href={link} target="_blank" rel="noopener noreferrer">
          <img src={imgLink} alt={altName} className={stylesTwo.bannerImage} />
        </a>
      );
    } else if (type === "WHATSAPP") {
      return (
        <img
          src={imgLink}
          alt={altName}
          style={{ cursor: "pointer" }}
          className={stylesTwo.bannerImage}
          onClick={() => {
            handleWhatsAppClick(number);
          }}
        />
      );
    }
  };

  const renderAdv = () => {
    return (
      <div className={stylesTwo.carouselContainer}>
        {filteredAds?.length === 1 ? (
          renderImage(
            filteredAds[0].link_type,
            filteredAds[0].banner,
            filteredAds[0].title,
            filteredAds[0].link,
            filteredAds[0].whatsapp
          )
        ) : (
          <Slider
            key={slideSettings.autoplay ? "autoplay" : "paused"}
            ref={sliderRef}
            {...slideSettings}
          >
            {ads &&
              ads?.scrolldata?.length > 0 &&
              filteredAds?.map((banner, index) => {
                return (
                  <div key={index}>
                    <div
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    >
                      {renderImage(
                        banner.link_type,
                        banner.banner,
                        banner.title,
                        banner.link,
                        banner.whatsapp
                      )}
                    </div>
                  </div>
                );
              })}
          </Slider>
        )}
      </div>
    );
  };

  return (
    <div>
      {!showContent && (
        <div className={styles.doorContainerT2}>
          <div className={styles.doubleDoorContainerT2}>
            <div
              className={`${styles.doorT2} ${styles.leftDoorT2} ${
                isOpen ? styles.openT2 : ""
              }`}
            ></div>
            <div
              className={`${styles.doorT2} ${styles.rightDoorT2} ${
                isOpen ? styles.openT2 : ""
              }`}
            ></div>
            <div className={styles.welcomeTextT2}>
              <h1>Welcome to Events On Life</h1>
            </div>
          </div>
        </div>
      )}
      <>
        {showTemplate ? (
          <>
            {templateDecider === "custom" && (
              // <>
              //   <div className={styles.navbarT2}>
              //     <div className={styles.navLogoContainerT2}>
              //       <a href="/">
              //         <img
              //           src="https://d1gmn9lhl3xm2s.cloudfront.net/static/logo/neolLogo.jpg"
              //           className={styles.navLogoT2}
              //           alt="nav-logo"
              //         />
              //       </a>
              //     </div>
              //     <h1
              //       style={{
              //         background:
              //           "linear-gradient(45deg, rgb(0 0 0), rgb(205 205 205), rgb(217 164 8), rgb(255 173 0)) text",
              //         WebkitBackgroundClip: "text",
              //         color: "transparent",

              //         fontWeight: "bold",
              //         textAlign: "center",
              //         fontFamily: "cursive",
              //       }}
              //       className={styles.fontSize}
              //     >
              //       {capitalizeFirstLetter(singleEventData?.name)}
              //     </h1>
              //   </div>
              //   <div
              //     style={{
              //       padding: "50px",
              //       display: "flex",
              //       flexDirection: "column",
              //       gap: "30px",
              //     }}
              //   >
              //     {renderBannerImage()}
              //     {renderInvitationSection()}
              //     {renderLiveVideoSection()}
              //     {renderGallerySection()}
              //     <RenderFooterSection
              //       idOfflinePortrait={idOfflinePortrait}
              //       css={css}
              //     />
              //     <WhatsAppButton count={count} />
              //   </div>
              // </>
              <div>
                <div
                  style={{
                    display: `${showContent ? "inline" : "none"}`,
                  }}
                  className={stylesTwo.mainContainerForPage}
                >
                  <div>
                    {renderNavigation()}

                    <div style={{ position: "relative" }}>
                      <div
                        style={{
                          backgroundImage:
                            css?.bgColor === "" && css?.bgImage
                              ? `url('${css?.bgImage}')`
                              : undefined,
                          backgroundRepeat: css?.bgImage && "no-repeat",
                          backgroundSize: css?.bgImage && "cover",
                          opacity: css?.bgImage && parseFloat(css?.bgOpacity),
                          position: css?.bgImage && "absolute",
                          height: css?.bgImage && `${containerHeight}px`,
                          width: css?.bgImage && "100%",
                        }}
                      ></div>
                      <div
                        ref={containerRef}
                        style={{
                          minHeight: "100vh",
                          width: "100%",

                          background:
                            css?.bgGradient ||
                            (!css?.bgImage &&
                              !css?.bgColor &&
                              "radial-gradient(circle, #F2C94C, #F2994A)"),

                          backgroundColor: css?.bgColor && css?.bgColor,

                          position: "absolute",
                        }}
                      >
                        {/* {renderAdv()} */}

                        <div className={stylesTwo.templateGlobal}>
                          <p
                            style={{
                              color: css?.textColor ? css?.textColor : "black",
                              fontFamily: css?.textFontFamily
                                ? css?.textFontFamily
                                : "Courier New",
                              margin: "0px",
                              fontsize: "16px",
                            }}
                            className={stylesTwo.invitationHeading}
                          >
                            {capitalizeFirstLetter(singleEventData?.title)}
                          </p>
                          {renderBannerImage()}
                          {/* {/* {renderVenueSection()}} */}
                          {renderInvitationSection()}
                          <div
                            style={{
                              display: "flex",
                              flexDirection: "column",
                              gap: 0,
                            }}
                          >
                            {renderLiveVideoSection()}
                          </div>
                          {renderGallerySection()}
                          <RenderFooterSection
                            idOfflinePortrait={idOfflinePortrait}
                            css={css}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <WhatsAppButton count={count} />
                </div>
                {/* <div
                  style={{
                    // display: `${LoadingViewFor5 ? "flex" : "none"}`,
                    opacity: "1",
                    transition: "opacity 1s ease-in-out",
                  }}
                  className={stylesTwo.loaderContainer}
                >
                  <div className={stylesTwo.loader}></div>
                </div> */}
              </div>
            )}
            {templateDecider === "dafault" && (
              <div
                style={{
                  display: `${showContent ? "inline" : "none"}`,
                }}
                className={`${styles.mainContentT2} ${
                  showContent ? styles.showT2 : ""
                }`}
              >
                <DefaultTemplate
                  setInvitationPopOver={setInvitationPopOver}
                  newdas={newdas}
                  formatTime={formatTime}
                  videoInvitationRef={videoInvitationRef}
                  handleInvitationVideoMetadata={handleInvitationVideoMetadata}
                  idOfflinePortrait={idOfflinePortrait}
                  videoRef={videoRef}
                  handleOfflineVideoMetadata={handleOfflineVideoMetadata}
                  css={css}
                  streamStatus={streamStatus}
                  setStreamStatus={setStreamStatus}
                  selectedTemplateOption={templateDecider}
                />
              </div>
            )}
            {templateDecider === "t2" && (
              <div
                style={{
                  display: `${showContent ? "inline" : "none"}`,
                }}
              >
                <T2Template
                  streamStatus={streamStatus}
                  setStreamStatus={setStreamStatus}
                  selectedTemplateOption={templateDecider}
                  idOfflinePortrait={idOfflinePortrait}
                  videoRef={videoRef}
                  handleOfflineVideoMetadata={handleOfflineVideoMetadata}
                />
              </div>
            )}
            {templateDecider === "t3" && (
              <div
                className={`${stylez.container} ${
                  showContent ? stylez.showT2 : ""
                }`}
              >
                <div className={stylez.nav1}>
                  <div>
                    <a href="/">
                      <img
                        src="https://d1gmn9lhl3xm2s.cloudfront.net/static/logo/neolLogo.jpg"
                        className={stylez.navLogoT2}
                        alt="nav-logo"
                      />
                    </a>
                  </div>
                </div>

                {T3renderBannerImage({ singleEventData })}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "30px",
                  }}
                >
                  {T3renderInvitationSection({
                    singleEventData,
                    setInvitationPopOver,
                    timeLeft,
                  })}
                  {T3renderLiveVideoSection({
                    singleEventData,
                    idOfflinePortrait,
                    streamStatus,
                    setStreamStatus,
                    videoRef,
                    videoRef,
                    handleOfflineVideoMetadata,
                  })}
                  {T3renderGallerySection({ singleEventData })}
                  {T3WhatsAppButton({ count, css })}
                  {T3renderFooterSection()}
                </div>
              </div>
            )}
            {invitationPopOver !== null && (
              <div className={stylesTwo.invitationPopOver}>
                <div>
                  <button
                    style={{
                      position: "absolute",
                      backgroundColor: "red",
                      padding: "5px",
                      borderRadius: "5px",
                      border: "none",
                      color: "white",
                      top: "10px",
                      right: "10px",
                      cursor: "pointer",
                    }}
                    className={stylesTwo.closeIcon}
                    onClick={(e) => {
                      e.stopPropagation();
                      setInvitationPopOver(null);
                    }}
                  >
                    <X />
                  </button>
                </div>
                <img
                  src={invitationPopOver}
                  alt="overlayImage"
                  style={{
                    maxWidth: "100vw",
                    maxHeight: "100vh",
                    objectFit: "contain",
                    border: templateDecider === "dafault" && "30px white solid",
                    boxShadow:
                      templateDecider === "dafault" &&
                      "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                  }}
                />
              </div>
            )}
          </>
        ) : (
          <div>
            <div
              style={{
                backgroundColor: css?.bgColor,
                opacity: css?.opacity,
              }}
              className={stylesTwo.modalOverlay}
            >
              <div className={stylesTwo.modalContent}>
                <h1 className={stylesTwo.modalTitle}>
                  This event is password protected
                </h1>
                <h2 className={stylesTwo.modalSubTitle}>
                  Please Enter Password
                </h2>
                <input
                  type="text"
                  placeholder="Enter password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className={stylesTwo.inputField}
                />
                {error && <p className={stylesTwo.errorMessage}>{error}</p>}
                <button
                  onClick={handleSecureValidation}
                  className={stylesTwo.submitBtn}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        )}
      </>
    </div>
  );
};

export default WeddingTemplate;
