import { useCallback, useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import HLSPlayer from "components/hlsPlayer";
import { useAppDataContext } from "hooks/useappdatacontext";
import { FaLocationDot, FaWhatsapp } from "react-icons/fa6";
import { X, CalendarHeart, Clock, MapPinned } from "lucide-react";
import Slider from "react-slick";
import { ReactComponent as ViewerIcon } from "../../../assets/images/viwerCount.svg";
import styles from "./t3.module.css";
import NavBar from "components/navBar/navBar";
import T3FancyBoxGallery from "../../../components/fancyBoxGallery/T3fancyBoxGallery";
import { extractHlsUrl, extractYouTubeLiveId, extractYouTubeVideoId } from "pages/weddingTemplate/comonFunctions";
// import LoadingView from "./LoadingView";
const renderScrollingView = ( mess, idOfflinePortrait ) => {
  return (
    <>
      {mess?.length > 0 && (
        <div
          className={`${styles.advertisementContainer} ${idOfflinePortrait
            ? styles.advertismentL
            : styles.advertismentP
            }`}
        >
          <div className={styles.advertisement}>
            <div>{mess}</div>
          </div>
        </div>
      )}
    </>
  );
};

export const T3capitalizeFirstLetter = (str) => {
  return str
    ?.split(" ") // Split the string into words by space
    ?.map(
      (word) => word?.charAt(0).toUpperCase() + word?.slice(1).toLowerCase()
    ) // Capitalize first letter of each word
    ?.join(" "); // Join the words back together with space
};
export const T3renderBannerImage = ({ singleEventData }) => {

  const fontSize =
    singleEventData.title && singleEventData.title.length > 0 && singleEventData.title.length < 10
      ? "40px"
      : singleEventData.title && singleEventData.title.length > 20 && singleEventData.title.length < 40
        ? "20px"
        : singleEventData.title && singleEventData.title.length >= 40
          ? "16px"
          : "30px";

  return (
    <section
      id="banner"

    >{
        (singleEventData && singleEventData.banner !== "" && singleEventData.banner !== null && singleEventData.banner !== undefined)
          ? (<div className={styles.bgbanner}
            style={{
              backgroundImage: `url(${singleEventData.banner})`, // Ensure correct property access
            }}>

            <h1 style={{ fontSize }}>{T3capitalizeFirstLetter(singleEventData?.title)}</h1>
          </div>)
          : <div style={{ width: "100%", textAlign: "center" }}>
            <h1 style={{ fontSize }}>{T3capitalizeFirstLetter(singleEventData?.title)}</h1>
          </div>
      }

    </section>
  );
};
export const T3WhatsAppButton = ({count,css}) => {
  const handleWhatsAppClick = () => {
    const phoneNumber = "919030908181"; // Replace with your WhatsApp number (include country code, e.g., 911234567890)
    const message = "Greetings From eventsonlife.com"; // Replace with your default message
    const url = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;
    window.open(url, "_blank"); // Opens WhatsApp in a new tab
  };

  return (
    <div
      style={{
        position: "fixed",
        bottom: "20px",
        right: "20px",
        display: "flex",
        alignItems: "center",
        gap: "10px",
      }}
    >
      {count > 0 && (
        <div
          style={{
            color: css?.textColor ? css?.textColor : "black",
            fontFamily: "Arial, sans-serif",
            fontSize: "18px",
            backgroundColor: "#f3f4f6",
            border: "1px solid #ccc",
            borderRadius: "8px",
            padding: "6px 5px",
            display: "flex",
            alignItems: "center",
            gap: "5px",
            boxShadow: "2px 2px 6px rgba(0, 0, 0, 0.1)",
          }}
        >
          <ViewerIcon
            style={{ borderRadius: "50%", width: "30px", height: "32px" }}
          />
          : <strong style={{ color: "#ff5722" }}>{count}</strong>
        </div>
      )}

      <button
        onClick={handleWhatsAppClick}
        style={{
          backgroundColor: "#25D366",
          color: "#fff",
          border: "none",
          borderRadius: "50%",
          width: "60px",
          height: "60px",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
          cursor: "pointer",
          fontSize: "24px",
        }}
      >
        <FaWhatsapp style={{ fontSize: "24px" }} />
      </button>
    </div>
  );
};
export const T3renderInvitationSection = ({ singleEventData, setInvitationPopOver, timeLeft }) => {

  return (
    <>
      {singleEventData && singleEventData.invitation_card ? (
        <div id="invitation">
          <div>
            {singleEventData?.invitation_card && (
              <div className={styles.invi1bg}>
                <div className={styles.invi1headings}>
                  <h1>Wedding Info</h1>
                  <img src="https://preview.colorlib.com/theme/wed/assets/img/gallery/tittle_img.png.webp" alt="" />
                </div>
                <img
                  style={{
                    borderRadius: "10px",
                  }}
                  className={styles.t3invitaitonImage}
                  src={singleEventData?.invitation_card}
                  alt="Invitation card"
                  onClick={() =>
                    setInvitationPopOver(singleEventData?.invitation_card)
                  }
                />
              </div>
            )}
          </div>
        </div>
      ) : (
        <div className={styles.invi1bg}>
          <div className={styles.invi1headings}>
            <h1>Wedding Info</h1>
            <img src="https://preview.colorlib.com/theme/wed/assets/img/gallery/tittle_img.png.webp" alt="" />
          </div>
          <div className={styles.invi1content}>
            <div className={styles.detailscontainer}>
              <div className={styles.detailbox}>
                <CalendarHeart className={styles.detailboxicon} />
                <h2>Date</h2>
                <p>{singleEventData?.venue_date}</p>
              </div>
              <div className={styles.detailbox}>
                <Clock className={styles.detailboxicon} />

                <h2>Time</h2>
                <p>{singleEventData?.venue_time}</p>
              </div>
              <div className={styles.detailbox}>
                <MapPinned className={styles.detailboxicon} />

                <h2>Venue</h2>
                <p>{singleEventData?.venue}</p>
              </div>
            </div>
            <p>{singleEventData?.template_desc || "All are Invited"}</p>
            <div className={styles.countdowncontainer}>
              <div className={styles.countdownbox}>
                <p>{timeLeft.days}</p>
                <span>Days</span>
              </div>
              <div className={styles.countdownbox}>
                <p>{timeLeft.hours}</p>
                <span>Hours</span>
              </div>
              <div className={styles.countdownbox}>
                <p>{timeLeft.minutes}</p>
                <span>Mins</span>
              </div>
              <div className={styles.countdownbox}>
                <p>{timeLeft.seconds}</p>
                <span>Secs</span>
              </div>
            </div>
          </div>
        </div>
      )}
      {singleEventData && singleEventData?.invitation_video && (
        <>
          <div className={styles.invivideo}>
            <div className={styles.invi1headings}>
              <h1>Invitation Video</h1>
              <img src="https://preview.colorlib.com/theme/wed/assets/img/gallery/tittle_img.png.webp" alt="Divider" />
            </div>
            <div className={styles.videocontainer}>
              <video controls>
                <source src={singleEventData?.invitation_video} type="video/mp4" />
                Your browser does not support the video tag.
              </video>
            </div>
          </div>
        </>
      )}

    </>
  );
};
export const T3renderLiveVideoSection = ({ singleEventData, idOfflinePortrait, streamStatus, setStreamStatus, videoRef, handleOfflineVideoMetadata }) => {
  const videoId = extractYouTubeVideoId(singleEventData?.live_vidlink);
  const liveId = extractYouTubeLiveId(singleEventData?.live_vidlink);
  const hlsUrl = extractHlsUrl(singleEventData?.live_vidlink);
  const  mess= singleEventData ? singleEventData?.scrolling_mess : ""
  if (videoId) {
    return (
      <>
        <section
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
          id="live"
          className={`${styles.bannerSectionT2}`}
        >
          <div className={styles.invi1headings}>
            <h1>Live video</h1>
            <img src="https://preview.colorlib.com/theme/wed/assets/img/gallery/tittle_img.png.webp" alt="" />
          </div>
          {renderScrollingView(mess, idOfflinePortrait)}
          <div className={styles.videoContainer}>
            <iframe
              style={{ width: "100%", height: "inherit" }}
              src={`https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="YouTube Video"
            ></iframe>
          </div>
        </section>
      </>
    );
  } else if (liveId) {
    return (
      <>
        <section
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
          id="live"
          className={`${styles.bannerSectionT2} `}
        >
          <div className={styles.invi1headings}>
            <h1>Live video</h1>
            <img src="https://preview.colorlib.com/theme/wed/assets/img/gallery/tittle_img.png.webp" alt="" />
          </div>
          {renderScrollingView(mess, idOfflinePortrait)}
          <div className={styles.videoContainer}>
            <iframe
              style={{ width: "100%", height: "inherit" }}
              src={`https://www.youtube.com/embed/${liveId}?autoplay=1&mute=1`}
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
              title="Live"
            ></iframe>
          </div>
        </section>
      </>
    );
  } else if (hlsUrl) {
    return (
      <>
        <section
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
          id="live"
          className={`${styles.bannerSectionT2}`}
        >
          <div className={styles.invi1headings}>
            <h1>Live video</h1>
            <img src="https://preview.colorlib.com/theme/wed/assets/img/gallery/tittle_img.png.webp" alt="" />
          </div>
          {renderScrollingView(mess, idOfflinePortrait)}
          <div
            className={`video-container-hlsplayer ${styles.videocontainer}`}
          >
            <HLSPlayer
            selectedTemplateOption="t3"
              weddingPage={true}
              streamStatus={streamStatus}
              setStreamStatus={setStreamStatus}
            />
          </div>
        </section>
      </>
    );
  } else if (
    singleEventData?.offline_video !== null &&
    singleEventData?.offline_video !== ""
  ) {
    return (
      <>
        <section
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
          id="live"
          className={`${styles.bannerSectionT2}`}
        >
          <h1 className={styles.bannerHeadingT2}>Live Video</h1>
          {renderScrollingView(mess, idOfflinePortrait)}
          <div
            className={`${idOfflinePortrait
              ? styles.videoContainerPortrait
              : styles.videoContainer
              }`}
          >
            <video
              width="100%"
              height="auto"
              controls
              style={{ maxWidth: "1020px" }}
              ref={videoRef}
              src={singleEventData?.offline_video}
              type="video/mp4"
              onLoadedMetadata={handleOfflineVideoMetadata}
            />
          </div>
        </section>
      </>
    );
  }
};

export const T3renderGallerySection = ({ singleEventData }) => {
  return (

    <section
      id="gallery"
      style={{
        paddingTop: "20px",
        paddingBottom: "20px",
        margin: "50px 0px",
      }}
    >
      {singleEventData &&
        Array.isArray(singleEventData.images) &&
        singleEventData.images.length > 0 && (
          <>
            <div className={styles.invi1headings}>
              <h1>Gallery</h1>
              <img src="https://preview.colorlib.com/theme/wed/assets/img/gallery/tittle_img.png.webp" alt="" />
            </div>
            <div
              style={{
                display: "flex",
                gap: "25px",
                alignItems: "center",
                justifyContent: "center",
                width: "90%",
                margin: "auto",
              }}
            >
              <T3FancyBoxGallery />
            </div>
          </>
        )}
    </section>
  );
};

export const T3renderFooterSection = () => {
  const currentYear = new Date().getFullYear();

  return (
    <footer className={styles.L302F}>
      <p>
        &copy; {currentYear}, Made with ❤️ by <strong>Infinitum Tech</strong>
      </p>
    </footer>
  );
};
const T3 = () => {
  // contexts
  const { getEvent, singleEventData, setShowLoader, getAds, ads } =
    useAppDataContext();
  console.log(singleEventData);

  const settings = {
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: true,
    dots: false,
    speed: 1000,
    autoplaySpeed: ads?.delaytime * 1000 || 2000,
    autoplay: true,
  };
  const [streamStatus, setStreamStatus] = useState("");

  // states
  const [showTemplate, setShowTemplate] = useState(true);
  const [newdas, setnewdas] = useState([]);
  const [password, setPassword] = useState("");
  const [error, setError] = useState("");
  const [LoadingViewFor5, setLoadingViewFor5] = useState(true);
  const [id, setID] = useState("test3");
  const videoRef = useRef(null);
  const videoInvitationRef = useRef(null);

  const [idOfflinePortrait, setIdOfflinePortrait] = useState(false);
  const [idInvitationPortrait, setIdInvitationPortrait] = useState(false);
  const [css, setCss] = useState({});
  const [invitationPopOver, setInvitationPopOver] = useState(null);

  const containerRef = useRef(null); // Reference to the container
  const [containerHeight, setContainerHeight] = useState(0);
  const [count, setCount] = useState([]);

  const [isOpen, setIsOpen] = useState(false);
  const [showContent, setShowContent] = useState(false);
  const v_date = newdas?.venue_date;
  const v_time = newdas?.venue_time;

  useEffect(() => {
    setIsOpen(true);
  }, []);

  useEffect(() => {
    if (isOpen) {
      setTimeout(() => {
        // Trigger the fade-out of h1 after the door animation
        const h1 = document.querySelector("h1");
        h1.style.animation = "fadeOut 2s forwards";

        // After h1 fades out, show the main content
        setTimeout(() => setShowContent(true), 2000); // Wait for the h1 fade out
      }, 1500); // Wait for doors to open
    }
  }, [isOpen]);

  const calculateTimeLeft = () => {
    const eventDateTime = new Date(`${singleEventData?.venue_date}T${singleEventData.venue_time}`).getTime();
    const now = new Date().getTime();
    const difference = eventDateTime - now;

    if (difference <= 0) return { days: 0, hours: 0, minutes: 0, seconds: 0 };

    return {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [singleEventData?.venue_date, singleEventData?.venue_time]);

  const [progress, setProgress] = useState(0);

  useEffect(() => {
    // Calculate the progress (percentage of time left)
    setProgress(((60 - timeLeft.seconds) / 60) * 100);
  }, [timeLeft]);

  const getVisitorsCount = async () => {
    let url = `${process.env.REACT_APP_BASE_URL}/events/get_event_visitors/`;
    if (id) {
      try {
        let res = await fetch(url, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ prlink: id }),
        });
        if (res.ok) {
          const { visitors } = await res.json();
          setCount(visitors);
        }
      } catch (e) {
        console.error(e.message);
      }
    }
  };

  const handlerToIncreaseCount = async () => {
    const eventId = localStorage.getItem(`eventsentID:${id}`);

    if (eventId === null && id) {
      const url = `${process.env.REACT_APP_BASE_URL}/events/increase_visitors/`;
      localStorage.setItem(`eventsentID:${id}`, true);
      try {
        let r = await fetch(url, {
          method: "POST",
          headers: { "Content-Type": "application/json" },
          body: JSON.stringify({ prlink: id }),
        });
      } catch (e) {
        console.error(e.message);
      }
    }
  };

  useEffect(() => {
    getVisitorsCount();
    handlerToIncreaseCount();
  }, [id]);

  useEffect(() => {
    const fetchAds = async () => {
      try {
        await getAds(); // Assuming getAds is an API call function
      } catch (error) {
        console.error("Error fetching ads:", error);
      }
    };

    fetchAds();
  }, []);
  // const [isHovered, setIsHovered] = useState(false);
  const [slideSettings, setSettings] = useState({ ...settings });
  const sliderRef = useRef(null);

  const handleMouseEnter = useCallback(() => {
    // Stop autoplay on hover
    setSettings((prevSettings) => ({
      ...prevSettings,
      autoplay: false,
    }));
  }, []);

  const handleMouseLeave = useCallback(() => {
    // Start autoplay on mouse leave
    setSettings((prevSettings) => ({
      ...prevSettings,
      autoplay: true,
    }));
  }, []);

  useEffect(() => {
    if (ads?.delaytime) {
      setSettings((prevSettings) => ({
        ...prevSettings,
        autoplaySpeed: ads.delaytime * 1000, // Update speed in milliseconds
      }));
    }
  }, [ads?.delaytime]);

  const calculateHeight = () => {
    if (containerRef.current) {
      setContainerHeight(containerRef.current.scrollHeight);
    }
  };

  const updateHeightSmoothly = () => {
    requestAnimationFrame(() => {
      calculateHeight();
    });
  };

  useEffect(() => {
    calculateHeight();

    const handleResize = () => {
      updateHeightSmoothly();
    };
    window.addEventListener("resize", handleResize);

    const observer = new MutationObserver(updateHeightSmoothly);
    if (containerRef.current) {
      observer.observe(containerRef.current, {
        childList: true,
        subtree: true,
      });
    }

    return () => {
      window.removeEventListener("resize", handleResize);
      if (observer) {
        observer.disconnect();
      }
    };
  }, [singleEventData]);

  updateHeightSmoothly();

  // const params = useParams();

  // useEffect(() => {
  //   // Set the decoded ID
  //   setID(params.id);
  // }, [params.id]);

  useEffect(() => {
    let timer = setTimeout(() => {
      setLoadingViewFor5(false);
    }, 4500);

    return () => {
      clearTimeout(timer);
    };
  }, []);


  useEffect(() => {
    const getSingleEvent = async () => {
      setShowLoader(true);
      const das = await getEvent(id);
      if (das) {
        setnewdas(newdas);
      }
      setShowLoader(false);
      if (das && newdas.is_secure === true) {
        setShowTemplate(false);
      }
    };
    if (id) {
      getSingleEvent();
    }
  }, [id]);

  useEffect(() => {
    if (
      singleEventData?.template_css !== "" &&
      singleEventData?.template_css !== undefined
    ) {
      const jsonString = singleEventData?.template_css;
      setCss(JSON.parse(jsonString));
    }
  }, [singleEventData]);

  // Handle video dimensions after metadata is loaded
  const handleVideoMetadata = (ref, setPortraitState) => {
    if (ref.current) {
      const videoWidth = ref.current.videoWidth;
      const videoHeight = ref.current.videoHeight;
      setPortraitState(videoHeight > videoWidth);
    }
  };

  // When videoRef metadata is loaded
  const handleOfflineVideoMetadata = () => {
    handleVideoMetadata(videoRef, setIdOfflinePortrait);
  };

  // When videoInvitationRef metadata is loaded
  const handleInvitationVideoMetadata = () => {
    handleVideoMetadata(videoInvitationRef, setIdInvitationPortrait);
  };

  const handleSecureValidation = () => {
    if (password === newdas.password) {
      setShowTemplate(true);
    } else {
      setError("Password Invalid");
    }
  };


  





  const renderNavigation = () => {
    return (
      <>
        {/* <NavBar /> */}
        <h1>{singleEventData?.title}</h1>
      </>
    );
  };





  const filteredAds =
    ads?.scrolldata?.length > 0
      ? ads.scrolldata.filter((banner) => {
        const expiresOn = new Date(banner?.expires_on); // Convert expires_on to a Date object
        const now = new Date(); // Get the current date and time

        // Reset the time to 00:00:00 for both dates
        expiresOn.setHours(0, 0, 0, 0);
        now.setHours(0, 0, 0, 0);

        // Compare the dates: Include ads that haven't expired or those with no expiration date
        return !banner?.expires_on || expiresOn >= now;
      })
      : [];

  const handleWhatsAppClick = (number) => {
    const phoneNumber = "919030908181"; // Replace with your WhatsApp number (include country code, e.g., 911234567890)
    const message = "Greetings From eventsonlife.com"; // Replace with your default message
    const url = `https://wa.me/${number}?text=${encodeURIComponent(message)}`;
    window.open(url, "_blank"); // Opens WhatsApp in a new tab
  };

  

  const renderImage = (type, imgLink, altName, link, number) => {
    if (type === "NONE") {
      return (
        <img src={imgLink} alt={altName} className={styles.bannerImage} />
      );
    } else if (type === "LINK") {
      return (
        <a href={link} target="_blank" rel="noopener noreferrer">
          <img src={imgLink} alt={altName} className={styles.bannerImage} />
        </a>
      );
    } else if (type === "WHATSAPP") {
      return (
        <img
          src={imgLink}
          alt={altName}
          style={{ cursor: "pointer" }}
          className={styles.bannerImage}
          onClick={() => {
            handleWhatsAppClick(number);
          }}
        />
      );
    }
  };

  const renderAdv = () => {
    return (
      <div className={styles.carouselContainer}>
        {filteredAds?.length === 1 ? (
          renderImage(
            filteredAds[0].link_type,
            filteredAds[0].banner,
            filteredAds[0].title,
            filteredAds[0].link,
            filteredAds[0].whatsapp
          )
        ) : (
          <Slider
            key={slideSettings.autoplay ? "autoplay" : "paused"}
            ref={sliderRef}
            {...slideSettings}
          >
            {ads &&
              ads?.scrolldata?.length > 0 &&
              filteredAds?.map((banner, index) => {
                return (
                  <div key={index}>
                    <div
                      onMouseEnter={handleMouseEnter}
                      onMouseLeave={handleMouseLeave}
                    >
                      {renderImage(
                        banner.link_type,
                        banner.banner,
                        banner.title,
                        banner.link,
                        banner.whatsapp
                      )}
                    </div>
                  </div>
                );
              })}
          </Slider>
        )}
      </div>
    );
  };


  return (
    <div className={`${showContent && styles.container}`}>
      {!showContent && (
        <div className={styles.doorContainerT2}>
          <div className={styles.doubleDoorContainerT2}>
            <div
              className={`${styles.doorT2} ${styles.leftDoorT2} ${isOpen ? styles.openT2 : ""
                }`}
            ></div>
            <div
              className={`${styles.doorT2} ${styles.rightDoorT2} ${isOpen ? styles.openT2 : ""
                }`}
            ></div>
            <div className={styles.welcomeTextT2}>
              <h1>Welcome to Events On Life</h1>
            </div>
          </div>
        </div>
      )}
      <>
        {showTemplate ? (
          <div
            className={`${styles.container} ${showContent ? styles.showT2 : ""
              }`}
          >
            <div className={styles.nav1}>
              <div>
                <a href="/">
                  <img
                    src="https://d1gmn9lhl3xm2s.cloudfront.net/static/logo/neolLogo.jpg"
                    className={styles.navLogoT2}
                    alt="nav-logo"
                  />
                </a>
              </div>
              {/* <h1
                style={{
                  background: "linear-gradient(45deg, rgb(0, 0, 0), rgb(205, 205, 205), rgb(217, 164, 8), rgb(255, 173, 0))",
                  WebkitBackgroundClip: "text",
                  WebkitTextFillColor: "transparent",
                  fontWeight: "bold",
                  textAlign: "center",
                  fontFamily: "cursive",
                }}
                className={styles.fontSize}
              >
                {T3capitalizeFirstLetter(singleEventData?.name)}
              </h1> */}
            </div>

            {T3renderBannerImage({ singleEventData })}
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "30px",
              }}
            >
              {T3renderInvitationSection({ singleEventData, setInvitationPopOver, timeLeft })}
              {T3renderLiveVideoSection({ singleEventData, idOfflinePortrait, streamStatus, setStreamStatus, videoRef, videoRef,handleOfflineVideoMetadata })}
              {T3renderGallerySection({ singleEventData })}
              {T3WhatsAppButton({count,css})}
              {T3renderFooterSection()}
            </div>
            {invitationPopOver !== null && (
              <div className={styles.invitationPopOver}>
                <div>
                  <button
                    style={{
                      position: "absolute",
                      backgroundColor: "red",
                      padding: "5px",
                      borderRadius: "5px",
                      border: "none",
                      color: "white",
                      top: "10px",
                      right: "10px",
                      cursor: "pointer",
                    }}
                    className={styles.closeIcon}
                    onClick={(e) => {
                      e.stopPropagation();
                      setInvitationPopOver(null);
                    }}
                  >
                    <X />
                  </button>
                </div>
                <img
                  src={invitationPopOver}
                  alt="overlayImage"
                  style={{
                    maxWidth: "100vw",
                    maxHeight: "100vh",
                    objectFit: "contain",
                    border: "30px white solid",
                    boxShadow: "rgba(0, 0, 0, 0.35) 0px 5px 15px",
                  }}
                />
              </div>
            )}
          </div>
        ) : (
          <div>
            <div
              style={{
                backgroundColor: css?.bgColor,
                opacity: css?.opacity,
              }}
              className={styles.modalOverlay}
            >
              <div className={styles.modalContent}>
                <h1 className={styles.modalTitle}>
                  This event is password protected
                </h1>
                <h2 className={styles.modalSubTitle}>
                  Please Enter Password
                </h2>
                <input
                  type="text"
                  placeholder="Enter password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  className={styles.inputField}
                />
                {error && <p className={styles.errorMessage}>{error}</p>}
                <button
                  onClick={handleSecureValidation}
                  className={styles.submitBtn}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        )}
      </>
    </div>
  );
};

export default T3;
