import styles from "../Custom/T2/styles.module.css";
import stylesTwo from "./weddingTemplate.module.css";
import { useAppDataContext } from "hooks/useappdatacontext";
import { motion } from "framer-motion";
import { FaLocationDot } from "react-icons/fa6";
import { useState, useEffect } from "react";
import {
  capitalizeFirstLetter,
  extractHlsUrl,
  extractYouTubeLiveId,
  extractYouTubeVideoId,
} from "./comonFunctions";
import { RenderScrollingView } from "./scrollingAds";
import HLSPlayer from "components/hlsPlayer";
import { RenderFooterSection } from "./fotterSection";
import { WhatsAppButton } from "./whatsAppSection";
import DefaultGallery from "../../components/fancyBoxGallery/DefaultGallery";

const DefaultTemplate = ({
  setInvitationPopOver,
  newdas,
  formatTime,
  videoInvitationRef,
  handleInvitationVideoMetadata,
  handleOfflineVideoMetadata,
  idOfflinePortrait,
  videoRef,
  css,
  streamStatus,
  setStreamStatus,
  selectedTemplateOption,
}) => {
  const { singleEventData } = useAppDataContext();

  const calculateTimeLeft = () => {
    const eventDateTime = new Date(
      `${singleEventData?.venue_date}T${singleEventData?.venue_time}`
    ).getTime();
    const now = new Date().getTime();
    const difference = eventDateTime - now;

    if (difference <= 0) return { days: 0, hours: 0, minutes: 0, seconds: 0 };

    return {
      days: Math.floor(difference / (1000 * 60 * 60 * 24)),
      hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
      minutes: Math.floor((difference / 1000 / 60) % 60),
      seconds: Math.floor((difference / 1000) % 60),
    };
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, [singleEventData]);

  const renderBannerImage = () => {
    const fontSize =
      singleEventData?.title &&
      singleEventData?.title.length > 20 &&
      singleEventData?.title.length < 40
        ? "20px"
        : singleEventData?.title && singleEventData?.title.length >= 40
        ? "16px"
        : "24px";
    return (
      singleEventData &&
      singleEventData?.banner && (
        <motion.section
          initial={{ opacity: 0, y: -150 }} // Start hidden and above
          animate={{ opacity: 1, y: 0 }} // Fade in and slide down
          transition={{ duration: 1.5, delay: 0.5, ease: "easeOut" }}
          id="banner"
          className={`${styles.bannerSectionT2} ${styles.eachSectionT2}`}
        >
          <h1 className={styles.bannerHeadingT2}>Banner</h1>
          {/* <img
                src={singleEventData?.banner}
                alt="Banner_image"
                onClick={() => setInvitationPopOver(singleEventData?.banner)}
                style={{ width: "90%", maxWidth: "1020px" }}
              /> */}
          <motion.img
            src={singleEventData?.banner}
            alt="Banner_image"
            onClick={() => setInvitationPopOver(singleEventData?.banner)}
            style={{ width: "90%", maxWidth: "1020px" }}
            whileHover={{ scale: 1.05, transition: { duration: 0.3 } }} // Add a hover effect
          />
        </motion.section>
      )
    );
  };

  const renderInvitationSection = () => {
    return (
      <>
        {singleEventData && singleEventData.invitation_card ? (
          <div id="invitation" className={styles.eachSectionT2}>
            <h1 className={styles.bannerHeadingT2}>Invitation Card</h1>

            <div>
              {singleEventData?.invitation_card && (
                <motion.img
                  style={{
                    borderRadius: "10px",
                  }}
                  className={stylesTwo.invitaitonImage}
                  src={singleEventData?.invitation_card}
                  alt="Invitation card"
                  onClick={() =>
                    setInvitationPopOver(singleEventData?.invitation_card)
                  }
                  whileHover={{ scale: 1.05, transition: { duration: 0.3 } }}
                />
              )}
            </div>
          </div>
        ) : (
          <section
            id="invitationCard"
            className={`${styles.staticHeroS2T2} ${styles.eachSectionT2}`}
          >
            <h1 className={styles.bannerHeadingT2}>Invitation Card</h1>
            <div className={styles.staticMainBoxT2}>
              <div className={styles.containerFluidT2}>
                <div className={styles.staticInnerBoxT2}>
                  <div className={styles.rowT2}>
                    <div className={styles.invitationCardT2}>
                      <div className={styles.wpoStaticHeroTextWrapT2}>
                        <div className={styles.wpoStaticHeroTextBoxT2}>
                          <div className={styles.slideTitleT2}>
                            <div
                              className={`${styles.poortTextT2} ${styles.poortInUpT2}`}
                              style={{ perspective: "600px" }}
                            >
                              <h2
                                className={styles.poortLineT2}
                                style={{
                                  display: "block",
                                  textAlign: "center",
                                  position: "relative",
                                  fontFamily: "'Dancing Script', cursive",
                                }}
                              >
                                {singleEventData?.title}
                              </h2>
                            </div>
                          </div>

                          <div
                            className={styles.slideTextT2}
                            data-wow-duration="1600ms"
                            style={{
                              visibility: "visible",
                              animationDuration: "1600ms",
                              animationName: "fadeInUp",
                            }}
                          >
                            <p>We Are Getting Married In</p>
                          </div>

                          <p>On</p>

                          {newdas.venue_date && (
                            <div
                              className={styles.slideDateT2}
                              data-wow-duration="1700ms"
                              style={{
                                visibility: "visible",
                                animationDuration: "1700ms",
                                animationName: "fadeInUp",
                                display: "flex",
                                alignItems: "center",
                                gap: "14px",
                              }}
                            >
                              <span
                                style={{
                                  color: "rgb(113 108 83)",
                                  fontWeight: "700",
                                  fontSize: "1rem",
                                }}
                              >
                                Date:
                              </span>
                              {/* <p>04 . 03 . 2024</p> */}
                              <p>
                                {new Date(newdas.venue_date).toLocaleDateString(
                                  "en-GB",
                                  {
                                    day: "2-digit",
                                    month: "short",
                                    year: "numeric",
                                  }
                                )}
                              </p>
                            </div>
                          )}

                          {newdas.live_time && (
                            <div
                              className={styles.slideTimeT2}
                              data-wow-duration="1700ms"
                              style={{
                                visibility: "visible",
                                animationDuration: "1700ms",
                                animationName: "fadeInUp",
                                display: "flex",
                                gap: "14px",
                                alignItems: "center",
                              }}
                            >
                              <span
                                style={{
                                  color: "rgb(113 108 83)",
                                  fontWeight: "700",
                                  fontSize: "1rem",
                                }}
                              >
                                Time:
                              </span>
                              {/* <p>04 : 00 : 00 PM</p> */}
                              <p>{formatTime(newdas.live_time)}</p>
                            </div>
                          )}
                          {newdas.venue && (
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                gap: "4px",
                              }}
                            >
                              <p
                                className={styles.cedarvilleCursiveRegularT2}
                                style={{ maxWidth: "250px", overflowX: "auto" }}
                              >
                                <FaLocationDot style={{ marginTop: "2px" }} />{" "}
                                {newdas.venue}
                              </p>
                            </div>
                          )}

                          <div className={styles.clearFixT2}></div>
                        </div>

                        {/* <div
                        className="shape wow fadeInLeftSlow"
                        data-wow-duration="2500ms"
                        style={{
                          visibility: "visible",
                          animationDuration: "2500ms",
                          animationName: "fadeInLeftSlow",
                        }}
                      >
                        <img
                          src="https://wpocean.com/html/tf/sukun/assets/images/slider/left-shape-1.png"
                          alt=""
                        />
                      </div>

                      <div
                        className="shape2 wow fadeInRightSlow"
                        data-wow-duration="2500ms"
                        style={{
                          visibility: "visible",
                          animationDuration: "2500ms",
                          animationName: "fadeInRightSlow",
                        }}
                      >
                        <img
                          src="https://wpocean.com/html/tf/sukun/assets/images/slider/left-shape-2.png"
                          alt=""
                        />
                      </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        )}
        {singleEventData && singleEventData?.invitation_video && (
          <>
            <section
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
              }}
              id="live"
              className={`${styles.bannerSectionT2} ${styles.eachSectionT2}`}
            >
              <h1 className={styles.bannerHeadingT2}>Invitation Video</h1>
              <video
                width="90%"
                height="auto"
                controls
                autoPlay
                loop
                ref={videoInvitationRef}
                className={styles.videoT2}
                style={{ maxWidth: "1020px" }}
                onLoadedMetadata={handleInvitationVideoMetadata}
              >
                <source
                  src={singleEventData?.invitation_video}
                  type="video/mp4"
                />
              </video>
            </section>
          </>
        )}
      </>
    );
  };

  const renderTimeLeftSection = () => {
    return (
      <section
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
        className={`${styles.bannerSectionT2} ${styles.eachSectionT2}`}
      >
        <h1 className={styles.bannerHeadingT2}>Time Left</h1>
        {/* {timeLeft.days !== 0 &&
        timeLeft.hours !== 0 &&
        timeLeft.minutes !== 0 &&
        timeLeft.seconds !== 0 ? ( */}
        <div className={styles.countdownContainerT2}>
          <div className={styles.countdownBoxT2}>
            <p style={{ color: timeLeft.days > 10 ? "#574444" : "green" }}>
              {timeLeft.days}
            </p>
            <span style={{ color: timeLeft.days > 10 ? "#574444" : "green" }}>
              Days
            </span>
          </div>
          <div className={styles.countdownBoxT2}>
            <p style={{ color: timeLeft.hours > 10 ? "#574444" : "green" }}>
              {timeLeft.hours}
            </p>
            <span style={{ color: timeLeft.hours > 10 ? "#574444" : "green" }}>
              Hours
            </span>
          </div>

          <div className={styles.countdownBoxT2}>
            <p style={{ color: timeLeft.minutes > 10 ? "#574444" : "green" }}>
              {timeLeft.minutes}
            </p>
            <span
              style={{ color: timeLeft.minutes > 10 ? "#574444" : "green" }}
            >
              Mins
            </span>
          </div>
          <div className={styles.countdownBoxT2}>
            <p style={{ color: timeLeft.seconds > 10 ? "#574444" : "green" }}>
              {timeLeft.seconds}
            </p>
            <span
              style={{ color: timeLeft.seconds > 10 ? "#574444" : "green" }}
            >
              Secs
            </span>
          </div>
        </div>
        {/*  ) : ( // <h1 className={styles.bannerHeadingT2}>Event Complelted</h1>
         )} */}
      </section>
    );
  };

  const renderLiveVideoSection = () => {
    const videoId = extractYouTubeVideoId(singleEventData?.live_vidlink);
    const liveId = extractYouTubeLiveId(singleEventData?.live_vidlink);
    const hlsUrl = extractHlsUrl(singleEventData?.live_vidlink);

    if (videoId) {
      return (
        <>
          <section
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
            id="live"
            className={`${styles.bannerSectionT2} ${styles.eachSectionT2} `}
          >
            <h1 className={styles.bannerHeadingT2}>Live Video</h1>
            <RenderScrollingView idOfflinePortrait={idOfflinePortrait} />
            <div className={stylesTwo.videoContainerIframe}>
              <iframe
                style={{ width: "100%", height: "100%" }}
                src={`https://www.youtube.com/embed/${videoId}?autoplay=1&mute=1`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="YouTube Video"
              ></iframe>
            </div>
          </section>
        </>
      );
    } else if (liveId) {
      return (
        <>
          <section
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
            id="live"
            className={`${styles.bannerSectionT2} ${styles.eachSectionT2} `}
          >
            <h1 className={styles.bannerHeadingT2}>Live Video</h1>
            <RenderScrollingView idOfflinePortrait={idOfflinePortrait} />
            <div className={stylesTwo.videoContainerIframe}>
              <iframe
                style={{ width: "100%", height: "100%" }}
                src={`https://www.youtube.com/embed/${liveId}?autoplay=1&mute=1`}
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
                title="Live"
              ></iframe>
            </div>
          </section>
        </>
      );
    } else if (hlsUrl) {
      return (
        <>
          <section
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
            id="live"
            className={`${styles.bannerSectionT2} ${styles.eachSectionT2} `}
          >
            <h1 className={styles.bannerHeadingT2}>Live Video</h1>
            <RenderScrollingView idOfflinePortrait={idOfflinePortrait} />
            <div
              className={`video-container-hlsplayer ${styles.videoContainerT4}`}
              style={{
                maxWidth: "1020px",
                border: "none",
                boxShadow: "none",
                borderRadius: "none",
              }}
            >
              <HLSPlayer
                selectedTemplateOption={selectedTemplateOption}
                weddingPage={true}
                streamStatus={streamStatus}
                setStreamStatus={setStreamStatus}
              />
            </div>
          </section>
        </>
      );
    } else if (
      singleEventData?.offline_video !== null &&
      singleEventData?.offline_video !== ""
    ) {
      return (
        <>
          <section
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              justifyContent: "center",
            }}
            id="live"
            className={`${styles.bannerSectionT2} ${styles.eachSectionT2} `}
          >
            <h1 className={styles.bannerHeadingT2}>Live Video</h1>
            <RenderScrollingView idOfflinePortrait={idOfflinePortrait} />
            <div
              className={`${
                idOfflinePortrait
                  ? stylesTwo.videoContainerPortrait
                  : stylesTwo.videoContainerT4
              }`}
            >
              <video
                width="100%"
                height="auto"
                controls
                style={{ maxWidth: "1020px" }}
                ref={videoRef}
                src={singleEventData?.offline_video}
                type="video/mp4"
                onLoadedMetadata={handleOfflineVideoMetadata}
              />
            </div>
          </section>
        </>
      );
    }
  };

  const renderFooterSection = () => {
    const currentYear = new Date().getFullYear();
    return (
      <footer style={{ padding: "20px 0px", textAlign: "center" }}>
        <p>
          &copy; {currentYear}, Made with ❤️ by <strong>Infinitum Tech</strong>
        </p>
      </footer>
    );
  };

  const renderGallerySection = () => {
    return (
      <section
        id="gallery"
        style={{
          paddingTop: "20px",
          paddingBottom: "20px",
          margin: "50px 0px",
        }}
        className={styles.eachSectionT2}
      >
        {singleEventData &&
          Array.isArray(singleEventData.images) &&
          singleEventData.images.length > 0 && (
            <>
              <h1 className={styles.bannerHeadingT2}>Captured Moment</h1>
              <div
                style={{
                  width: "90%",
                  margin: "auto",
                }}
              >
                <DefaultGallery />
              </div>
            </>
          )}
      </section>
    );
  };


  return (
    <div className={styles.mainContainerT2}>
      <div
        style={{
          display:
            singleEventData?.title?.length >= 40 && window.innerWidth <= 768
              ? "none"
              : "block",
        }}
        className={styles.navbarT2}
      >
        <div className={styles.navLogoContainerT2}>
          <a href="/">
            <img
              src="https://d1gmn9lhl3xm2s.cloudfront.net/static/logo/neolLogo.jpg"
              className={styles.navLogoT2}
              alt="nav-logo"
            />
          </a>
        </div>
        <h1
          style={{
            background:
              "linear-gradient(45deg, rgb(217 164 8), rgb(255 173 0)) text",
            WebkitBackgroundClip: "text",
            color: "transparent",

            fontWeight: "bold",
            textAlign: "center",
            fontFamily: "cursive",
          }}
          className={styles.fontSizeT2}
        >
          {capitalizeFirstLetter(singleEventData?.title)}{" "}
        </h1>
      </div>
      <div
        style={{
          display:
            singleEventData?.title?.length >= 40 && window.innerWidth < 768
              ? "block"
              : "none",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
        }}
        className={styles.navbarMobileT2}
      >
        <div>
          <a href="/">
            <img
              src="https://d1gmn9lhl3xm2s.cloudfront.net/static/logo/neolLogo.jpg"
              className={styles.navLogoT2}
              alt="nav-logo"
            />
          </a>
        </div>
        <h1
          style={{
            background:
              "linear-gradient(45deg, rgb(217 164 8), rgb(255 173 0)) text",
            WebkitBackgroundClip: "text",
            color: "transparent",

            fontWeight: "bold",
            textAlign: "center",
            fontFamily: "cursive",
          }}
          className={styles.fontSizeT2}
        >
          {capitalizeFirstLetter(singleEventData?.title)}{" "}
        </h1>
      </div>
      <div
        style={{
          padding: "50px",
          display: "flex",
          flexDirection: "column",
          gap: "30px",
        }}
      >
        {renderBannerImage()}
        {renderInvitationSection()}
        {renderTimeLeftSection()}
        {renderLiveVideoSection()}
        {renderGallerySection()}
        {renderFooterSection()}
        <WhatsAppButton />
      </div>
    </div>
  );
};

export default DefaultTemplate;
