import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import styles from "./styles.module.css";
import { FaArrowLeft } from "react-icons/fa";

const NotFoundPage = () => {
  useEffect(() => {
    document.title = "Event on Life - Not Found";
  }, []);
  return (
    <div className={styles.notFoundContainer}>
      <h1 className={styles.notFoundTitle}>404</h1>
      <h3 className={styles.notFoundSubtitle}>Page Not Found</h3>
      <p className={styles.notFoundDescription}>
        Sorry, the page you are looking for does not exist.
      </p>
      <Link to="/" className={styles.notFoundLink}>
        <FaArrowLeft className={styles.arrowIcon} /> Go Back to Home
      </Link>
    </div>
  );
};

export default NotFoundPage;
